import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import * as currentPageActions from '../redux/actions/current-page'
import LoginForm from '../components/login/login-form'

import './page-styles.css'
import client from "../axios";

const Login = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(currentPageActions.dashboard())
    }, [])

    useEffect(() => {
        client.delete('/api/auth/logout')
    }, [])

    return (
        <>
            <div className="d-flex my-2">
                <div className="col-1" />
                <div className="col-11">
                    <img src="img/checkinglogo.svg" alt="" width="160px"/>
                </div>
            </div>

            <div className="image-container">
                <img src="img/login-pic.svg" alt=""/>
            </div>

            <div className="d-flex flex-column justify-content-center login-form-position flex-lg-row">
                <div className="me-lg-4">
                    <LoginForm />
                </div>
                <div className="ms-lg-4 d-flex justify-content-center">
                    <img src="img/login-right-img.png" className="login-image" alt=""/>
                </div>
            </div>

            <footer className="border-top d-flex justify-content-center mx-2" style={{ marginTop: '70px' }}>
                <label className='text-secondary my-4' style={{ fontSize: '10pt', textAlign: 'center' }}>
                    Copyright© Checking Co.,Ltd ALL Rights Reserved.&emsp;사업자 : 체킹주식회사&emsp;대표이사 : 하영길&emsp;사업자등록번호 : 826-87-00908&emsp;
                    경상남도 창원시 성산구 중앙대로 33 대흥인터빌 823호&emsp;사용문의 Tel. 1600-0317&emsp;
                    Hompage : <a href="http://checking.co.kr" style={{ color: 'inherit' }}>www.checking.co.kr</a>
                </label>
            </footer>
        </>
    )
}

export default Login