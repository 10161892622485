import {Modal} from 'react-bootstrap'
import React, {useRef} from "react";

interface IInfoModal {
	show: boolean;
	onHide: () => any;
	title: string;
	message: string;
	onButtonClick: () => any;
}

const InfoModal = ({ show, onHide, title, message, onButtonClick } : IInfoModal) => {

	const buttonRef = useRef<HTMLButtonElement>(null)

	const handleEntered = () => {
		buttonRef.current?.focus()
	}

	return (
		<Modal centered show={show} onHide={onHide} onEntered={handleEntered}>

			<Modal.Body>
				<h6 className="my-3">{message}</h6>
			</Modal.Body>

			<Modal.Footer>
				<button ref={buttonRef} className="btn btn-primary" onClick={onButtonClick}>확인</button>
			</Modal.Footer>
		</Modal>
	)
}

export default InfoModal

