import React, {useEffect, useMemo, useRef, useState} from 'react'
import {convertDateWithHyphen} from "../../../../lib/converting-func";
import {getDate} from "../../../../lib/util-func";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import client from "../../../../axios";
import {IStockPayDetail, IStockPaySummary} from "../../../interfaces/stock-manage/i-stock-pay";
import {IColumn} from "../../../interfaces/common";
import {accCategory} from "../../../../lib/common-args";
import SmlProductModal from "../../../common-modals/sml-product-modal";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import * as buttonActions from '../../../../redux/actions/button'
import {ClickedItemContext} from "./stock-pay-context";
import StockPayList from "./stock-pay-list";
import InfoModal from "../../../common-modals/info-modal";

const StockPay = () => {

	/* item_nm의 ref */
	const itemNmRef = useRef<HTMLInputElement>(null)
	/* 기본 검색 데이터 */
	const defaultSearchData = {
		ymd1: getDate(),
		ymd2: getDate(),
		item: '',
		item_usr: '',
		item_nm: '',
		acc_gbn: '00000'
	}

	/* 보이는 테이블 결정(1: 요약, 2: 상세) */
	const [ shownTable, setShownTable ] = useState(1)
	/* 검색 데이터 */
	const [searchInputData, setSearchInputData] = useState(defaultSearchData)
	/* 수불부 데이터 */
	const [ matPaySummary, setMatPaySummary ] = useState<IStockPaySummary[]>([])
	const [ matPayDetail, setMatPayDetail ] = useState<IStockPayDetail[]>([])
	/* 품목 목록 */
	const [ productData, setProductData ] = useState<IProductData[]>([])
	/* 품목 검색창 */
	const [ showSmlProductList, setShowSmlProductList ] = useState(false)
	/* 클릭한 아이템 */
	const [ clickedItem, setClickedItem ] = useState('a!9$v')
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 테이블의 칼럼 */
	const columns = useMemo(() => {
		if(shownTable === 1)
			return [
				{ name: '코드', style: { textAlign: 'center', width: '60px', minWidth: '60px' } },
				{ name: '품명', style: { textAlign: 'center', width: '200px', minWidth: '200px' } },
				{ name: '규격', style: { textAlign: 'center', width: '200px', minWidth: '200px' } },
				{ name: '단위', style: { textAlign: 'center', width: '50px', minWidth: '50px' } },
				{ name: '이월', style: { textAlign: 'center', width: '150px', minWidth: '120px' } },
				{ name: '입고', style: { textAlign: 'center', width: '150px', minWidth: '120px' } },
				{ name: '출고', style: { textAlign: 'center', width: '150px', minWidth: '120px' } },
				{ name: '재고', style: { textAlign: 'center', width: '150px', minWidth: '120px' } },
				{ name: '계정구분', style: { textAlign: 'center', width: '90px', minWidth: '80px' } },
				{ name: '', style: {}}
			] as IColumn[]
		else
			return [
				{ name: '코드', style: { textAlign: 'center', width: '60px', minWidth: '60px' } },
				{ name: '품명', style: { textAlign: 'center', width: '200px', minWidth: '200px' } },
				{ name: '규격', style: { textAlign: 'center', width: '200px', minWidth: '200px' } },
				{ name: '비고', style: { textAlign: 'center', width: '200px', minWidth: '200px' } },
				{ name: '일자', style: { textAlign: 'center', width: '90px', minWidth: '80px' } },
				{ name: '구분', style: { textAlign: 'center', width: '130px', minWidth: '130px' } },
				{ name: '입고', style: { textAlign: 'center', width: '120px', minWidth: '120px' } },
				{ name: '출고', style: { textAlign: 'center', width: '120px', minWidth: '120px' } },
				{ name: '기타입고', style: { textAlign: 'center', width: '120px', minWidth: '120px' } },
				{ name: '기타출고', style: { textAlign: 'center', width: '120px', minWidth: '120px' } },
				{ name: '재고', style: { textAlign: 'center', width: '120px', minWidth: '120px' } },
				{ name: '', style: {} }
			] as IColumn[]
	}, [shownTable])

	const data = useMemo(() =>
		shownTable === 1 ? matPaySummary : matPayDetail
	, [shownTable, matPaySummary, matPayDetail])


	/* 품목 데이터 가져오기 */
	const fetchProductData = () => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProduct } = res.data.payload
					setProductData(rowsProduct)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 원료수불부 데이터 가져오기 */
	const fetchStockPay = (ymd1: string, ymd2: string, item: string, item_nm: string, acc_gbn: string) => {
		client.post('/api/stock-manage/get-stock-pay', {
			payload: {ymd1, ymd2, item, item_nm, acc_gbn}
		})
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsMatPaySummary } = res.data.payload
					setMatPaySummary(rowsMatPaySummary)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 상세 원료수불부 데이터 가져오기 */
	const fetchStockPayDetail = (ymd1: string, ymd2: string, item: string) => {
		client.post('/api/stock-manage/get-stock-pay-detail', {
			payload: {ymd1, ymd2, item}
		})
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsMatPayDetail } = res.data.payload
					setMatPayDetail(rowsMatPayDetail)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 날짜 수정 */
	const handleChangeOnYMD = (e: React.ChangeEvent<HTMLInputElement>, mode: number) => {
		if (mode === 1) {
			setSearchInputData({...searchInputData, ymd1: e.target.value.replaceAll("-", "")})
		} else if (mode === 2)
			setSearchInputData({...searchInputData, ymd2: e.target.value.replaceAll("-", "")})
	}

	/* 제품명 변경 */
	const handleChangeOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputData({...searchInputData, item_nm: e.target.value, item: '', item_usr: ''})
	}

	/* 검색창 엔터 입력 */
	const handleEnterOnInput = (e: any) => {
		if(e.key === 'Enter') {
			const filtered = productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()) || pd_elem.item_usr.includes(e.target.value) || pd_elem.spec.includes(e.target.value)
			)
			const target = filtered[0]
			if(filtered.length !== 1) {
				setShowSmlProductList(true)
			} else {
				setSearchInputData({ ...searchInputData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
			}
		}
	}

	/* 품목 검색 모달 입력 완료시 */
	const handleSubmitOnSmlProductSearch = (data: IProductData) => {
		setSearchInputData({ ...searchInputData, item_nm: data.item_nm, item: data.item, item_usr: data.item_usr })
		setShowSmlProductList(false)
	}

	/* 검색 버튼 클릭 */
	const handleClickOnSearchButton = () => {
		const value = itemNmRef.current === null ? '' : itemNmRef.current.value
		const filtered = productData.filter(elem =>
			(elem.item_nm.toLowerCase().includes(value.toLowerCase()) || elem.item_usr.includes(value) || elem.spec.includes(value))
		)

		if(filtered.length !== 1) {
			setShowSmlProductList(true)
		} else {
			setSearchInputData({ ...searchInputData, item_nm: filtered[0].item_nm, item: filtered[0].item, item_usr: filtered[0].item_usr })
		}
	}

	/* 마운트 시 데이터 가져오기 */
	useEffect(() => {
		fetchStockPay(defaultSearchData.ymd1, defaultSearchData.ymd2, defaultSearchData.item, defaultSearchData.item_nm, defaultSearchData.acc_gbn)
		fetchProductData()
	}, [])

	/* 버튼 클릭 시 취할 행동 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'search') {
			if(parseInt(searchInputData.ymd1) > parseInt(searchInputData.ymd2)) {
				setShowFailedModal(true)
				setFailedTitle('잘못된 검색 데이터')
				setFailedMessage('올바르지 않은 기간입니다.')
			} else {
				fetchStockPay(searchInputData.ymd1, searchInputData.ymd2, defaultSearchData.item, searchInputData.item_nm, searchInputData.acc_gbn)
				if(shownTable === 2) {
					fetchStockPayDetail(searchInputData.ymd1, searchInputData.ymd2, clickedItem)
				} else {
					setClickedItem('a!9$v')
				}
			}
			dispatch(buttonActions.clear())
		}
	}, [btnActions.action, btnActions.active, dispatch])

	/* 클릭한 아이템 변경 시 상세 수불 데이터 변경 */
	useEffect(() => {
		fetchStockPayDetail(searchInputData.ymd1, searchInputData.ymd2, clickedItem)
	}, [clickedItem])

	return (
		<>
			<div className="d-flex mt-2 flex-column">
				{/* 날짜 입력 */}
				<div className="d-flex ms-3 my-1">
					<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">기간</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchInputData.ymd1)}
								   onChange={e => handleChangeOnYMD(e, 1)}
							/>
						</div>
						<label className="col-auto align-items-center mx-2 my-1">~</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchInputData.ymd2)}
								   onChange={e => handleChangeOnYMD(e, 2)}
							/>
						</div>
					</div>
				</div>
				{/* 품명/계정구분 */}
				<div className="d-flex flex-column flex-lg-row ms-3 my-1">
					<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">품명</label>
						<div className="input-group input-group-sm">
							<input ref={itemNmRef} type="text" className="form-control" style={{ width: "235px", maxWidth: "235px" }}
								   value={searchInputData.item_nm}
								   onChange={handleChangeOnInput}
								   onKeyDown={handleEnterOnInput}/>
							<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
						</div>
					</div>

					<div className="d-flex mx-3 py-2 py-lg-0">
						<label className="col-auto align-items-center my-1 me-2">계정구분</label>
						<select className="form-select form-select-sm" value={searchInputData.acc_gbn} style={{ width: '100' }}
								onChange={e => {setSearchInputData({ ...searchInputData, acc_gbn: e.target.value })}}>
							{accCategory.map((category, index) =>
								<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
							)}
						</select>
					</div>
				</div>
				{/* 테이블 선택 */}
				<div className="d-flex ms-3 mt-3 mb-1">
					<div className="form-check mx-3">
						<input type="radio" className="form-check-input" checked={shownTable === 1} onChange={() => setShownTable(1)}/>
						<label className="form-check-label" onClick={() => setShownTable(1)}>요약</label>
					</div>
					<div className="form-check mx-3">
						<input type="radio" className="form-check-input" checked={shownTable === 2} onChange={() => setShownTable(2)}/>
						<label className="form-check-label" onClick={() => setShownTable(2)}>상세</label>
					</div>
				</div>
			</div>

			{/* 테이블 */}
			<div className="mx-3 scrollbar table-wrapper">
				<table className="table table-sm table-hover table-bordered table-condensed">
					<thead className="sticky-head">
					<tr>
						{columns.map((value, index) =>
							<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<ClickedItemContext.Provider value={{clickedItem, setClickedItem, shownTable, setShownTable}}>
						<StockPayList data={data} />
					</ClickedItemContext.Provider>
					</tbody>
				</table>
			</div>

			<SmlProductModal product={productData} show={showSmlProductList} onHide={() => setShowSmlProductList(false)}
							 onSubmit={handleSubmitOnSmlProductSearch} initialSearchData={searchInputData.item_nm} />
			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />


		</>
	)
}

export default StockPay