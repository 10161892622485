import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {accCategory} from '../../../../lib/common-args'
import {SelectedProductContext} from './add-mod-modal-context'
import {
	ProductModalListProps,
	ProductModalProps,
	ProductModalRowProps
} from "../../../interfaces/production-manage/i-add-recipe";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import {defaultNewRow} from "./add-mod-modal";
import {IColumn} from "../../../interfaces/common";

const ProductModal = ({ show, onHide, onSubmit, nrContext, rContext, clickedParentProduct, initialSearchData, startIndex, mode }: ProductModalProps) => {

	/* 기본 검색 데이터 */
	const defaultSearchData = {
		item_nm: '',
		acc_gbn: '00000'
	}
	/* 검색 데이터 */
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	/* 선택한 데이터 */
	const [ selectedProduct, setSelectedProduct ] = useState<IProductData[]>([])

	/* 품목 테이블의 칼럼 */
	const columns = useMemo(() => [
		{ name: '✓', style: { width: '50px', textAlign: 'center' } },
		{ name: '코드', style: {  width: '70px', textAlign: 'center' } },
		{ name: '품명', style: { width: '300px', textAlign: 'center' } },
		{ name: '규격', style: { width: '200px', textAlign: 'center' } },
		{ name: '계정구분', style: { width: '140px', textAlign: 'center' } },
		{ name: '현재고', style: { width: '150px', textAlign: 'center' } },
	] as IColumn[], [])

	/* 품목 테이블의 데이터 */
	const data = useMemo(() =>
		rContext.productData
			.map(product => ({
				item: product.item,
				item_usr: product.item_usr,
				item_nm: product.item_nm,
				spec: product.spec,
				unit: product.unit,
				unit_nm: product.unit_nm,
				acc_gbn: product.acc_gbn,
				acc_nm: product.acc_nm,
				qty_bs: ''
			}))
			.filter(element => {
				let item_nm = false, acc_gbn = false
				let no_overlap = rContext.recipe.findIndex(r_elem => r_elem.item_p === clickedParentProduct.item_p && r_elem.item === element.item) === -1

				if(element.item_nm.toLowerCase().includes(searchData.item_nm.toLowerCase()) || element.item_usr.includes(searchData.item_nm))
					item_nm = true

				if(element.item_usr.includes(searchData.item_nm))
					item_nm = true

				if(element.spec.includes(searchData.item_nm))
					item_nm = true

				if(searchData.acc_gbn === '00000')
					acc_gbn = true
				else if(element.acc_gbn === searchData.acc_gbn)
					acc_gbn = true

				return item_nm && acc_gbn && no_overlap
			}), [rContext.productData, searchData.item_nm, searchData.acc_gbn])

	/* initialSearchData가 바뀌면 적용 */
	useEffect(() => {
		setSearchData({ ...searchData, item_nm: initialSearchData })
	}, [initialSearchData])

	const handleClick = () => {
		if(selectedProduct.length !== 0) {
			onSubmit(startIndex, selectedProduct)
		}
	}

	return (
		<Modal show={show} onHide={onHide} size="lg" centered
			   onEntered={() => setSelectedProduct([])}
			   onExit={() => nrContext.setNewRow({
				   ...defaultNewRow,
				   item_p: nrContext.newRow.item_p,
				   item_p_usr: nrContext.newRow.item_p_usr,
				   item_p_nm: nrContext.newRow.item_p_nm
			   })}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					품목 추가
				</Modal.Title>
			</Modal.Header>
			{/* 모달의 내용물 */}
			<Modal.Body>
				<div className="card-body">
					<div className="d-flex">
						{/* 항목 이름 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">품명</label>
							<div className="input-group input-group-sm" style={{ width: '200px' }}>
								<input type="text" className="form-control" value={searchData.item_nm}
									   onChange={e => setSearchData({ ...searchData, item_nm: e.target.value })}/>
							</div>
						</div>
						{/* 계정구분 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">계정구분</label>
							{/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
							<select className="form-select form-select-sm" value={searchData.acc_gbn}
									onChange={e => {setSearchData({ ...searchData, acc_gbn: e.target.value })}}>
								{accCategory.map((category, index) =>
									<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
								)}
							</select>
						</div>
					</div>

					<div className="my-3 mx-3 scrollbar product-table-wrapper">
						<table className="table table-sm table-hover table-bordered table-condensed">
							<thead className="sticky-head">
							<tr>
								{columns.slice(mode === 1 ? 0 : 1, ).map((value, index) =>
									<th key={index} scope="col" style={value.style}>{value.name}</th>
								)}
							</tr>
							</thead>

							<tbody>
							<SelectedProductContext.Provider value={{selectedProduct, setSelectedProduct } } >
								<ProductModalList onSubmit={onSubmit} startIndex={startIndex} data={data} mode={mode} />
							</SelectedProductContext.Provider>
							</tbody>
						</table>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				{
					(mode === 1)
						?
						<div className="d-flex justify-content-end">
							<div className="d-flex align-content-center mx-3 ">
								<button className="btn btn-secondary my-3" onClick={handleClick}>
									입력
								</button>
							</div>
						</div>
						:
						null
				}
			</Modal.Footer>
		</Modal>
	)
}

/* 선택된 제품을 나타내는 리스트 */
const ProductModalList = ({ data, startIndex, onSubmit, mode }: ProductModalListProps) => {

	/* 선택된 제품의 컨텍스트 */
	const spContext = useContext(SelectedProductContext)

	return (
		<>
			{data.map((row, index) =>
				<ProductModalRow key={index} row={row} startIndex={startIndex} onSubmit={onSubmit} spContext={spContext} mode={mode} />
			)}
		</>
	)
}

/* 선택된 제품의 행 */
const ProductModalRow = ({ row, startIndex, onSubmit, spContext, mode }: ProductModalRowProps) => {

	const [ checked, setChecked ] = useState(false)

	useEffect(() => {
		if(checked) {
			if(spContext.selectedProduct.findIndex(elem => elem.item === row.item) === -1)
				spContext.setSelectedProduct(spContext.selectedProduct.concat(row))
		} else {
			if(spContext.selectedProduct.findIndex(elem => elem.item === row.item) !== -1)
				spContext.setSelectedProduct(spContext.selectedProduct.filter(elem => elem.item !== row.item))
		}
	}, [checked])

	const handleDoubleClick = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
		if(e.detail === 2) {
			onSubmit(startIndex, [row])
		}
	}

	return (
		<>
			<tr>
				{
					(mode === 1)
						?
						<th scope='row' style={{textAlign: 'center'}}>
							<input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
						</th>
						:
						null
				}
				<td style={{textAlign: 'center' }} onClick={handleDoubleClick}>{row.item_usr}</td>
				<td onClick={handleDoubleClick}>{row.item_nm}</td>
				<td onClick={() => onSubmit(startIndex, [row])}>{row.spec}</td>
				<td style={{ textAlign: 'center' }} onClick={handleDoubleClick}>{row.acc_nm}</td>
				<td onClick={handleDoubleClick}/>
			</tr>
		</>
	)
}

export default ProductModal