import React, {useState, useEffect, useContext} from 'react'
import {AddModModalListProps, AddModModalRowProps, IRecipe} from "../../../interfaces/production-manage/i-add-recipe";
import {InfoModalContext, NewRowContext, RecipeContext, SelectedRecipeContext} from "./add-mod-modal-context";
import {accCategory} from "../../../../lib/common-args";
import ProductModal from "./product-modal";
import {defaultNewRow} from "./add-mod-modal";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";

/* 추가 모달의 정보 리스트 */
const AddModModalList = ({ data, clickedParentProduct }: AddModModalListProps) => {

	/* provider에서 받아온 컨텍스트 소환 */
	const recipeContext = useContext(RecipeContext)
	const newRowContext = useContext(NewRowContext)
	const infoModalContext = useContext(InfoModalContext)
	const selectedRecipeContext = useContext(SelectedRecipeContext)

	/* 포커싱 된 행을 나타냄 */
	const [ focusedIndex, setFocusedIndex ] = useState(clickedParentProduct.index === -2 ? 0 : -1)
	/* 소수점 */
	const { dec } = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((element, index) =>
				<AddModModalRow key={index} row={element} focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} clickedParentProduct={clickedParentProduct}
								rContext={recipeContext} nrContext={newRowContext} imContext={infoModalContext} srContext={selectedRecipeContext} dec={dec} />
			)}
		</>
	)
}

/* 추가 모달의 정보 행 */
const AddModModalRow = ({ row, focusedIndex, setFocusedIndex, rContext, nrContext, imContext, srContext, clickedParentProduct, dec }: AddModModalRowProps) => {

	/* 현재 포커싱 되어있는 셀 */
	const [ focusMode, setFocusMode ] = useState({
		item_nm: false,
		qty: false,
		loss_rate: false
	})
	/* 품목 모달의 초기 데이터 */
	const [ initialProductData, setInitialProductData ] = useState('')
	/* 행 체크 */
	const [ checked, setChecked ] = useState(false)
	/* 품목 검색 모달 가져오기 */
	const [ showProductModal, setShowProductModal ] = useState(false)

	/* focusedIndex가 바뀔 시 그 행에 포인트 */
	useEffect(() => {
		if(row.index === focusedIndex) {
			if(!focusMode.item_nm && !focusMode.qty && !focusMode.loss_rate) {
				setFocusMode({ ...focusMode, item_nm: true})
			}
		}
	}, [focusedIndex])

	/* 행 선택 시 선택배열에 넣기 */
	useEffect(() => {
		if(row.index !== -1) {
			if(checked) {
				if(srContext.selectedRecipe.findIndex(elem => elem.item === row.item) === -1) {
					srContext.setSelectedRecipe(srContext.selectedRecipe.concat(row))
				}
			} else {
				if(srContext.selectedRecipe.findIndex(elem => elem.item === row.item) !== -1) {
					srContext.setSelectedRecipe(srContext.selectedRecipe.filter(elem => elem.item !== row.item))
				}
			}
		}
	}, [row.index, checked])

	useEffect(() => {
		if(srContext.selectedRecipe.length === 0)
			setChecked(false)
	}, [srContext.selectedRecipe])

	/* 테이블 셀 선택과 블러 */
	const handleClickOnItemNmCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: true, qty: false, loss_rate: false})
	}
	const handleClickOnQtyCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: false, qty: true, loss_rate: false})
	}
	const handleClickOnLossRateCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: false, qty: false, loss_rate: true})
	}
	const handleBlurOnItemNmCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ ...focusMode, item_nm: false })
	}
	const handleBlurOnQtyCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ ...focusMode, qty: false })
	}
	const handleBlurOnLossRateCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ ...focusMode, loss_rate: false })
	}

	/* item_nm */
	const handleChangeOnItemNm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if(index === -1) {
			nrContext.setNewRow({ ...nrContext.newRow, item_usr: '', item_nm: e.target.value })
		} else {
			rContext.setRecipe(rContext.recipe.map(element => {
				return (index === element.index)
					?
					{ ...element, item_usr: '', item_nm: e.target.value, item: '' }
					:
					element
			}))
		}
	}
	const handleEnterOnItemNm = (e: any, index: number) => {
		if(e.key === 'Enter' && row.item_usr === '') {
			const filteredProductData = rContext.productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()) ||
				pd_elem.item_usr.includes(e.target.value)
			)

			if((e.target.value.length <= 1) || filteredProductData.length === 0) {
				/* 입력란의 문자 개수가 1개 이하이거나 입력란에 따라 찾을 수 없다면 */
				setInitialProductData('')
				setShowProductModal(true)
			} else if(filteredProductData.length >= 2) {
				/* 입력란의 검색 결과가 2개 이상이라면 */
				setInitialProductData(e.target.value)
				setShowProductModal(true)
			} else {
				const addingData = filteredProductData[0]
				const isOverlap = rContext.recipe
					.findIndex(r_elem =>  clickedParentProduct.item_p === r_elem.item_p && r_elem.item === addingData.item && r_elem.item_usr !== ''
					) !== -1

				if(isOverlap) {
					imContext.setFailedTitle('입력 실패')
					imContext.setFailedMessage('이미 중복되는 품목이 있습니다.')
					imContext.setShowFailedModal(true)
					setInitialProductData('')

					if(index === -1) {
						nrContext.setNewRow({ ...defaultNewRow, item_p: row.item_p, item_p_usr: row.item_p_usr, item_p_nm: row.item_p_nm})
					} else {
						rContext.setRecipe(rContext.recipe.map(elem =>
							(elem.index === row.index)
								?
								{ ...elem, item: '', item_usr: '', item_nm: '' }
								:
								elem
						))
					}
				} else {
					rContext.setIsChanged(true)
					if(index === -1) {
						const newIndex = getNewIndex()
						rContext.setRecipe(rContext.recipe.concat({
							...row,
							index: newIndex,
							seq: getNewSeqNum(row.item_p),
							item: addingData.item,
							item_usr: addingData.item_usr,
							item_nm: addingData.item_nm,
							spec: addingData.spec,
							unit: addingData.unit,
							unit_nm: addingData.unit_nm,
							acc_gbn: addingData.acc_gbn
						}))
						rContext.setBeforeRecipe(rContext.beforeRecipe.concat({
							index: newIndex,
							item_p: '',
							item: addingData.item
						}))
						nrContext.setNewRow({
							...defaultNewRow,
							item_p: row.item_p,
							item_p_usr: row.item_p_usr,
							item_p_nm: row.item_p_nm
						})
						setFocusedIndex(newIndex)
					} else {
						rContext.setRecipe(rContext.recipe.map(elem =>
							(elem.index === index)
								?
								{
									...elem,
									item: addingData.item,
									item_usr: addingData.item_usr,
									item_nm: addingData.item_nm,
									spec: addingData.spec,
									unit: addingData.unit,
									unit_nm: addingData.unit_nm
								}
								:
								elem
						))
					}
					setFocusMode({ item_nm: false, qty: true, loss_rate: false })
					setInitialProductData('')
				}
			}
			e.preventDefault()
		} else if(e.key === 'Enter' && row.item_usr !== '') {
			setFocusMode({ item_nm: false, qty: true, loss_rate: false })
			e.preventDefault()
		}
	}


	/* qty */
	const handleChangeOnQty = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if(index === -1 ) {
			nrContext.setNewRow({ ...nrContext.newRow, qty: e.target.value })
		} else {
			rContext.setRecipe(rContext.recipe.map(element => {
				return (element.index === index) ?
					{ ...element, qty: e.target.value }
					:
					element
			}))
		}
		rContext.setIsChanged(true)
	}
	const handleBlurOnQty = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
		const tmp = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, ''))
		const modifiedQtyBs = isNaN(tmp) ? '' : tmp.toString()

		if(index === -1) {
			nrContext.setNewRow(
				{
					...nrContext.newRow, qty:
						(e.target.value === '')
							?
							''
							:
							modifiedQtyBs
				})
		} else {
			rContext.setRecipe(rContext.recipe.map(element => {
				return (element.index === index)
					?
					{
						...element, qty:
							(e.target.value === '')
								?
								''
								:
								modifiedQtyBs
					}
					:
					element
			}))
		}
	}
	const handleEnterOnQty = (e: any) => {
		if(e.key === 'Enter') {
			setFocusMode({ item_nm: false, qty: false, loss_rate: true })
		}
	}

	/* bigo */
	const handleChangeOnLossRate = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if(index === -1 ) {
			nrContext.setNewRow({ ...nrContext.newRow, loss_rate: e.target.value })
		} else {
			rContext.setRecipe(rContext.recipe.map(element => {
				return (element.index === index) ?
					{ ...element, loss_rate: e.target.value }
					:
					element
			}))
		}
		rContext.setIsChanged(true)
	}
	const handleBlurOnLossRate = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
		const tmp = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, ''))
		const modifiedQtyBs = isNaN(tmp) ? '' : tmp.toString()

		if(index === -1) {
			nrContext.setNewRow(
				{
					...nrContext.newRow, loss_rate:
						(e.target.value === '')
							?
							''
							:
							modifiedQtyBs
				})
		} else {
			rContext.setRecipe(rContext.recipe.map(element => {
				return (element.index === index)
					?
					{
						...element, loss_rate:
							(e.target.value === '')
								?
								''
								:
								modifiedQtyBs
					}
					:
					element
			}))
		}
	}
	const handleEnterOnLossRate = (e: any) => {
		if(e.key === 'Enter') {
			setFocusedIndex(getNextFocusingCell())
			setFocusMode({ item_nm: false, qty: false, loss_rate: false })
		}
	}

	/* 다음 포커싱 열의 인덱스 가져오기 */
	const getNextFocusingCell = () => {
		let flag: boolean = false, currentIndex: number = row.index, nextIndex: number = 0

		if(currentIndex === -1) return -1

		for(let i = currentIndex; i < rContext.recipe.length; i++) {
			if(rContext.recipe[i].index > currentIndex && rContext.recipe[i].item_p === row.item_p) {
				flag = true
				nextIndex = i
				break
			}
		}

		return flag ? nextIndex : -1
	}
	/* 새 인덱스 받아오기 */
	const getNewIndex = () => {
		return rContext.recipe.length
	}
	/* 새 seq 받아오기 */
	const getNewSeqNum = (item_p: string) => {
		return rContext.recipe.filter(elem => elem.item_p === item_p).length + 1
	}
	/* 품목 모달에서 받아온 데이터를 추가 */
	const handleSubmit = (startIndex: number, selectedData: IProductData[]) => {
		const newIndex = getNewIndex()
		const newSeq = getNewSeqNum(row.item_p)
		const reducedSelectedData =
			selectedData.reduce((prev, cur, index) => {
				return prev.concat({
					...cur,
					index: newIndex + index,
					item_p: row.item_p,
					item_p_usr: row.item_usr,
					item_p_nm: row.item_p_nm,
					spec_p: row.spec_p,
					unit_p: row.unit_p,
					unit_p_nm: row.unit_p_nm,
					bigo: row.bigo,
					seq: newSeq + index,
					qty: '',
					loss_rate: '',
				})
			}, [] as IRecipe[])

		if(row.index === -1) {
			rContext.setRecipe(rContext.recipe.concat(reducedSelectedData))
			rContext.setBeforeRecipe(rContext.beforeRecipe.concat(
				reducedSelectedData.map(rsd => ({
					index: rsd.index,
					item_p: '',
					item: ''
				}))
			))
			setFocusedIndex(newIndex)
		} else {
			const newData = reducedSelectedData[0]
			rContext.setRecipe(rContext.recipe.map(elem =>
				(startIndex === elem.index)
					?
					{
						...elem,
						item: newData.item,
						item_usr: newData.item_usr,
						item_nm: newData.item_nm,
						spec: newData.spec,
						unit: newData.unit,
						unit_nm: newData.unit_nm
					}
					:
					elem
			))
		}
		rContext.setIsChanged(true)
		setShowProductModal(false)
		setTimeout(() => {
			setFocusMode({ item_nm: false, qty: true, loss_rate: false })
		}, 200)
	}

	return (
		<>
			<tr>
				<th scope='row' style={{ textAlign: 'center'}}>
					<input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
				</th>
				<td style={{ textAlign: 'center' }}>{row.seq === -1 ? '' : row.seq}</td>
				<td style={{ textAlign: 'center' }}>{row.item_usr}</td>
				<td
					onClick={handleClickOnItemNmCell}
					onBlur={handleBlurOnItemNmCell}
				>
					{
						focusMode.item_nm
							?
							<input type="text" className="form-control form-control-sm shadow-none"
								   value={row.item_nm} autoFocus
								   onChange={e => handleChangeOnItemNm(e, row.index)}
								   onKeyDown={e => handleEnterOnItemNm(e, row.index)}
							/>
							:
							row.item_nm
					}
				</td>
				<td>{row.spec}</td>
				<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
				<td style={{ textAlign: 'center' }}>{accCategory.find((element) => element.value === row.acc_gbn)?.name}</td>
				<td style={{textAlign: 'end' }}
					onClick={handleClickOnQtyCell}
					onBlur={handleBlurOnQtyCell}
				>
					{
						focusMode.qty
							?
							<input type="text" className="form-control form-control-sm shadow-none" style={{textAlign: "end"}}
								   value={row.qty} autoFocus
								   onKeyDown={handleEnterOnQty}
								   onChange={e => handleChangeOnQty(e, row.index)}
								   onBlur={e => handleBlurOnQty(e, row.index)}
							/>
							:
							isNaN(parseFloat(row.qty)) ? '' : convertReadable(row.qty, dec) + '%'
					}
				</td>
				<td style={{textAlign: 'end' }}
					onClick={handleClickOnLossRateCell}
					onBlur={handleBlurOnLossRateCell}
				>
					{
						focusMode.loss_rate
							?
							<input type="text" className="form-control form-control-sm shadow-none" style={{textAlign: "end"}}
								   value={row.loss_rate} autoFocus
								   onKeyDown={handleEnterOnLossRate}
								   onChange={e => handleChangeOnLossRate(e, row.index)}
								   onBlur={e => handleBlurOnLossRate(e, row.index)}
							/>
							:
							isNaN(parseFloat(row.loss_rate)) ? '' : convertReadable(row.loss_rate, dec) + '%'
					}
				</td>
			</tr>

			<ProductModal show={showProductModal} onHide={() => setShowProductModal(false)} onSubmit={handleSubmit}
						  clickedParentProduct={clickedParentProduct} rContext={rContext} nrContext={nrContext}
						  initialSearchData={initialProductData} startIndex={row.index} mode={row.index === -1 ? 1 : 0} />
		</>
	)
}

export default AddModModalList