import styled from 'styled-components'

const Container = styled.div`
  h6 {		
	color: lightseagreen;
	margin-top: 30px;
	margin-bottom: 10px;
  }
  
  p {
	font-size: 13px;
  }
  
  
`

const PersonalInfo = () => (
	<Container>
		<p>재고수불(www.subul.co.kr 이하 재고수불 홈페이지)은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</p>
		<p>체킹주식회사(이하 회사라 함)는 이용자의 동의를 기반으로 개인정보를 수집 이용 제공하고 있으며, 재고수불 이용자(이하 이용자 라 함)의 개인정보를 안전하게 보호하기 위해 노력하고 있습니다. 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자 개인정보의 보호와 권익 보호에 최선을 다하고 있습니다.</p>
		<p>회사가 개인정보 처리방침을 개정하는 경우에는 홈페이지 또는 재고수불 앱에 게시하거나 개별적으로 공지할 것입니다. 본 동의서는 재고수불 서비스와 관련하여 본인의 개인(신용)정보를 수집이용하기 위하여 1회만 작성하는 동의서로, 본 동의 이후 별도의 동의가 필요하지 않습니다. 본 개인정보처리방침은 회사에서 제공하는 체킹 재고수불 서비스에 적용되며 다음과 같은 내용을 담고 있습니다.</p>

		<h6>&emsp;1. 개인정보의 이용</h6>
		<p>회사는 카드 가맹 사업자가 카드매출 정산서비스를 편리하게 이용할 수 있도록 서비스를 제공하고 그에 적용된 회사는 재고수불 서비스(이하 서비스) 이용에 반드시 필요한 개인정보를 회원의 관리, 서비스의 제공 및 개선, 새로운 서비스의 개발, 마케팅, 금융사고 조사, 분쟁해결, 민원처리, 서비스이용에 대한 통계 등의 목적을 위해 활용합니다.</p>
		<p>이용자의 개인정보는 이용 목적 이외의 용도로는 활용되지 않으며, 이용목적과 수집되는 개인정보의 수집항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 할 것입니다.회사가 서비스 운영을 위해 활용하는 이용자의 개인정보는 아래와 같습니다.</p>
		<p className="ms-3">
			<li>재고수불 회원가입 정보 : 핸드폰 본인인증</li>
			<li>재고수불 서비스 이용ㆍ관리(금융거래정보) 정보, 사업장명, 사업자등록번호, 사업장주소, 사업자명, 사업장연락처 등</li>
			<li>재고수불 이용을 위한 가입 및 회원가입 정보</li>
		</p>

		<h6>&emsp;2. 개인정보의 수집</h6>
		<p>회사가 서비스이용자에게 서비스 제공 과정 중에 수집하는 개인정보는 아래와 같습니다.</p>
		<p>개인정보는 회원가입 및 서비스 이용 시 이용자의 정보가 자동으로 생성되어 수집되며, 수집된 웹사이트 접속 정보는 고객 이외에 복호화가 불가능하도록 암호화하여 당사의 보안센터 내에 별도 분리된 서버에 저장됩니다.</p>
		<p className="ms-3">
			<li>개인정보의 수집 방법 : 이용자들의 서비스 이용을 위해 회사는 다음과 같은 방법으로 고객의 개인정보를 수집하고 있습니다.</li>
			<li>가입페이지를 통한 회원가입, 서비스 이용, 이벤트 응모, 회원정보 수정, 고객센터문의 등 본인으로부터의 수집</li>
			<li>스크래핑 기술을 활용한 수집</li>
			<li>생성정보 수집 툴을 통한 수집</li>
			<li>본인의 동의 하에 제3자로부터의 수집</li>
		</p>

		<p style={{ fontSize: "15px" }}>[회원 가입시 수집 항목]</p>
		<p className="ms-3">재고수불 회원가입시 입력한 사업자등록번호, 대표자명, 사용자 ID/PW, 휴대폰번호</p>
		<p style={{ fontSize: "15px" }}>[개인정보의 수집 및 이용 목적]</p>
		<p>회사는 다음과 같은 목적을 위해 개인정보를 수집합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 것입니다.</p>
		<p className="ms-3">1. 회원가입 및 관리 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별•인증, 회원자격 유지•관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종고지•통지, 고충처리, 분쟁조정을 위한 기록 보존 등</p>
		<p>2. 민원업무 처리 : 민원인의 신원 확인, 민원사항확인, 사실조사를 위한 연락•통지, 처리결과 통보 등</p>
		<p>3. 통계학적 분석 : 통계분석서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등</p>
		<p>4. 마케팅 및 광고에의 활용 : 신규 서비스 개발과 이벤트 행사에 따른 최신정보전달 및 맞춤 서비스 제공, 통계학적 분석에 따른 서비스 제공 및 광고게재 등</p>

		<h6>&emsp;제 3조(개인정보의 보유 및 이용 기간)</h6>
		<p>회사는 이용자가 회사가 운영하는 플랫폼 서비스 회원으로 가입한 날로부터 서비스를 제공받는 기간 동안 이용자의 개인정보를 보유 및 이용합니다. 회사는 이용자가 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우 해당 개인정보를 지체 없이 파기합니다. 다만, 회사는 이용자가 회사가 운영하는 플랫폼 서비스 회원에서 탈퇴하거나, 회사가 이용자를 제명하는 경우 권리남용, 악용방지, 권리침해, 명예훼손 분쟁 및 수사협조 의뢰에 대비하여 이용계약 해지일로부터 3년 동안 개인정보를 보관하고, 아래와 같이 관계법령의 규정에 따라 보존할 필요가 있는 경우 해당 기간 동안 개인정보를 보관합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</p>
		<p>1. 회사는 이용자가 탈회하거나, 이용자를 제명하는 경우 권리남용, 악용방지, 권리침해/명예훼손 분쟁 및 수사협조 의뢰에 대비하여 이용계약 해지일로부터 3년 동안 개인정보를 보존합니다.</p>
		<p>2. 회사는 다음의 정보에 대해서 명시한 기간 동안 보존합니다.</p>
		<p className="ms-3">
			가) 계약 또는 청약철회 등에 관한 기록 <br/>
			보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률(보존기간 : 5년)
		</p>
		<p className="ms-3">
			나) 자금의 이체 및 상환에 관한 기록 <br/>
			보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률(보존기간 : 5년)
		</p>
		<p className="ms-3">
			다) 소비자의 불만 또는 분쟁처리에 관한 기록 <br/>
			보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률(보존기간 : 3년)
		</p>
		<p className="ms-3">
			라) 표시/광고에 관한 기록 <br/>
			보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률(보존기간 : 6개월)
		</p>
		<p className="ms-3">
			마) 전자금융에 관한 기록 <br/>
			보존근거 : 전자금융거래법(보존기간 : 5년)
		</p>
		<p className="ms-3">
			바) 접속로그 등 서비스 이용에 관한 기록 <br/>
			보존근거 : 통신비밀보호법(보존기간 : 3개월)
		</p>

		<h6>&emsp;제 4조(개인정보의 파기 절차 및 방법)</h6>
		<p>회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
		<p className="ms-3">1. 파기절차 : 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 데이터베이스로 옮겨져 (종이의 경우 별도의 서류함) 내부방침 및 기타 관련법령에 의한 정보보호 사유에 따라 (보유 및 이용 기간 참조) 일정 기간 저장된 후 파기됩니다.</p>
		<p className="ms-3">2. 파기방법 : 별도 데이터베이스로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각합니다.</p>
		<p className="ms-3">3. 개인정보의 공유 및 제공</p>
		<p>회사는 재고수불 서비스 이용에 필요한 최소한의 개인정보를 수집하고 있으며, 1. 개인정보의 이용 목적에서 고지한 범위 내에서 사용합니다.</p>
		<p>회사는 대부분의 서비스에 대하여 취소 또는 철회 기능을 제공하여 이미 동의한 개인정보의 수집과 이용에 대하여 언제든지 취소하고 삭제할 수 있는 방법을 안내하고 있습니다(전체메뉴{">"}마이페이지{">"}회원탈퇴)</p>
		<p>수집된 개인정보는 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
		<p className="ms-3">
			<li>사전에 제3자에게 개인정보 제공을 동의한 경우</li>
			<li>재고수불 제휴서비스 이용 동의 후 제휴사에 개인정보를 제공하는 경우</li>
			<li>서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</li>
			<li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
		</p>
		<p>다만, 개인을 식별할 수 없는 속성정보는 별도의 동의절차 없이 수집 및 제공될 수 있습니다.</p>
		<p style={{ fontSize: "15px" }}>[수집한 개인정보의 제3자 제공]</p>
		<p>회사는 이용자에게 보다 나은 서비스를 제공하는 등의 목적으로 「개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용목적, 제공하는 개인정보의 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간」을 명시하며, 동의받은 범위를 넘어서 제3자에게 제공하지 않습니다.</p>

		<h6>&emsp;4. 개인정보의 취급위탁</h6>
		<p>회사는 위탁계약 체결 시 『개인정보보호법』 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독합니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>

		<h6>&emsp;5. 개인정보 자동수집장치의 설치/운영 및 거부에 관한 사항</h6>
		<p>회사는 개인정보를 생성하기 위해 회원이 재고수불 어플리케이션을 실행시 기기식별번호(디바이스 아이디 또는 IMEI)를 자동으로 수집하게 됩니다. 회원이 기기식별번호를 자동으로 수집하는 것을 거부하는 경우 재고수불 어플리케이션을 이용할 수 없습니다.</p>

		<h6>&emsp;6. 개인정보의 안정성 확보 조치</h6>
		<p>회사는 「정보통신망법」 제28조, 「개인정보보호법」 제29조에 의거하여 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적/물리적 대책을 강구하고 있습니다.</p>
		<p className="ms-3">가. 내부관리계획의 수립 및 시행 <br/>회사는 '개인정보의 안전성 확보조치 기준'에 의거하여 내부관리계획을 수립, 시행합니다.</p>
		<p className="ms-3">나. 개인정보취급자 지정의 최소화 및 교육 <br/>개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고 있습니다.</p>
		<p className="ms-3">다. 개인정보에 대한 접근 제한 <br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근을 통제하고, 침입차단시스템과 침입방지시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 3년간 보관하고 있습니다.</p>
		<p className="ms-3">라. 접속기록의 보관 및 위변조 방지 <br/>개인정보처리시스템에 접속한 기록을 보관 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 관리하고 있습니다.</p>
		<p className="ms-3">마. 개인정보의 암호화회원의 개인정보는 암호화되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p>
		<p className="ms-3">바. 해킹 등에 대비한 기술적 대책회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.사. 비인가자에 대한 출입 통제개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p>

		<h6>&emsp;7. 개인정보보호책임자 및 개인정보보호담당자</h6>
		<p>회사는 개인정보를 보호하고 개인정보와 관련된 사항을 처리하기 위하여 아래와 같이 개인정보 보호책임자와 실무담당자를 지정하고 있습니다. 회사는 회원의 개인정보와 관련된 민원 및 고충사항에 대하여 신속하게 처리하겠습니다.</p>
		<p className="ms-3">
			성명: 하영길 <br/>
			소속/직위: 대표이사 <br/>
			전화번호: 1600-0317 <br/>
			이메일 : zeroadha@naver.com
		</p>
		<h6>&emsp;8. 고지의 의무</h6>
		<p >현 개인정보취급방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소 7일전부터 재고수불 공지사항 화면을 통해 공고할 것입니다. 그러나 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자의 권리에 중요한 변경의 사유가 발생하는 경우 최소 14일 전에 공지합니다. 또한, 관련 법령이나 회사정책의 변경으로 불가피하게 처리방침을 변경해야 하는 경우, 웹사이트 공지사항을 통해 빠르게 알려드리고 있으니 참고하여 주시기 바랍니다.</p>
		<p className="ms-3">부칙 <br/>본 개인정보취급방침은 2022년 4월 1일 부터 적용됩니다.</p>
		<p style={{ fontSize: "15px" }}>[재고수불 마케팅 정보 수신 동의]</p>
		<p>주식회사 체킹은 개인정보보호법 및 정보통신망이용촉진 및 정보보호등에 관한 법률 등 관계법령에 따라 광고성정보를 전송하기 위해 수신자의 사전 수신동의를 받고 있으며, 광고성정보 수신자 의 수신동의여부를 정기적으로 확인합니다.</p>
		<p className="ms-3">- 전송방법 <br/>고객님의 핸드폰 문자메시지(SMS)등을 통해 전달될 수 있습니다.</p>
		<p className="ms-3">- 전송내용 <br/>발송되는 마케팅 정보는 수신자에게 재고수불 각종 이벤트 정보 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.</p>
		<p className="ms-3">- 철회안내 <br/>고객님은 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있으나, 당사의 마케팅 정보를 수신하지 못할 수 있습니다.</p>
		<p className="ms-3">- 수신동의 변경 <br/>재고수불 고객센터(1600-0317)을 통해 수신동의를 변경(동의/철회)할 수 있습니다.</p>
		<p className="ms-3">- 개인정보 이용 상세내용 <br/>이용하는 개인정보 항목 회원이름, 상호, 사업자등록번호, 휴대전화번호</p>
		<p className="ms-3">- 보유 이용 목적 <br/>혜택 정보, 각종 이벤트 정보 등 광고성 정보 제공</p>

	</Container>
)

export default PersonalInfo