import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {accCategory} from '../../lib/common-args'
import {
	SmlProductModalListProps,
	SmlProductModalProps,
	SmlProductModalRowProps
} from '../interfaces/common'

const SmlProductModal = ({ product, show, onHide, onSubmit, initialSearchData }: SmlProductModalProps) => {

	/* 기본 검색 데이터 */
	const defaultSearchData = {
		item_nm: '',
		acc_gbn: '00000'
	}

	/* 검색 데이터 */
	const [ searchData, setSearchData ] = useState(defaultSearchData)

	/* 칼럼 */
	const columns = useMemo(() => [
		{ name: '코드', style: {  width: '70px' } },
		{ name: '품명', style: { width: '300px' } },
		{ name: '규격', style: { width: '200px' } },
		{ name: '계정구분', style: { width: '140px' } },
		{ name: '현재고', style: { width: '150px' } },
	], [])

	useEffect(() => {
		setSearchData({ ...searchData, item_nm: initialSearchData })
	}, [initialSearchData])

	return (
		<Modal show={show} onHide={onHide} size="lg" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					품목 검색
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="card-body">
					<div className="d-flex">
						{/* 항목 이름 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">품명</label>
							<div className="input-group input-group-sm" style={{ width: '200px' }}>
								<input type="text" className="form-control" value={searchData.item_nm}
									   onChange={e => setSearchData({ ...searchData, item_nm: e.target.value })}/>
							</div>
						</div>
						{/* 계정구분 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">계정구분</label>
							{/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
							<select className="form-select form-select-sm" value={searchData.acc_gbn}
									onChange={e => {setSearchData({ ...searchData, acc_gbn: e.target.value })}}>
								{accCategory.map((category, index) =>
									<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
								)}
							</select>
						</div>
					</div>

					<div className="d-flex flex-column justify-content-center scrollbar mt-3">
						<table className="table table-sm table-hover table-bordered table-condensed scrollbar" style={{ display: "block", overflow: 'overlay', height: '500px' }}>
							<thead className="sticky-head">
							<tr>
								{columns.map((value, index) =>
									<th key={index} scope="col" style={{ textAlign: 'center', width: value.style.width }}>{value.name}</th>
								)}
							</tr>
							</thead>

							<tbody>
							<SmlProductModalList product={product.filter(pd_elem => {
								let item = false, acc_gbn = false

								if(pd_elem.item_nm.toLowerCase().includes(searchData.item_nm.toLowerCase()) || pd_elem.item_usr.includes(searchData.item_nm))
									item = true

								if(pd_elem.item_usr.includes(searchData.item_nm))
									item = true

								if(pd_elem.spec.includes(searchData.item_nm))
									item = true

								if(searchData.acc_gbn === '00000' || pd_elem.acc_gbn === searchData.acc_gbn)
									acc_gbn = true

								return item && acc_gbn
								}
							)}
												 onSubmit={onSubmit} />
							</tbody>
						</table>
					</div>
				</div>
			</Modal.Body>

		</Modal>
	)
}

const SmlProductModalList = ({ product, onSubmit }: SmlProductModalListProps) => {

	return (
		<>
			{product.map((row, index) =>
				<SmlProductModalRow key={index} row={row} onSubmit={onSubmit} />
			)}
		</>
	)
}

const SmlProductModalRow = ({ row, onSubmit }: SmlProductModalRowProps) => {

	const handleDoubleClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
		if(e.detail === 2) {
			onSubmit(row)
		}
	}

	return (
		<tr onClick={handleDoubleClick}>
			<td style={{textAlign: 'center' }}>{row.item_usr}</td>
			<td>{row.item_nm}</td>
			<td>{row.spec}</td>
			<td style={{textAlign: 'center' }}>{row.acc_nm}</td>
			<td/>
		</tr>
	)
}

export default SmlProductModal