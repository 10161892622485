import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
	HiddenTableListProps,
	HiddenTableProps, HiddenTableRowProps, IProdDaily,
	IProdDailyDetail, PrintedTablesProps
} from '../../../interfaces/production-manage/i-prod-daily'
import {IColumn} from "../../../interfaces/common";
import client from "../../../../axios";
import InfoModal from "../../../common-modals/info-modal";
import {convertDateWithHyphen, convertReadable} from "../../../../lib/converting-func";
import * as buttonActions from '../../../../redux/actions/button'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {useReactToPrint} from "react-to-print";
import * as timerActions from "../../../../redux/actions/timer";
import {useNavigate} from "react-router-dom";

const maxRow = 28

const PrintedTables = ({ summaryData, ymd }: PrintedTablesProps) => {

	const divRef = useRef<HTMLDivElement>(null)

	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	const [ detailData, setDetailData ] = useState<IProdDailyDetail[]>([])
	const [ data, setData ] = useState<IProdDaily[]>([])
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 회사명 */
	const [ corpNm, setCorpNm ] = useState('')
	/* 브레이크 포인트 */
	const [ breakpoints, setBreakpoints ] = useState<number[]>([])
	/* 내비게이터 */
	const navigate = useNavigate()

	const handlePrint = useReactToPrint({
		content: () => divRef.current,
		onAfterPrint: async () => {
			try {
				const response = await client.get('/api/auth/extend-login')
				if(response.data.result === 'success') {
					dispatch(timerActions.reset())
				} else {
					if(response.data.result === 'expired') {
						navigate('/login')
					} else {
						setFailedTitle('인쇄 실패')
						setFailedMessage('서버에 문제가 발생하였습니다.')
						setShowFailedModal(true)
					}
				}
			} catch(e: any) {
				setFailedTitle('인쇄 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				setShowFailedModal(true)
			}
		}
	})

	useEffect(() => {
		client.get('/api/auth/get-user-profile')
			.then(res => setCorpNm(res.data.corp_nm))
	}, [])

	useEffect(() => {
		setData([])
		fetchProdDailyDetail(ymd)
	}, [ymd])

	const fetchProdDailyDetail = (ymd: string) => {
		client.post('/api/production-manage/get-whole-prod-daily-detail', {
			payload: {
				ymd
			}
		})
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProdDailyDetail } = res.data.payload
					if(rowsProdDailyDetail.length !== 0)
						setDetailData(rowsProdDailyDetail)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	useEffect(() => {
		const retData = [] as IProdDaily[]
		const bp = [] as number[]
		let index = 0
		summaryData.forEach(sd_elem => {
			let qty_tot = 0, loss_tot = 0
			retData.push({
				index,
				item_p: sd_elem.item,
				item_whole_nm: sd_elem.item_nm,
				qty_whole: convertReadable(sd_elem.qty, dec),
				unit_whole_nm: sd_elem.unit_nm,
				item_nm: '',
				qty: '',
				unit_nm: '',
				loss_rate: '',
				isLast: false
			})
			index += 1
			detailData.filter(dd_elem => dd_elem.item_p === sd_elem.item).forEach(dd_elem => {
				qty_tot += parseFloat(dd_elem.qty)
				loss_tot += parseFloat(dd_elem.loss_rate)
				retData.push({
					index,
					item_p: sd_elem.item,
					item_whole_nm: '',
					qty_whole: '',
					unit_whole_nm: '',
					item_nm: dd_elem.item_nm,
					qty: convertReadable(dd_elem.qty, dec),
					unit_nm: dd_elem.unit_nm,
					loss_rate: convertReadable(dd_elem.loss_rate, dec),
					isLast: false
				})
				index += 1
			})
			retData.push({
				index,
				item_p: sd_elem.item,
				item_whole_nm: '',
				qty_whole: '',
				unit_whole_nm: '',
				item_nm: '',
				qty: convertReadable(String(qty_tot), dec),
				unit_nm: '',
				loss_rate: convertReadable(String(loss_tot), dec),
				isLast: true
			})
			bp.push(index + 1)
			index += 1
		})
		setBreakpoints(bp)
		setData(retData)
	}, [summaryData, detailData])

	useEffect(() => {
		if(btnActions.active && btnActions.action === 'print') {
			dispatch(buttonActions.clear())
			handlePrint()
		}
	}, [btnActions.active, btnActions.action, dispatch])

	const splittedData = useMemo(() => {
		const retData = [] as IProdDaily[][]
		const bp = [] as number[]
		let prevIndex = 0
		bp.push(0)

		for(let i = 0; i < breakpoints.length - 1; i++) {
			if(breakpoints[i + 1] - prevIndex > maxRow) {
				bp.push(breakpoints[i])
				prevIndex = breakpoints[i]
			}
		}
		bp.push(breakpoints[breakpoints.length - 1])

		for(let i = 0; i < bp.length - 1; i++) {
			if(bp[i + 1] - bp[i] > maxRow)
				bp.splice(i, 0, bp[i] + maxRow)
		}

		for(let i = 0; i < bp.length - 1; i++) {
			retData.push(data.slice(bp[i], bp[i + 1]))
		}

		return retData
	}, [data, breakpoints])

	return (
		<>
			<div className="d-flex" style={{ height: '0', width: '0', overflow: 'hidden' }}>
				<div ref={divRef}>
					{splittedData.map((element, index) =>
						<HiddenTable idx={index} key={index} data={element} ymd={ymd} corpNm={corpNm} tot={splittedData.length} />
					)}
				</div>
			</div>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
		</>
	)
}

const HiddenTable = ({ data, ymd, corpNm, idx, tot }: HiddenTableProps) => {

	const week = ['일', '월', '화', '수', '목', '금', '토']

	const columns = useMemo(() => [
		{ name: '이름', style: {  width: '200px', textAlign: 'center' }  },
		{ name: '생산량', style: { width: '40px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '40px', textAlign: 'center' }  },
		{ name: '자재이름', style: { width: '200px', textAlign: 'center' }  },
		{ name: '자재투입량', style: { width: '80px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '40px', textAlign: 'center' } },
		{ name: 'loss율', style: { width: '70px', textAlign: 'center' } },
	] as IColumn[], [])

	return (
		<div className="d-flex justify-content-center" style={{ pageBreakBefore: 'always', marginTop: '60px' }}>
			<div className="d-flex flex-column justify-content-center">
				<label className="text-secondary" style={{ fontSize: '12px' }}>페이지 {idx + 1}/{tot}</label>
				<h1 style={{ textAlign: 'center', textDecoration: 'underline', textUnderlinePosition: 'under' }}>제품생산일보</h1>
				<div className="d-flex justify-content-between my-2">
					<label className="ms-4">{corpNm}</label>
					<label className="me-4">생산날짜&emsp;{convertDateWithHyphen(ymd)}({week[new Date(convertDateWithHyphen(ymd)).getDay()]})</label>
				</div>
				<table className="table border-dark table-sm table-condensed">
					<thead>
					<tr className="border-top border-dark border-start-0 border-end-0 border-2">
						{columns.map((column, index) =>
							<th key={index} style={column.style} scope="column">{column.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<HiddenTableList key={`${idx}`} data={data} idx={idx} />
					</tbody>
				</table>
			</div>
		</div>
	)
}

const HiddenTableList = ({ data, idx }: HiddenTableListProps) => {
	return (
		<>
			{data.map((row, index) =>
				<HiddenTableRow key={`${idx}-${index}`} row={row} />
			)}
		</>
	)
}

const HiddenTableRow = ({ row }: HiddenTableRowProps) => {
	return (
		<tr>
			<td style={{ textAlign: 'center' }}
				className={`${row.item_whole_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.item_whole_nm}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${row.item_whole_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.qty_whole}
			</td>
			<td style={{ textAlign: 'center' }}
				className={`${row.item_whole_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.unit_whole_nm}</td>
			<td className={`${row.item_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.item_nm}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${row.item_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.qty}
			</td>
			<td style={{ textAlign: 'center' }}
				className={`${row.item_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.unit_nm}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${row.item_nm === '' ? 'border-0' : ''} ${row.isLast ? 'border-bottom border-dark border-2' : ''}`}>
				{row.loss_rate}
			</td>
		</tr>
	)
}

export default PrintedTables