import React, {useEffect, useMemo, useRef, useState} from 'react'
import {getDate} from "../../../../lib/util-func";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import SmlProductModal from "../../../common-modals/sml-product-modal";
import client from "../../../../axios";
import InfoModal from "../../../common-modals/info-modal";
import {convertDateWithHyphen} from "../../../../lib/converting-func";
import * as buttonActions from '../../../../redux/actions/button'
import {IProdDailyDetail, IProdDailySummary} from "../../../interfaces/production-manage/i-prod-daily";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {ClickedItemContext, ProdDailyDetailContext} from "./prod-daily-context";
import {ProdDailyDetailList, ProdDailySummaryList} from "./prod-daily-list";

import './styles.css'
import {Modal} from "react-bootstrap";
import PrintedTables from "./print-tables";


const ProdDaily = () => {

	/* item_nm의 ref */
	const itemNmRef = useRef<HTMLInputElement>(null)
	/* 기본 검색 데이터 */
	const defaultSearchData = {
		ymd: getDate(),
		item: '',
		item_usr: '',
		item_nm: '',
	}

	/* 요약, 상세 데이터 */
	const [ prodDailySummary, setProdDailySummary ] = useState<IProdDailySummary[]>([])
	const [ prodDailyDetail, setProdDailyDetail ] = useState<IProdDailyDetail[]>([])
	/* 검색 데이터 */
	const [searchInputData, setSearchInputData] = useState(defaultSearchData)
	/* 품목 목록 */
	const [ productData, setProductData ] = useState<IProductData[]>([])
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 수행 성공 모달 변수 */
	const [ showSuccessModal, setShowSuccessModal ] = useState(false)
	const [ successMessage, setSuccessMessage ] = useState('')
	const [ successTitle, setSuccessTitle ] = useState('')
	/* 품목 검색창 */
	const [ showSmlProductList, setShowSmlProductList ] = useState(false)
	/* loss율 수정 체크박스 클릭 */
	const [ lossRateCheck, setLossRateCheck ] = useState(false)
	/* 클릭한 아이템 */
	const [ clickedItem, setClickedItem ] = useState('a!9$v')
	/* 저장 모달 띄우기 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)

	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 칼럼 */
	const summaryCol = useMemo(() => [
		{ name: '코드', style: { textAlign: 'center', width: '15%' } },
		{ name: '품명', style: { textAlign: 'center', width: '45%' } },
		{ name: '생산량', style: { textAlign: 'center', width: '27%' } },
		{ name: '단위', style: { textAlign: 'center', width: '13%' } }
	], [])
	const detailCol = useMemo(() => [
		{ name: '자재이름', style: { textAlign: 'center', width: '47%' } },
		{ name: '자재투입량', style: { textAlign: 'center', width: '30%' } },
		{ name: '단위', style: { textAlign: 'center', width: '10%' } },
		{ name: 'loss율', style: { textAlign: 'center', width: '18%' } }
	], [])

	/* 품목 데이터 가져오기 */
	const fetchProductData = () => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProduct } = res.data.payload
					setProductData(rowsProduct)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}
	/* 생산일보 데이터 가져오기 */
	const fetchProdDaily = (ymd: string, item: string, item_nm: string) => {
		client.post('/api/production-manage/get-prod-daily', {
			payload: {
				ymd,
				item,
				item_nm
			}
		})
				.then(res => {
					if(res.data.result === 'failed') {
						setShowFailedModal(true)
						setFailedTitle('데이터 가져오기 실패')
						setFailedMessage('서버에 문제가 발생하였습니다.')
					} else {
						const { rowsProdDaily } = res.data.payload
						setProdDailySummary(rowsProdDaily)
					}
				})
				.catch(() => {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				})
	}
	/* 생산일보 상세 데이터 가져오기 */
	const fetchProdDailyDetail = (ymd: string, item: string) => {
		client.post('/api/production-manage/get-prod-daily-detail', {
			payload: {
				ymd,
				item
			}
		})
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProdDailyDetail } = res.data.payload
					setProdDailyDetail(rowsProdDailyDetail.map((element: IProdDailyDetail) => ({
						...element,
						qty: parseFloat(element.qty),
						loss_rate: parseFloat(element.loss_rate)
					})))
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}
	/* 생산일보 LOSS 변경 */
	const updateProdDaily = (ymd: string, item: string, detail: IProdDailyDetail[]) => {
		client.post('/api/production-manage/update-Prod-Daily', {
			payload: {
				ymd,
				item,
				detail
			}
		})
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('loss율 저장 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					setShowSuccessModal(true)
					setSuccessTitle('loss율 저장 성공')
					setSuccessMessage('loss율을 성공적으로 저장했습니다.')
					setLossRateCheck(false)
					fetchProdDailyDetail(searchInputData.ymd, clickedItem)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('loss율 저장 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 제품명 변경 */
	const handleChangeOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputData({...searchInputData, item_nm: e.target.value, item: '', item_usr: ''})
	}
	/* 날짜 수정 */
	const handleChangeOnYMD = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputData({ ...searchInputData, ymd: e.target.value.replaceAll("-", "")})
	}
	/* 검색창 엔터 입력 */
	const handleEnterOnInput = (e: any) => {
		if(e.key === 'Enter') {
			const filtered = productData.filter(pd_elem => pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			const foundIndex = productData.findIndex(pd_elem => pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			const target = filtered[0]
			if(foundIndex === -1 || searchInputData.item_nm.length === 0 || filtered.length >= 2) {
				setShowSmlProductList(true)
			} else {
				setSearchInputData({ ...searchInputData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
			}
		}
	}
	/* 검색 버튼 클릭 */
	const handleClickOnSearchButton = () => {
		const value = itemNmRef.current === null ? '' : itemNmRef.current.value
		const foundIndex = productData.findIndex(pd_elem => pd_elem.item_nm.toLowerCase().includes(value.toLowerCase()))
		const filtered = productData.filter(pd_elem => pd_elem.item_nm.toLowerCase().includes(value.toLowerCase()))
		if(foundIndex === -1 || searchInputData.item_nm.length === 0 || filtered.length >= 2) {
			setShowSmlProductList(true)
		} else {
			setSearchInputData({ ...searchInputData, item_nm: filtered[0].item_nm, item: filtered[0].item, item_usr: filtered[0].item_usr })
		}
	}

	/* 품목 검색 모달 입력 완료시 */
	const handleSubmitOnSmlProductSearch = (data: IProductData) => {
		setSearchInputData({ ...searchInputData, item_nm: data.item_nm, item: data.item, item_usr: data.item_usr })
		setShowSmlProductList(false)
	}

	/* 저장 수행 */
	const handleSaveData = async () => {
		setShowSaveModal(false)
		updateProdDaily(searchInputData.ymd, clickedItem, prodDailyDetail)
	}

	/* 컴포넌트 마운트 시 데이터 가져오기 */
	useEffect(() => {
		fetchProductData()
		fetchProdDaily(defaultSearchData.ymd, defaultSearchData.item, defaultSearchData.item_nm)
	}, [])

	/* 버튼 클릭 시 취할 행동 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'search') {
			setClickedItem('a!9$v')
			fetchProdDaily(searchInputData.ymd, searchInputData.item, searchInputData.item_nm)
			dispatch(buttonActions.clear())
		} else if(btnActions.active && btnActions.action === 'save') {
			setShowSaveModal(true)
			dispatch(buttonActions.clear())
		}
	}, [btnActions.action, btnActions.active, dispatch])

	/* 클릭한 아이템 변경 시 상세 일보 불러옴 */
	useEffect(() => {
		fetchProdDailyDetail(searchInputData.ymd, clickedItem)
	}, [clickedItem])

	return (
		<>
			<div className="d-flex my-2 flex-column">
				{/* 날짜 */}
				<div className="d-flex ms-3 my-1">
					<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
						<label className="col-auto align-items-center my-1 me-2">일자</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchInputData.ymd)}
								   onChange={handleChangeOnYMD}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex flex-column flex-xl-row" style={{ overflow: 'auto' }}>
				<div className="d-flex flex-column mx-3" style={{ overflow: 'auto', width: '600px' }}>
					<div className="d-flex mx-3 mb-2">
						<label className="col-auto align-items-center my-1 me-2">품명</label>
						<div className="input-group input-group-sm">
							<input ref={itemNmRef} type="text" className="form-control" style={{ maxWidth: "200px", width: "200px" }}
								   value={searchInputData.item_nm}
								   onChange={handleChangeOnInput}
								   onKeyDown={handleEnterOnInput}/>
							<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
						</div>


					</div>
					<table className="table table-sm table-hover table-bordered table-condensed" style={{ width: '100%' }}>
						<thead>
						<tr>
							{summaryCol.map((value, index) =>
								<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<ClickedItemContext.Provider value={{ clickedItem, setClickedItem }}>
							<ProdDailySummaryList data={prodDailySummary} />
						</ClickedItemContext.Provider>
						</tbody>
					</table>
				</div>

				<div className="d-flex mx-3 me-lg-5 flex-column" style={{ overflow: 'auto', width: '600px' }}>
					<div className="d-flex justify-content-end mb-2">
						<button className={`btn btn-sm ${lossRateCheck ? 'btn-primary' : 'btn-secondary'} me-2`}
								onClick={() => setLossRateCheck(!lossRateCheck)}
						>
							loss율 수정 &nbsp;
							{
								lossRateCheck ?
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
										 fill="currentColor" className="bi bi-toggle-on" viewBox="0 0 16 16">
										<path
											d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
									</svg>
									:
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
										 fill="currentColor" className="bi bi-toggle-off" viewBox="0 0 16 16">
										<path
											d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
									</svg>
							}
						</button>
					</div>
					<table className="table table-sm table-hover table-bordered table-condensed">
						<thead>
						<tr>
							{detailCol.map((value, index) =>
								<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<ProdDailyDetailContext.Provider value={{ prodDailyDetail, setProdDailyDetail }}>
							<ProdDailyDetailList data={prodDailyDetail} lossRateCheck={lossRateCheck} />
						</ProdDailyDetailContext.Provider>
						</tbody>
					</table>
				</div>
			</div>
			<div className="d-flex justify-content-center col-12">
				<PrintedTables summaryData={prodDailySummary} ymd={searchInputData.ymd} />
			</div>

			<SmlProductModal product={productData} show={showSmlProductList} onHide={() => setShowSmlProductList(false)}
							 onSubmit={handleSubmitOnSmlProductSearch} initialSearchData={searchInputData.item_nm} />
			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
			<InfoModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} title={successTitle}
					   message={successMessage} onButtonClick={() => setShowSuccessModal(false)} />


			{/* 정보 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => setShowSaveModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
				<Modal.Header closeButton>
					<Modal.Title>
						loss율 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					수정하신 loss율을 반영하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowSaveModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ProdDaily