
export const SEARCH = 'buttonActions/SEARCH' as const
export const INPUT = 'buttonActions/INPUT' as const
export const SAVE = 'buttonActions/SAVE' as const
export const REMOVE = 'buttonActions/REMOVE' as const
export const PRINT = 'buttonActions/PRINT' as const
export const EXCEL = 'buttonActions/EXCEL' as const
export const CLEAR = 'buttonAcions/CLEAR' as const

export const search = (active: boolean) => ({
    type: SEARCH,
    payload: active
})

export const input = (active: boolean) => ({
    type: INPUT,
    payload: active
})

export const save = (active: boolean) => ({
    type: SAVE,
    payload: active
})

export const remove = (active: boolean) => ({
    type: REMOVE,
    payload: active
})

export const printContents = (active: boolean) => ({
    type: PRINT,
    payload: active
})

export const excel = (active: boolean) => ({
    type: EXCEL,
    payload: active
})

export const clear = () => ({
    type: CLEAR
})

export type buttonActionsTypes =
    ReturnType<typeof search> |
    ReturnType<typeof input> |
    ReturnType<typeof save> |
    ReturnType<typeof remove> |
    ReturnType<typeof printContents> |
    ReturnType<typeof excel> |
    ReturnType<typeof clear>