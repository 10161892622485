import React from 'react'
import {Link, Outlet} from 'react-router-dom'

const Header = () => (
	<>
		<div className="d-flex my-2">
			<div className="col-1" />
			<div className="d-flex col-auto flex-grow-1 justify-content-between">
				<img src="/img/checkinglogo.svg" alt="체킹주식회사 로고" width="160px"/>
				<div className="me-5">
					<div className="me-5">
						<Link className="text-secondary text-decoration-none" to={'/login'}>
							<i className="bi bi-box-arrow-in-right me-2" />
							로그인
						</Link>
					</div>
				</div>
			</div>
		</div>
		<Outlet/>
	</>
)

export default Header