import React from 'react'
import {IProdDailyDetail} from "../../../interfaces/production-manage/i-prod-daily";

export interface IClickedItemContext {
	clickedItem: string,
	setClickedItem: (clickedItem: string) => void
}

export const ClickedItemContext = React.createContext<IClickedItemContext>({
	clickedItem: 'a!9$v',
	setClickedItem: () => {}
})

export interface IProdDailyDetailContext {
	prodDailyDetail: IProdDailyDetail[]
	setProdDailyDetail: (prodDailyDetail: IProdDailyDetail[]) => void
}

export const ProdDailyDetailContext = React.createContext<IProdDailyDetailContext>({
	prodDailyDetail: [] as IProdDailyDetail[],
	setProdDailyDetail: () => {}
})