import {
	AddModModalProps,
	IBeforeProdPerf,
	IProdPerf,
	ISelectedProdPerf
} from "../../../interfaces/production-manage/i-prod-perf";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {OriginalProdPerfContext, SelectedProdPerfContext as OriginalSelectedProdPerfContext} from "./production-performance-context";
import {getDate} from "../../../../lib/util-func";
import {IColumn} from "../../../interfaces/common";
import {Modal} from "react-bootstrap";
import {convertDateWithHyphen} from "../../../../lib/converting-func";
import InfoModal from "../../../common-modals/info-modal";
import {InfoModalContext, NewRowContext, ProdPerfContext, SelectedProdPerfContext} from "./add-mod-modal-context";
import AddModModalList from "./add-mod-modal-list";

import "./styles.css"
import client from "../../../../axios";


export const defaultNewRow = {
	index: -1,
	ymd: '',
	code: '',
	seq: -1,
	item: '',
	item_usr: '',
	item_nm: '',
	spec: '',
	unit: '',
	unit_nm: '',
	qty: '',
	qty_bad: '',
	qty_mix: '',
	acc_gbn: '',
	bigo: '',
}

const AddModModal = ({ show, onHide, fetchProdPerf, addModMode }: AddModModalProps) => {
	/* item_nm 레퍼런스 */
	const ymdRef = useRef<HTMLInputElement>(null)
	/* 컨텍스트 사용 */
	const { clickedProdPerf, setClickedProdPerf } = useContext(OriginalSelectedProdPerfContext)
	const {
		prodPerf: originalProdPerf,
		beforeProdPerf: originalBeforeProdPerf,
		productData
	} = useContext(OriginalProdPerfContext)

	/* 새로운 열 */
	const [ newRow, setNewRow ] = useState<IProdPerf>(defaultNewRow)
	/* 레시피 */
	const [ prodPerf, setProdPerf ] = useState<IProdPerf[]>([])
	const [ beforeProdPerf, setBeforeProdPerf ] = useState<IBeforeProdPerf[]>([])
	const [ searchData, setSearchData ] = useState({ ymd: '' })
	/* 변경되었는지 확인 */
	const [ isChanged, setIsChanged ] = useState(false)
	/* 선택된 상세 생산실적 */
	const [ selectedProdPerf, setSelectedProdPerf ] = useState<ISelectedProdPerf[]>([])
	/* 동작 실패 시 띄울 모달의 정보 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 변경 사항 저장 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)
	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)

	/* 요약 테이블의 칼럼 */
	const columns = useMemo(() => [
		{ name: '✓', style: { minWidth: '40px', textAlign: 'center' } },
		{ name: '번호', style: { minWidth: '40px', textAlign: 'center' } },
		{ name: '코드', style: { minWidth: '60px', textAlign: 'center' } },
		{ name: '품명', style: { width: '200px', textAlign: 'center' } },
		{ name: '규격', style: { width: '200px', textAlign: 'center' } },
		{ name: '단위', style: { minWidth: '40px', textAlign: 'center' } },
		{ name: '생산량', style: { width: '80px', textAlign: 'center' } },
		{ name: '계정구분', style: { minWidth: '80px', textAlign: 'center' } },
		{ name: '비고', style: { width: '200px', textAlign: 'center' } },
	] as IColumn[], [])

	/* 상세 레시피 테이블의 데이터 */
	const data = useMemo(() => prodPerf.filter(element => {
		return clickedProdPerf.ymd === element.ymd && clickedProdPerf.code === element.code
	}).concat(newRow), [prodPerf, newRow, searchData.ymd, clickedProdPerf.ymd])

	const applyChanged = async () => {
		try {
			const response = await client.post('/api/production-manage/update-prod-perf',
				{
					modifiedRows: prodPerf.map((value, index) => {
						return {
							beforeCode: beforeProdPerf[index].code,
							newData: {
								ymd: value.ymd,
								code: value.code,
								seq: value.seq,
								item: value.item,
								qty: value.qty,
								bigo: value.bigo
							}
						}
					})
				})

			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				fetchProdPerf()
				onHide()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 변경 사항 저장 */
	const handleSaveData = async () => {
		try {
			await applyChanged()
			setShowSaveModal(false)
		} catch (e: any) {

		}
	}

	/* 내용 삭제 */
	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post('/api/production-manage/delete-prod-perf',
				{
					deletedRows: selectedProdPerf.map(element => ({
						ymd: element.ymd,
						code: element.code,
						item: element.item
					}))
				})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setIsChanged(false)
				setSelectedProdPerf([])
				fetchProdPerf()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 클릭했던 행에 따라서 데이터 설정 */
	useEffect(() => {
		setSearchData({ ymd: clickedProdPerf.ymd })
		setNewRow({
			...newRow,
			ymd: clickedProdPerf.ymd,
		})
	}, [clickedProdPerf])

	/* 기존 데이터에서 복사해와 이 모달에서 사용 */
	useEffect(() => {
		setProdPerf(originalProdPerf)
		setBeforeProdPerf(originalBeforeProdPerf)
	}, [originalProdPerf, originalBeforeProdPerf])

	/* 추가모드일 시 날짜를 오늘로 맞춤 */
	useEffect(() => {
		if(show) {
			if(addModMode === 1) {
				setSearchData({ ymd: getDate() })
			} else {
				setProdPerf(originalProdPerf)
			}
		}
	}, [addModMode, show])

	/* 추가 모드일 때 품명에 포커싱 */
	useEffect(() => {
		if(show && addModMode === 1)
			ymdRef.current?.focus()
	}, [show, addModMode])

	const handleSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
		let ymd = e.target.value.replaceAll("-", "")
		setSearchData({ ymd } )
		setClickedProdPerf({
			...clickedProdPerf,
			ymd
		})
	}

	return (
		<>
			<Modal show={show} onHide={() => {
				if(isChanged)
					setShowSaveModal(true)
				else
					onHide()
			}} centered size={"xl"}
				   onExited={() => {
					   setSearchData({ ymd: getDate() })
					   setNewRow(defaultNewRow)
					   setIsChanged(false)
				   }}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{"항목" + (addModMode === 1) ? "추가" : "수정"}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="d-flex flex-column mt-2">
						<div className="d-flex align-content-center my-1">
							<div className="d-flex align-content-center mb-2 ms-4">
								<label style={{ textAlign: "end" }} className="me-2 my-1 col-auto">생산일</label>
								<div className="input-group input-group-sm">
									<input type="date" className="form-control form-control-sm"
										   value={convertDateWithHyphen(searchData.ymd)}
										   onChange={handleSearchData}
									/>
								</div>
								<label style={{ textAlign: "end" }} className="me-2 ms-4 my-1 col-auto">생산번호</label>
								<div className="input-group input-group-sm">
									<input style={{ maxWidth: "50px", textAlign: 'center' }} type="text" className="form-control form-control-sm" value={clickedProdPerf.code.charAt(clickedProdPerf.code.length - 1)} disabled/>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex my-3 mx-3" style={{ overflow: 'auto' }}>
						<table className="table table-sm table-bordered table-hover table-condensed">
							<thead>
							<tr>
								{columns.map((value, index) =>
									<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
								)}
							</tr>
							</thead>

							<tbody>
							<ProdPerfContext.Provider value={{ prodPerf, setProdPerf, beforeProdPerf, setBeforeProdPerf, productData, isChanged, setIsChanged}}>
								<NewRowContext.Provider value={{ newRow, setNewRow }}>
									<SelectedProdPerfContext.Provider value={{ selectedProdPerf, setSelectedProdPerf }}>
										<InfoModalContext.Provider value={{ setFailedTitle, setFailedMessage, setShowFailedModal }}>
											<AddModModalList data={data} clickedProdPerf={clickedProdPerf} />
										</InfoModalContext.Provider>
									</SelectedProdPerfContext.Provider>
								</NewRowContext.Provider>
							</ProdPerfContext.Provider>
							</tbody>

						</table>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-sm btn-danger me-2" onClick={() => {
						if(selectedProdPerf.length !== 0)
							setShowDeleteModal(true)
					}}>선택 항목 삭제</button>
					<button className="btn btn-sm btn-primary" onClick={applyChanged}>저장</button>
				</Modal.Footer>
			</Modal>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />

			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(true)} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						선택 항목 삭제
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 정보를 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>

			{/* 변경 내용 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => {
				setShowSaveModal(false)
			}} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						변경 사항 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					변경된 생산실적 정보를 저장하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => {
						setShowSaveModal(false)
						onHide()
					}}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>

		</>
	)
}

export default AddModModal