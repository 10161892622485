import {CHANGE, RESET, changeMenuTypes} from '../actions/change-menu'

const initialState = {
	changed: false
}

type ChangeMenuState = typeof initialState

/* reducer */

const changeMenu = (state: ChangeMenuState = initialState, action: changeMenuTypes) => {
	switch(action.type) {
		case CHANGE:
			return {
				changed: true
			}

		case RESET:
			return {
				changed: false
			}

		default:
			return state
	}
}

export default changeMenu