import React from 'react'
import {IInitialStock, IProductData, IOriginalCarryOver} from '../../../interfaces/stock-manage/i-initial-stock'

export const InitialStockContext = React.createContext<IInitialStockContext>({
	carryOverData: [] as IInitialStock[],
	setCarryOverData: () => {},
	originalCarryOver: [] as IOriginalCarryOver[],
	setOriginalCarryOver: () => {},
	productData: [] as IProductData[],
	newRow: {} as IInitialStock,
	setNewRow: () => {},
	yy: '',
	setShowFailedModal: () => {},
	setFailedMessage: () => {},
	setFailedTitle: () => {},
	selectAll: false
})

export interface IInitialStockContext {
	carryOverData: IInitialStock[];
	setCarryOverData: (carryOver: IInitialStock[]) => void;
	originalCarryOver: IOriginalCarryOver[];
	setOriginalCarryOver: (originalCarryOver: IOriginalCarryOver[]) => void;
	productData: IProductData[];
	newRow: IInitialStock;
	setNewRow: (newRow: IInitialStock) => void;
	yy: string;
	setShowFailedModal: (flag: boolean) => void;
	setFailedMessage: (message: string) => void;
	setFailedTitle: (title: string) => void;
	selectAll: boolean
}