import React, {useContext, useEffect, useState} from 'react'
import {InitialStockContext, IInitialStockContext} from './initial-stock-context'
import AddModal from './add-modal'
import {IInitialStock, IProductData} from '../../../interfaces/stock-manage/i-initial-stock'
import {ISelectedCarryOverContext, SelectedCarryOverContext} from './initial-stock'
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as changeMenuActions from "../../../../redux/actions/change-menu";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";

interface IData {
	index: number,
	yy: string,
	item: string,
	item_usr: string,
	item_nm: string,
	spec: string,
	unit_nm: string,
	qty_bs: string
}

interface CarryOverListProps {
	data: IData[]
}

interface CarryOverRowProps {
	row: IData;
	context: IInitialStockContext;
	selectedContext: ISelectedCarryOverContext;
	focusedIndex: number;
	setFocusedIndex: (index: number) => void;
	dec: number
}

const InitialStockList = ({ data }: CarryOverListProps) => {

	const context = useContext(InitialStockContext)
	const selectedContext = useContext(SelectedCarryOverContext)

	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	useEffect(() => {
		if(context.selectAll)
			selectedContext.setSelectedCarryOver(data.filter(element => element.index !== -1).map(row => ({
				index: row.index,
				item: row.item,
				yy: row.yy
			})))
		else
			selectedContext.setSelectedCarryOver([])


	}, [context.selectAll])

	/* 포커싱 하는 인덱스 */
	const [ focusedIndex, setFocusedIndex ] = useState(-1)
	return (
		<>
			{data.map((element, index) =>
				<InitialStockRow key={index} row={element} context={context} selectedContext={selectedContext}
							  focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} dec={dec} />
			)}
		</>
	)
}

const InitialStockRow = ({ row, focusedIndex, setFocusedIndex, context, selectedContext, dec }: CarryOverRowProps) => {

	/* dispatch 인스턴스 생성 */
	const dispatch = useDispatch()
	/* 입력 전환용 state, false면 일반 레이블, true면 input  */
	const [ itemNmMode, setItemNmMode ] = useState(false)
	const [ qtyBsMode, setQtyBsMode ] = useState(false)
	/* AddModal에 들어갈 initial Value */
	const [ initialSearchData, setInitialSearchData ] = useState('')
	/* addModal 보이기 */
	const [ showAddModal, setShowAddModal ] = useState(false)
	/* 행 선택 */
	const [ checked, setChecked ] = useState(false)

	/* 모두 선택 */
	useEffect(() => {
		if(context.selectAll)
			setChecked(true)
		else
			setChecked(false)
	}, [context.selectAll])

	/* enter를 쳐서 다음 행으로 넘어갔다면 */
	useEffect(() => {
		if(row.index === focusedIndex) {
			if(!itemNmMode && !qtyBsMode) {
				setItemNmMode(true)
			}
		}
	}, [focusedIndex])

	/* 선택된 값 추가/제거 */
	useEffect(() => {
		if(row.index !== -1) {
			if(checked) {
				if(selectedContext.selectedCarryOver.findIndex(elem => elem.item === row.item) === -1)
					selectedContext.setSelectedCarryOver(selectedContext.selectedCarryOver.concat({
						index: row.index,
						item: row.item,
						yy: row.yy
					}))
			} else {
				if(selectedContext.selectedCarryOver.findIndex(elem => elem.item === row.item) !== -1) {
					selectedContext.setSelectedCarryOver(selectedContext.selectedCarryOver.filter(elem => elem.item !== row.item))
				}
			}
		}
	}, [checked, context.selectAll])



	/* selectedCarryOver 길이가 0이 되면 체크 풀기 */
	useEffect(() => {
		if(selectedContext.selectedCarryOver.length === 0) {
			setChecked(false)
		}
	}, [selectedContext.selectedCarryOver])

	/* item_nm 수정 */
	const handleChangeOnItemNm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		dispatch(changeMenuActions.change())
		if(index === -1)
			context.setNewRow({ ...context.newRow, item_usr: '', item_nm: e.target.value })
		else
			context.setCarryOverData(context.carryOverData.map(element => {
				return (index === element.index)
					?
					{ ...element, item_usr: '', item_nm: e.target.value }
					:
					element
			}))
	}
	/* qty_bs 수정 */
	const handleChangeOnQtyBs = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		dispatch(changeMenuActions.change())
		if(index === -1 ) {
			context.setNewRow({ ...context.newRow, qty_bs: e.target.value })
		} else {
			context.setCarryOverData(context.carryOverData.map(element => {
				return (element.index === index) ?
					{ ...element, qty_bs: e.target.value }
					:
					element
			}))
		}
	}
	/* qty_bs 포커스 상실 */
	const handleBlurOnQtyBs = (e: React.FocusEvent<HTMLInputElement>, index: number) => {

		const modifiedQtyBs = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, '')).toString()

		if(index === -1) {
			context.setNewRow(
				{
					...context.newRow, qty_bs:
						(e.target.value === '')
							?
							''
							:
							modifiedQtyBs
				})
		} else {
			context.setCarryOverData(context.carryOverData.map(element => {
				return (element.index === index)
					?
					{
						...element, qty_bs:
							(e.target.value === '')
								?
								''
								:
								modifiedQtyBs
					}
					:
					element
			}))
		}
	}
	/* item_nm에 엔터를 눌렀을 때 발생하는 동작 */
	const handleEnterOnItemNm = (e: any, index: number) => {
		if(e.key === 'Enter' && row.item_usr === '') {
			const filteredProductData = context.productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			if((e.target.value.length <= 1) || filteredProductData.length === 0) {
				/* 입력란의 문자 개수가 1개 이하이거나 입력란에 따라 찾을 수 없다면 */
				setInitialSearchData('')
				setShowAddModal(true)
			} else if(filteredProductData.length >= 2) {
				/* 입력란의 검색 결과가 2개 이상이라면 */
				setInitialSearchData(e.target.value)
				setShowAddModal(true)
			} else {
				const addingData = filteredProductData[0]
				const isOverlap = context.carryOverData
					.findIndex(co_elem =>
					co_elem.item === addingData.item && co_elem.yy === context.yy && co_elem.item_usr !== ''
				) !== -1

				if(isOverlap) {
					/* 중복되는 품목이 있으면 */
					context.setFailedTitle('입력 실패')
					context.setFailedMessage('이미 중복되는 품목이 있습니다.')
					context.setShowFailedModal(true)
					setInitialSearchData('')

					if(index === -1) {
						context.setNewRow({
							index: -1,
							yy: '',
							item: '',
							item_usr: '',
							item_nm: '',
							spec: '',
							unit: '',
							unit_nm: '',
							qty_bs: '',
						})
					} else {
						context.setCarryOverData(context.carryOverData.map(co_elem =>
							(co_elem.index === row.index)
								?
								{ ...co_elem, item: '', item_usr: '', item_nm: '' }
								:
								co_elem
						))
					}
				} else {
					if(index === -1) { /* 아래쪽의 빈 칸을 선택했을 때 */
						const newIndex = getNewIndex()
						context.setCarryOverData(context.carryOverData.concat({
							index: newIndex,
							yy: context.yy,
							item: addingData.item,
							item_usr: addingData.item_usr,
							item_nm: addingData.item_nm,
							spec: addingData.spec,
							unit: addingData.unit,
							unit_nm: addingData.unit_nm,
							qty_bs: ''
						}))
						context.setOriginalCarryOver(context.originalCarryOver.concat({
							index: newIndex,
							yy: context.yy,
							item: ''
						}))
						context.setNewRow({
							index: -1,
							yy: '',
							item: '',
							item_usr: '',
							item_nm: '',
							spec: '',
							unit: '',
							unit_nm: '',
							qty_bs: '',
						})
						setFocusedIndex(newIndex)
						setItemNmMode(false)
						setQtyBsMode(true)
					} else { /* 일반 데이터를 선택했을 때 */
						context.setCarryOverData(context.carryOverData.map(co_elem =>
							(co_elem.index === index)
								?
								{
									...co_elem,
									item: addingData.item,
									item_usr: addingData.item_usr,
									item_nm: addingData.item_nm,
									spec: addingData.spec,
									unit: addingData.unit,
									unit_nm: addingData.unit_nm
								}
								:
								co_elem
						))
						setItemNmMode(false)
						setQtyBsMode(true)
					}
					setInitialSearchData('')
				}
			}
			e.preventDefault()
		} else if (e.key === 'Enter' && row.item_usr !== '') {
			setItemNmMode(false)
			setQtyBsMode(true)
			e.preventDefault()
		}
	}
	/* qty_bs에 엔터를 눌렀을 때 발생하는 동작 */
	const handleEnterOnQtyBs = (e: any) => {
		if(e.key === 'Enter') {
			setFocusedIndex(getNextFocusingCell())
			setItemNmMode(false)
			setQtyBsMode(false)
		}
	}
	/* item_nm 셀에 클릭을 했을 때 */
	const handleClickOnItemNmCell = () => {
		setFocusedIndex(row.index)
		setItemNmMode(true)
		setQtyBsMode(false)
	}
	/* qty_bs 셀에 클릭을 했을 때 */
	const handleClickOnQtyBsCell = () => {
		setFocusedIndex(row.index)
		setItemNmMode(false)
		setQtyBsMode(true)
	}
	/* 셀의 포커스를 상실했을 때 */
	const handleBlurOnItemNmCell = () => {
		setItemNmMode(false)
	}
	const handleBlurOnQtyBsCell = () => {
		setQtyBsMode(false)
	}
	/* 다음 포커싱 열 찾기 */
	const getNextFocusingCell = () => {
		let flag: boolean = false, currentIndex: number = row.index, nextIndex: number = 0

		if(currentIndex === -1) return -1

		for(let i = currentIndex; i < context.carryOverData.length; i++) {
			if(context.carryOverData[i].yy === context.yy && context.carryOverData[i].index > currentIndex) {
				flag = true
				nextIndex = i
				break
			}
		}

		return flag ? nextIndex : -1
	}
	/* submit 확인 */
	const handleSubmit = (startIndex: number, selectedData: IProductData[]) => {
		const newIndex = getNewIndex()
		/* 고른 품목들을 데이터에 추가할 수 있도록 가공 */
		const reducedSelectedData =
			selectedData.reduce(((previousValue, currentValue, index) => {
				return previousValue.concat({
					index: newIndex + index,
					yy: context.yy,
					item: currentValue.item,
					item_usr: currentValue.item_usr,
					item_nm: currentValue.item_nm,
					spec: currentValue.spec,
					unit: currentValue.unit,
					unit_nm: currentValue.unit_nm,
					qty_bs: ''
				})
			}), [] as IInitialStock[])
		if(row.index === -1) {
			context.setCarryOverData(context.carryOverData.concat(reducedSelectedData))
			context.setOriginalCarryOver(context.originalCarryOver.concat(
				reducedSelectedData.map(rsd_elem => ({
					index: rsd_elem.index,
					yy: rsd_elem.yy,
					item: ''
				}))
			))
			setFocusedIndex(newIndex)
		} else {
			const newData = reducedSelectedData[0]
			context.setCarryOverData(context.carryOverData.map(co_elem =>
				(startIndex === co_elem.index)
					?
					{
						...co_elem,
						item: newData.item,
						item_usr: newData.item_usr,
						item_nm: newData.item_nm,
						spec: newData.spec,
						unit: newData.unit,
						unit_nm: newData.unit_nm
					}
					:
					co_elem
			))
		}
		setShowAddModal(false)
		setTimeout(() => {
			setItemNmMode(false)
			setQtyBsMode(true)
		}, 200)
	}
	/* 데이터 추가용 새 인덱스 얻기 */
	const getNewIndex = () => {
		return context.carryOverData.length
	}

	return (
		<>
			<tr>
				<th scope='row' style={{ textAlign: 'center'}}>
					<input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
				</th>
				<td style={{ textAlign: 'center' }}>{row.item_usr}</td>
				<td
					onClick={handleClickOnItemNmCell}
					onBlur={handleBlurOnItemNmCell}>
					{
						itemNmMode
							?
							<input type="text" className="form-control form-control-sm shadow-none" value={row.item_nm}
								   onKeyDown={e => handleEnterOnItemNm(e, row.index)}
								   onChange={e => handleChangeOnItemNm(e, row.index)} autoFocus />
							:
							row.item_nm
					}
				</td>
				<td>{row.spec}</td>
				<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
				<td style={{ textAlign: 'end' }}
					onClick={handleClickOnQtyBsCell}
					onBlur={handleBlurOnQtyBsCell}
				>
					{
						qtyBsMode
							?
							<input type="text" className="form-control form-control-sm shadow-none text-end" value={row.qty_bs}
								   onKeyDown={handleEnterOnQtyBs}
								   onChange={e => handleChangeOnQtyBs(e, row.index)}
								   onBlur={e => handleBlurOnQtyBs(e, row.index)}
								   autoFocus/>
							:
							convertReadable(row.qty_bs, dec)
					}
				</td>
				<td/>
			</tr>

			<AddModal show={showAddModal} onHide={() => setShowAddModal(false)} mode={row.index === -1 ? 1 : 0} context={context}
					  onSubmit={handleSubmit} initialSearchData={initialSearchData} startIndex={row.index} />
		</>
	)
}

export default InitialStockList