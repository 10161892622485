import React, {useState, useEffect, useMemo, useRef} from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {IInitialStock, IProductData, IOriginalCarryOver, ISelectedCarryOver} from '../../../interfaces/stock-manage/i-initial-stock'
import {InitialStockContext} from './initial-stock-context'
import {getDateWithHyphen} from '../../../../lib/converting-func'
import client from '../../../../axios'
import InitialStockList from "./initial-stock-list";
import InfoModal from '../../../common-modals/info-modal'
import {RootReducer} from '../../../../redux/reducers'
import * as buttonActions from '../../../../redux/actions/button'
import {Modal} from 'react-bootstrap'
import {IColumn} from "../../../interfaces/common";

export const SelectedCarryOverContext = React.createContext<ISelectedCarryOverContext>({
    selectedCarryOver: [] as ISelectedCarryOver[],
    setSelectedCarryOver: () => {}
})

export interface ISelectedCarryOverContext {
    selectedCarryOver: ISelectedCarryOver[],
    setSelectedCarryOver: (selectedCarryOver: ISelectedCarryOver[]) => void
}

const InitialStock = () => {



    /* 이월재고 데이터, 품목 데이터, 선택된 행을 담는 state */
    const [ carryOverData, setCarryOverData ] = useState<IInitialStock[]>([])
    /* 데이터 변경 전의 이월재고(일부) */
    const [ originalCarryOver, setOriginalCarryOver ] = useState<IOriginalCarryOver[]>([])
    /* 선택된 이월재고(삭제용) */
    const [ selectedCarryOver, setSelectedCarryOver ] = useState<ISelectedCarryOver[]>([])
    /* 빈칸 */
    const [ newRow, setNewRow ] = useState<IInitialStock>({
        index: -1,
        yy: getDateWithHyphen().slice(0, 4),
        item: '',
        item_usr: '',
        item_nm: '',
        spec: '',
        unit: '',
        unit_nm: '',
        qty_bs: '',
    })
    const [ productData, setProductData ] = useState<IProductData[]>([])
    /* 검색하고자 하는 년도 데이터 */
    const [ searchData, setSearchData ] = useState({ yy: getDateWithHyphen().slice(0, 4) })
    /* 동작 실패 시 띄울 모달의 정보 */
    const [ showFailedModal, setShowFailedModal ] = useState(false)
    const [ failedMessage, setFailedMessage ] = useState('')
    const [ failedTitle, setFailedTitle ] = useState('')
    /* 동작 성공 시 띄울 모달의 정보 */
    const [ showSuccessModal, setShowSuccessModal ] = useState(false)
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ successTitle, setSuccessTitle ] = useState('')
    /* 저장 모달 띄우기 */
    const [ showSaveModal, setShowSaveModal ] = useState(false)
    /* 삭제 모달 띄우기 */
    const [ showDeleteModal, setShowDeleteModal ] = useState(false)
    /* 모두 선택 */
    const [ selectAll, setSelectAll ] = useState(false)


    /* 버튼 액션의 변화를 관찰 */
    const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
    const dispatch = useDispatch()

    /* 테이블의 칼럼 */
    const columns = useMemo(() => [
        {
            name: <input type="checkbox" className="form-check-input" checked={selectAll} onChange={() => setSelectAll(!selectAll)} />,
            style: { width: '40px', minWidth: '40px', textAlign: 'center' }
        },
        { name: '코드', style: {  width: '70px', minWidth: '70px', textAlign: 'center' }  },
        { name: '품명', style: { width: '300px', minWidth: '200px', textAlign: 'center' }  },
        { name: '규격', style: { width: '300px', minWidth: '200px', textAlign: 'center' }  },
        { name: '단위', style: { width: '50px', minWidth: '50px', textAlign: 'center' }  },
        { name: '기초재고수량', style: { width: '150px', minWidth: '120px', textAlign: 'center' }  },
        { name: '', style: {} }
    ] as IColumn[], [selectAll])
    /* 테이블의 데이터 */
    const data = useMemo(() => carryOverData.map(carryOver => (
            {
                index: carryOver.index,
                yy: carryOver.yy,
                item: carryOver.item,
                item_usr: carryOver.item_usr,
                item_nm: carryOver.item_nm,
                spec: carryOver.spec,
                unit_nm: carryOver.unit_nm,
                qty_bs: carryOver.qty_bs
            }
        )
    )
        .filter(element => element.yy === searchData.yy)
        .concat({
            index: newRow.index,
            yy: newRow.yy,
            item: newRow.item,
            item_usr: newRow.item_usr,
            item_nm: newRow.item_nm,
            spec: newRow.spec,
            unit_nm: newRow.unit_nm,
            qty_bs: newRow.qty_bs
    }),[carryOverData, newRow, searchData.yy])

    /* 이월재고 초기 데이터, 품목 불러옴 */
    useEffect(() => {
        /* 이월재고 데이터 */
        fetchCarryOver()
        /* 품목 데이터 */
        client.get('/api/stock-manage/get-product-data')
            .then(res => {
                const { rowsProduct } = res.data.payload
                setProductData(rowsProduct)
            })
    }, [])

    /* 버튼 클릭 시 취할 행동 */
    useEffect(() => {
        if(btnActions.active && btnActions.action === 'save') {
            setShowSaveModal(true)
        } else if(btnActions.active && btnActions.action === 'remove') {
            setShowDeleteModal(true)
        }
    }, [btnActions.action, btnActions.active, dispatch])

    /* 데이터 불러오기 */
    const fetchCarryOver = () => {
        client.get('/api/stock-manage/get-initial-stock')
            .then(res => {
                const { rowsCarryOver } = res.data.payload
                setCarryOverData(rowsCarryOver.map((elem: IInitialStock) => ({
                    ...elem,
                    qty_bs: isNaN(parseFloat(elem.qty_bs)) ? '' : parseFloat(elem.qty_bs).toString()
                })))
                setOriginalCarryOver(rowsCarryOver.map((elem: IInitialStock) => ({
                    index: elem.index,
                    yy: elem.yy,
                    item: elem.item
                })))
            })
    }

    const handleSaveData = async () => {
        setShowSaveModal(false)
        try {
            const response = await client.post('/api/stock-manage/update-initial-stock',
                {
                    modifiedRows: carryOverData.map((value, index) => ({
                        originalItem: originalCarryOver[index].item,
                        newData: {
                            item: value.item,
                            yy: value.yy,
                            qty_bs: parseFloat(value.qty_bs),
                        }
                    }))
                })
            if(response.data.result === 'expired') {
                setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
                setFailedTitle('저장 실패')
                setShowFailedModal(true)
            } else if(response.data.result === 'failed') {
                setFailedMessage('서버에 문제가 발생하였습니다.')
                setFailedTitle('저장 실패')
                setShowFailedModal(true)
            } else {
                setSuccessMessage('기초재고를 성공적으로 저장하였습니다.')
                setSuccessTitle('저장 성공')
                setShowSuccessModal(true)
                fetchCarryOver()
            }
        } catch (e: any) {
            setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
            setFailedTitle('통신 에러')
            setShowFailedModal(true)
        }
    }

    const handleDeleteData = async () => {
        setShowDeleteModal(false)
        try {
            const response = await client.post('/api/stock-manage/remove-initial-stock',
                {
                    deletedRows: selectedCarryOver
                })
            if(response.data.result === 'expired') {
                setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
                setFailedTitle('저장 실패')
                setShowFailedModal(true)
            } else if(response.data.result === 'failed') {
                setFailedMessage('서버에 문제가 발생하였습니다.')
                setFailedTitle('저장 실패')
                setShowFailedModal(true)
            } else {
                setSuccessMessage('기초재고를 성공적으로 삭제하였습니다.')
                setSuccessTitle('삭제 성공')
                setShowSuccessModal(true)
                setSelectAll(false)
                fetchCarryOver()
            }
            setSelectedCarryOver([])
        } catch (e: any) {
            setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
            setFailedTitle('통신 에러')
            setShowFailedModal(true)
        }
    }

    return (
        <>
            {/* 검색란 */}
            <div className="d-flex mt-2">
                <div className="d-flex ms-3">
                    {/* 년도 입력 */}
                    <div className="d-flex align-content-center mx-3">
                        <label className="col-auto align-items-center my-1 mx-1">기준년도</label>
                        <div className="input-group input-group-sm" style={{ width: '60px' }}>
                            <input type="number" className="form-control" style={{ textAlign: 'center' }} value={searchData.yy} onChange={e => setSearchData({ yy: e.target.value })}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-3 mx-3 scrollbar table-wrapper">
                <table className="table table-sm table-bordered table-hover table-condensed">
                    <thead className="sticky-head">
                    <tr>
                        {columns.map((value, index) =>
                            <th key={index} scope="col" style={value.style}>{value.name}</th>
                        )}
                    </tr>
                    </thead>

                    <tbody>
                    <InitialStockContext.Provider value={{
                        carryOverData,
                        setCarryOverData,
                        originalCarryOver,
                        setOriginalCarryOver,
                        productData,
                        newRow,
                        setNewRow,
                        yy: searchData.yy,
                        setShowFailedModal,
                        setFailedMessage,
                        setFailedTitle,
                        selectAll
                    }}>
                        <SelectedCarryOverContext.Provider value={{ selectedCarryOver, setSelectedCarryOver}}>
                            <InitialStockList data={data} />
                        </SelectedCarryOverContext.Provider>
                    </InitialStockContext.Provider>
                    </tbody>
                </table>
            </div>

            <InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
                       message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
            <InfoModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} title={successTitle}
                       message={successMessage} onButtonClick={() => setShowSuccessModal(false)}/>

            {/* 정보 저장 모달 */}
            <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        기초재고 저장
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    입력하신 기초재고를 저장하시겠습니까?
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-secondary my-2" onClick={() => setShowSaveModal(false)}>취소</button>
                    <button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
                </Modal.Footer>
            </Modal>
            {/* 정보 삭제 모달 */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        기초재고 삭제
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    선택하신 기초재고를 삭제하시겠습니까?
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
                    <button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default InitialStock