import {ICusData, ICustomerModal} from "../interfaces/in-out-manage/i-goods-buying";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {convertSaupNoReadable} from "../../lib/converting-func";
import {IColumn} from "../interfaces/common";

export const CustomerSearchModal = ({ cusData, show, onHide, onSubmit, initialSearchData }: ICustomerModal) => {

	/* 거래처 필드 레퍼런스 */
	const inputRef = useRef<HTMLInputElement>(null)
	/* 모달의 검색 데이터와 거리처 데이터를 담는 state */
	const [ searchData, setSearchData ] = useState(initialSearchData)
	/* 클릭으로 선택된 데이터 */
	const [ clickedRow, setClickedRow ] = useState<ICusData | undefined>(undefined)

	/* 모달의 칼럼 */
	const columns = useMemo(() => [
		{ name: "코드", style: { width: '50px', textAlign: "center" } },
		{ name: "거래처", style: { width: '200px', textAlign: "center" }  },
		{ name: "대표자", style: { width: '80px', textAlign: "center" }  },
		{ name: "사업자등록번호", style: { width: '120px', textAlign: "center" }  },
		{ name: "전화", style: { width: '120px', textAlign: "center" }  },
		{ name: "" }
	] as IColumn[], [])

	/* 모달의 데이터 */
	const data = useMemo(() => cusData.filter(cd_elem =>
		searchData.length === 0 || cd_elem.cus_nm.toLowerCase().includes(searchData.toLowerCase())
	), [cusData, searchData])

	useEffect(() => {
		if(show) {
			setTimeout(() => {
				inputRef.current?.focus()
			}, 100)
		}
	}, [show])

	useEffect(() => {
		setSearchData(initialSearchData)
	}, [initialSearchData])

	/* 제출 버튼 클릭 이벤트 */
	const handleInputButton = () => {
		if(clickedRow !== undefined)
			onSubmit(clickedRow)
	}

	const handleClickOnRow = (e: React.MouseEvent<HTMLTableRowElement>, row: ICusData) => {
		if(e.detail === 1)
			setClickedRow(row)
		else if(e.detail === 2)
			onSubmit(row)
	}

	const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === "Enter") {
			setSearchData(inputRef.current === null ? '' : inputRef.current.value)
		}
	}

	const handleClick = () => {
		setSearchData(inputRef.current === null ? '' : inputRef.current.value)
	}

	return (
		<Modal show={show} centered onHide={onHide} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					거래처 검색
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex my-3 mx-3">
					<div className="d-flex align-content-center">
						<label style={{ width: "80px" }}>거래처</label>
						<div className="input-group input-group-sm">
							<input onKeyDown={handleEnter} type="text" className="form-control"
								   onChange={e => setSearchData(e.target.value)}
								   value={searchData}
								   autoFocus />
							{/*
							<span className="input-group-text" onClick={handleClick}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
							*/}
						</div>
					</div>
				</div>

				<div className="d-flex">
					<table className="table table-sm table-hover table-bordered table-condensed">
						<thead>
						<tr>
							{columns.map((cell, index) =>
								<th key={index} scope="col" style={cell.style}>{cell.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						{data.map((row, index) =>
							<tr key={index} onClick={e => handleClickOnRow(e, row)}
								style={(clickedRow !== undefined && row.cus === clickedRow.cus) ? { backgroundColor: '#eeeeee' } : {}}>
								<td style={{ textAlign: "center" }}>{row.cus_usr}</td>
								<td>{row.cus_nm}</td>
								<td style={{ textAlign: "center" }}>{row.owner}</td>
								<td style={{ textAlign: "center" }}>
									{
										row.saup_no === ''
											?
											''
											:
											row.saup_no.slice(0, 3) + '-' + row.saup_no.slice(3, 5) + '-' + row.saup_no.slice(5)
									}
								</td>
								<td style={{ textAlign: "center" }}>{row.tel}</td>
								<td/>
							</tr>
						)}
						</tbody>
					</table>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>닫기</Button>
				<Button variant="primary" onClick={handleInputButton}>입력</Button>
			</Modal.Footer>
		</Modal>
	)
}