export const CHANGE = 'changeMenu/CHANGE'
export const RESET = 'changeMenu/RESET'

export const change = () => ({
	type: CHANGE
})

export const reset = () => ({
	type: RESET
})

export type changeMenuTypes =
	ReturnType<typeof change> |
	ReturnType<typeof reset>