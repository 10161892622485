import React from 'react'
import {StockStatusListProps, StockStatusRowProps} from '../../../interfaces/stock-manage/i-stock-status'
import {accCategory} from '../../../../lib/common-args'
import {convertReadable} from "../../../../lib/converting-func";
import {useSelector, shallowEqual} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";

const StockStatusList = ({ data }: StockStatusListProps) => {
	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((element, index) => <StockStatusRow key={`st-${index}`} row={element} dec={dec} />)}
		</>
	)
}

const StockStatusRow = ({ row, dec }: StockStatusRowProps) => {
	return (
		<tr>
			<th scope='row' style={{ textAlign: 'center' }}>{row.item_usr}</th>
			<td>{row.item_nm}</td>
			<td>{row.spec}</td>
			<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
			<td className={row.stk_bs < 0 ? 'text-danger' : ''} style={{ textAlign: 'end' }}>{convertReadable(String(row.stk_bs), dec)}</td>
			<td style={{ textAlign: 'center' }}>{accCategory.find((element) => element.value === row.acc_gbn)?.name}</td>
			<td/>
		</tr>
	)
}

export default StockStatusList