import {DECREASE, RESET, timerTypes} from '../actions/timer'

const initialState = {
	seconds: 60 * 30
}

type TimerState = typeof initialState

/* reducer */
const timer = (state: TimerState = initialState, action: timerTypes): TimerState => {
	switch(action.type) {
		case DECREASE:
			return {
				seconds: state.seconds - 1
			}

		case RESET:
			return initialState

		default:
			return state
	}
}

export default timer