import React from 'react'

export interface IClickedItemContext {
	clickedItem: string,
	setClickedItem: (clickedItem: string) => void
	shownTable: number,
	setShownTable: (shownTable: number) => void
}

export const ClickedItemContext = React.createContext<IClickedItemContext>({
	clickedItem: '',
	setClickedItem: () => {},
	shownTable: 1,
	setShownTable: () => {}
})