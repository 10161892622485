import React, {useEffect, useMemo, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {IStockStatus} from '../../../interfaces/stock-manage/i-stock-status'
import client from '../../../../axios'
import StockStatusList from './stock-status-list'
import InfoModal from '../../../common-modals/info-modal'
import {getDateWithHyphen} from '../../../../lib/converting-func'
import {RootReducer} from '../../../../redux/reducers'
import * as buttonActions from '../../../../redux/actions/button'
import {accCategory} from '../../../../lib/common-args'
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import SmlProductModal from "../../../common-modals/sml-product-modal";
import {IColumn} from "../../../interfaces/common";

const StockStatus = () => {

	const itemNmRef = useRef<HTMLInputElement>(null)

	const stockStatusHeaders = useMemo(() => [
		{ name: '코드', style: { width: '60px', minWidth: '50px', textAlign: 'center' } },
		{ name: '품명', style: { width: '300px', minWidth: '200px', textAlign: 'center' }  },
		{ name: '규격', style: { width: '300px', minWidth: '200px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '50px', minWidth: '50px', textAlign: 'center' }  },
		{ name: '재고', style: { width: '150px', minWidth: '130px', textAlign: 'center' }  },
		{ name: '계정구분', style: { width: '80px', minWidth: '80px', textAlign: 'center' }  },
		{ name: '', style: {} }
	] as IColumn[], [])

	const defaultSearchData = {
		ymd: getDateWithHyphen(),
		item_nm: '',
		acc_gbn: '00000'
	}

	/* 검색창 값 */
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	/* 불러온 재고 현황(전체) */
	const [ stockStatus, setStockStatus ] = useState<IStockStatus[]>([])
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 품목 데이터 */
	const [ productData, setProductData ] = useState<IProductData[]>([])
	/* 품목 검색 모달 */
	const [ showSmlProductList, setShowSmlProductList ] = useState(false)

	/* 리덕스 버튼 액션 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	useEffect(() => {
		if(btnActions.active && btnActions.action === 'search') {
			fetchStockStatus(searchData.ymd, '', searchData.item_nm, searchData.acc_gbn)
		}
		
		dispatch(buttonActions.clear())
	}, [btnActions.action, btnActions.active, dispatch, searchData.acc_gbn, searchData.item_nm, searchData.ymd])

	useEffect(() => {
		fetchProductData()
	}, [])

	const fetchStockStatus = (ymd: string, item: string, item_nm: string, acc_gbn: string) => {
		client.post('/api/stock-manage/get-stock-status', {
			payload: {
				ymd: ymd.replaceAll('-', ''),
				item,
				item_nm,
				acc_gbn
			}
		})
			.then(res => {
				if(res.data.result === 'expired') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				} else if (res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowStockStatus } = res.data.payload
					setStockStatus(rowStockStatus)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	const fetchProductData = () => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProduct } = res.data.payload
					setProductData(rowsProduct)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 품목 검색에서 엔터 */
	const handleEnterOnInput = (e: any) => {
		if(e.key === 'Enter') {
			const filtered = productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()) || pd_elem.item_usr.includes(e.target.value) || pd_elem.spec.includes(e.target.value)
			)

			if(filtered.length !== 1) {
				setShowSmlProductList(true)
			} else {
				setSearchData({ ...searchData, item_nm: filtered[0].item_nm })
			}
		}
	}

	/* 품목 검색의 검색 버튼 */
	const handleClickOnSearchButton = () => {
		const value = itemNmRef.current === null ? '' : itemNmRef.current.value
		const filtered = productData.filter(elem =>
			(elem.item_nm.toLowerCase().includes(value.toLowerCase()) || elem.item_usr.includes(value) || elem.spec.includes(value))
		)

		if(filtered.length !== 1) {
			setShowSmlProductList(true)
		} else {
			setSearchData({ ...searchData, item_nm: filtered[0].item_nm })
		}
	}

	/* 품목 검색 모달에서 submit 이벤트 */
	const handleSubmitOnSmlProductSearch = (data: IProductData) => {
		setSearchData({ ...searchData, item_nm: data.item_nm })
		setShowSmlProductList(false)
	}

	return (
		<>
			{/* 데이터 검색 메뉴 */}
			<div className="d-flex mt-2">
				<div className="flex-grow-1 d-flex ms-3 flex-column flex-lg-row">

					{/* 날짜 입력창 */}
					<div className="d-flex">
						<div className="d-flex align-content-center mx-3 py-2 py-lg-0 ">
							<label className="col-auto align-items-center my-1 mx-1">일자</label>
							<div className="input-group input-group-sm">
								<input type="date" className="form-control" value={searchData.ymd}
									   onChange={e => {setSearchData({ ...searchData, ymd: e.target.value })}} />
							</div>
						</div>
					</div>

					{/* 품명/규격 */}
					<div className="d-flex">
						<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
							<label className="col-auto align-items-center my-1 mx-1">품명</label>
							<div className="input-group input-group-sm">
								<input type="text" className="form-control" value={searchData.item_nm} style={{ maxWidth: "235px" }}
									   onChange={e => {setSearchData({ ...searchData, item_nm: e.target.value })}}
									   onKeyDown={handleEnterOnInput}
								/>
								<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
							</div>
						</div>
					</div>

					{/* 계정구분 */}
					<div className="d-flex">
						<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
							<label className="col-auto align-items-center my-1 mx-1">계정구분</label>
							{/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
							<select className="form-select form-select-sm" value={searchData.acc_gbn}
									onChange={e => {setSearchData({ ...searchData, acc_gbn: e.target.value })}}>
								{accCategory.map((category, index) =>
									<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
								)}
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="my-3 mx-3 scrollbar table-wrapper">
				<table className="table table-sm table-bordered table-hover table-condensed">
					<thead className="sticky-head">
					<tr>
						{stockStatusHeaders.map((value, index) =>
							<th key={`ssHead-${index + 1}`} style={{ ...value.style, textAlign: 'center' }} scope="col">{value.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<StockStatusList data={stockStatus} />
					</tbody>
				</table>
			</div>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />

			<SmlProductModal product={productData} show={showSmlProductList} onHide={() => setShowSmlProductList(false)}
							 onSubmit={handleSubmitOnSmlProductSearch} initialSearchData={searchData.item_nm} />

		</>
	)
}

export default StockStatus