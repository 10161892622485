import styled from 'styled-components'

const Container = styled.div`
  h6 {		
	color: lightseagreen;
	margin-top: 30px;
	margin-bottom: 10px;
  }
  
  p {
	font-size: 13px;
  }
`

const Terms = () => (
	<Container>
		<h6>&emsp;제 1 조 (목적)</h6>
		<p>체킹주식회사(이하 '회사')는 회사가 제공하는 재고수불 서비스(이하 '재고수불’)를 이용하는 회원(이하 '회원')들이 편리하게 이용할 수 있도록 그에 적용되는 약관(이하 '본 약관')을 마련하였습니다.</p>
		<p>본 약관은 회사와 회원과의 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있습니다.</p>

		<h6>&emsp;제 2 조 (약관의 효력 및 변경)</h6>
		<p>① 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 재고수불 회원 가입시의 화면, 회사 홈페이지 또는 어플리케이션을 통해 공지합니다.</p>
		<p>② 재고수불을 이용하기 위하여 회원으로 가입하는 경우 본 약관을 확인하고 동의하여야 하며, 본 약관은 본 약관에 동의하고 가입한 회원 모두에게 그 효력이 발생합니다.</p>
		<p>③ 회사는 약관의 규제에 관한 법률(이하 '약관규제법'), 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법') 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</p>
		<p>④ 본 약관이 개정되는 경우 회사는 개정사항을 시행일자 7일 전부터 시행일자 전일까지 홈페이지 공지사항을 통해 공지하는 것을 원칙으로 하며, 본 약관을 회원에게 불리한 내용으로 개정하는 경우에는 최소한 시행일자로부터 30일 이상의 유예기간을 두고 공지하며, 홈페이지 공지 외에 회원가입시 기재된 전자우편, 로그인시 동의창을 통해 별도로 개별통지합니다.</p>
		<p>⑤ 회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 동의한 것으로 본다는 뜻을 명확하게 고지하였음에도 회원의 의사표시가 없는 경우에는 개정된 약관에 동의한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회사는 서비스를 제공하지 않거나 본 약관에 따른 이용계약을 해지할 수 있습니다.</p>

		<h6>&emsp;제 3 조 (약관 외 준칙)</h6>
		<p>① 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 회사가 정한 개별약관, 운영정책 및 규칙 등(이하 '세부지침')의 규정에 따릅니다.</p>
		<p>② 회사는 필요한 경우 재고수불 서비스 중 특정 서비스에 적용되는 사항에 대하여 개별약관을 정하여 회원가입시 조건, 이용약관, 개인정보처리방침을 수정할 수 있으며, 해당 내용은 회원의 카카오톡이나 핀플 홈페이지 또는 모바일 어플리케이션 등을 통해 공지합니다.</p>
		<p>③ 회원이 제2항의 개별약관에 동의하고 재고수불 서비스 중 특정서비스를 이용하는 경우에는 해당 특정서비스의 개별약관이 우선적으로 적용됩니다.</p>

		<h6>&emsp;제 4 조 (용어의 정의)</h6>
		<p>본 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.</p>
		<p className="ms-3">1. 회사가 제공하는 무료서비스와 유료서비스를 포함한 서비스를 합하여 '재고수불'이라 하고, 이중 유료서비스는 ‘프리미엄 서비스’라 합니다.</p>
		<p className="ms-3">2. '회원'이라 함은 재고수불 홈페이지에서 본 약관에 동의하고, 서비스를 제공받는 자를 말합니다.</p>
		<p className="ms-3">3. '서비스 이용계약'이라 함은 회사가 제공하는 핀플 서비스를 이용하기 위해 소정의 가입절차를 거쳐 본 약관에 동의하고, 회사로부터 회원가입신청에 대한 승낙을 받는 것을 의미합니다.</p>

		<h6>&emsp;제 5 조 (재고수불 회원가입)</h6>
		<p>① 재고수불 이용하기 위한 회원 가입은 재고수불 홈페이지 또는 어플리케이션의 회원정보등록 화면에서 회원이 되고자 하는 자가 본 약관에 동의하고, 요청되는 정보들을 입력하는 방식으로 이루어집니다.</p>
		<p>② 재고수불 회원이 되고자 하는 자가 제1항에서 정한 방식에 따라 이용신청을 하고, 회사가 위 신청에 대해 회원가입을 승낙함으로써 회원으로 가입되고, 회사는 회원에게 핀플 서비스를 이용하기 위한 계정을 부여합니다.</p>
		<p>③ 회사는 제1항의 이용신청에 대하여 다음 각 호에 해당하는 경우 승낙을 유보하거나 승낙하지 않을 수 있고, 이용신청 시 입력하는 정보가 진실한 것인지 확인하기 위하여 별도의 인증 절차를 거칠 수 있습니다.</p>
		<p className="ms-3">1. 이용자의 정보를 허위로 기재하거나, 필요한 정보를 기재하지 않은 경우</p>
		<p className="ms-3">2. 회사가 제공하는 서비스 관련 설비의 용량에 현실적인 여유가 없는 경우</p>
		<p className="ms-3">3. 회사가 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우</p>
		<p className="ms-3">4. 회사가 재정적, 기술적으로 필요하다고 인정하는 경우</p>
		<p className="ms-3">5. 회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간에 이용계약을 임의로 해지하고 재이용을 신청하는 경우</p>
		<p className="ms-3">6. 관련 법령에 위배되거나 본 약관 및 개별약관, 세부지침 등 회사가 정한 기준에 반하는 경우</p>

		<h6>&emsp;제 6 조 (재고수불 회원의 의무)</h6>
		<p>① 회원은 본 약관 및 회사가 고지하는 내용을 준수해야 하며, 이를 위반하거나 이행하지 아니하여 발생하는 모든 손해에 대한 책임을 부담해야 합니다.</p>
		<p>② 회원은 회사의 명시적 동의 없이 서비스 가입에 따른 본 약관상의 지위 또는 권리, 의무의 전부 또는 일부를 제3자에게 양도하거나 위임할 수 없으며 담보 제공 등의 목적으로 처분할 수 없습니다.</p>
		<p>③ 회원은 재고수불을 이용할 때 아래 각 호의 행위는 하여서는 안 됩니다.</p>
		<p className="ms-3">1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 아이디 및 비밀번호를 도용, 부정하게 사용하는 행위</p>
		<p className="ms-3">2. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위</p>
		<p className="ms-3">3. 재고수불 서비스와 관련된 설비의 오작동이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위</p>
		<p className="ms-3">4. 재고수불 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해하는 행위, 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여하는 행위, 타인에게 그 이용을 허락하는 행위, 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 행위 , 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위</p>
		<p className="ms-3">5. 다른 회원의 정보를 수집, 저장, 공개하는 행위</p>
		<p className="ms-3">6. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스를 유포하는 행위</p>
		<p className="ms-3">7. 기타 재고수불 서비스의 운영을 방해하는 불법 행위</p>
		<p>④ 회원이 관련 법령, 본 약관 및 특정 서비스 이용시 개별약관, 정책 등을 준수하지 않는다면, 회사는 회원의 위반행위 등을 조사할 수 있고, 핀플 이용을 잠시 또는 계속하여 중단하거나, 회원가입승낙을 일방적으로 철회하고, 회원으로 재가입에 제한을 둘 수도 있습니다.</p>

		<h6>&emsp;제 7 조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)</h6>
		<p>① 재고수불 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.</p>
		<p>② 회사는 회원의 아이디가 개인정보 유출 우려가 있는 경우, 반사회적 또는 미풍양속에 어긋나는 경우, 회사 및 회사의 운영자로 오인할 우려가 있는 경우 등은 해당 아이디의 이용을 제한할 수 있습니다.</p>
		<p>③ 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</p>
		<p>④ 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않으며, 회원은 회사에 대하여 통지의무 불이행으로 인한 손해배상 책임을 부담할 수 있습니다.</p>

		<h6>&emsp;제 8 조 환불</h6>
		<p>① 회원은 다음 각 호의 사유가 발생한 경우, 회사에 환불을 받을 계좌번호를 적은 환불신청서를 제출하여 포인트를 환불받을 수 있습니다.</p>
		<p className="ms-3">1. 천재지변 등 예상치 못한 불가항력적인 사유가 발생하여 더 이상 재고수불 서비스 제공이 불가능한 경우</p>
		<p className="ms-3">2. 회원이 재고수불 회원을 탈퇴하는 경우</p>

		<h6>&emsp;제 9 조 (서비스 이용 신청)</h6>
		<p>① 회원은 회사 홈페이지 또는 어플리케이션을 통해 제 4조의 프리미엄서비스포를 이용하여 재고수불프로그램의 지속적인 사용을 신청할 수 있습니다.</p>
		<p>② 회사는 프리미엄 회원서비스를 이용하는 회원에게 아래와 같은 부가 서비스를 제공합니다.</p>
		<p className="ms-3">■ 재고수불프로그램의 지속적인 사용을 승인 함</p>

		<h6>&emsp;제 10 조 (필요에 따른 서비스의 변경 및 중단)</h6>
		<p>① 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있으며, 이에 대하여 관계 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</p>
		<p>② 재고수불 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경된 내용을 적용하기 7일 전 회사 홈페이지에 게시하여야 합니다.</p>
		<p>③ 회사는 컴퓨터 등 정보통신설비의 보수점검교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 별도의 공지 없이 서비스의 제공이 일시적으로 중단될 수 있습니다. 이 경우 회사는 가능한 신속하게 홈페이지에 서비스 중단사유 및 예상되는 중단기간을 공지하도록 합니다.</p>
		<p>④ 회사는 고의 또는 과실이 아닌 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지 않습니다.</p>
		<p>⑤ 회사가 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우 회사는 이용자에게 통지한 후 서비스를 종료할 수 있습니다.</p>

		<h6>&emsp;제 11 조 (회원정보의 변경)</h6>
		<p>① 회원은 회사 홈페이지, 어플리케이션의 사용회사정보등록 및 환경설정을 통하여 언제든지 회원의 정보를 열람하고 수정할 수 있습니다.</p>
		<p>② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 제1항에 따라 변경된 내용에 따라 회원정보를 수정을 하여야 합니다.</p>
		<p>③ 제2항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>
		<p>④ 회원에 관한 정보가 변경되었을 경우 해당 회원은 기존에 회사로부터 제공받던 서비스의 이용에 제한을 받을 수 있으며, 이 경우 회사는 이메일 등 적절한 방식을 통해 해당 회원에게 통지를 합니다.</p>

		<h6>&emsp;제 12 조 (회원 탈퇴 및 재고수불 회원계약 해지)</h6>
		<p>① 회원은 언제든지 회사 홈페이지, 어플리케이션, 고객센터를 통하여 재고수불 회원을 탈퇴할 수 있습니다.</p>
		<p>② 회원이 재고수불 탈퇴를 신청한 경우 회사는 본인 여부를 확인할 수 있으며, 관계 법령 등이 정하는 바에 따라 이를 즉시 처리합니다.</p>
		<p>③ 회원이 회원탈퇴를 하더라도 이미 이용한 재고수불 서비스에 대해서는 본 약관이 계속 적용되며, 관련법 및 개인정보 처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 혜택은 소멸됩니다.</p>
		<p>④ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 재고수불 회원계약을 해지하거나 또는 서비스 이용을 제한하는 등의 조치를 취할 수 있습니다.</p>
		<p className="ms-3">1. 회원이 본 약관을 위반하여 서비스 제공에 지장을 주거나 중대한 손해를 주었을 때</p>
		<p className="ms-3">2. 가입신청시에 허위 내용을 등록하거나 타인의 정보를 도용한 경우</p>
		<p className="ms-3">3. 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</p>
		<p className="ms-3">4. 사이트를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</p>
		<p className="ms-3">5. 회사의 서비스 제공과 관련하여 허위의 사실을 적시하거나 유포하여 회사의 명예를 실추시키거나 회사의 신뢰성을 해하는 경우</p>
		<p className="ms-3">6. 제3자를 대상으로 회사의 서비스를 영리 목적으로 무단 이용하는 경우</p>
		<p className="ms-3">7. 서비스의 이용과정에서 회사의 직원에게 폭언, 협박 또는 음란한 언행 등으로 회사의 업무를 방해하는 경우</p>
		<p className="ms-3">8. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하는 경우</p>
		<p className="ms-3">9. 금융실명거래 및 비밀보장에 관한 법률 등 금융거래 관련 법령을 준수하지 않은 경우</p>
		<p className="ms-3">10. 서비스를 이용하여 범죄행위를 하거나 범죄행위를 교사방조한 경우</p>
		<p className="ms-3">11. 회원의 서비스 이용이 회사의 영업상 또는 다른 업무에 문제가 있다고 판단된 경우</p>
		<p>⑤ 회사가 회원 자격을 제한 또는 정지시킨 후에도, 전항 각 호의 사유가 발생하여 회사로부터 일정한 기간을 정하여 시정 요구를 받고 그 기간 내에 시정되지 아니하는 경우 회사는 회원의 자격을 상실시킬 수 있습니다.</p>
		<p>⑥ 회사가 회원 자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</p>
		<p>⑦ 회사는 일년 동안 서비스 이용기록이 없는 회원의 정보를 일반 회원의 정보와 별도 분리보관 합니다. 단, 이용자의 별도 요청에 따라 유효기간을 달리 정한 경우에는 그 기간으로 합니다.</p>

		<h6>&emsp;제 13 조 (정보의 제공 및 광고의 게재)</h6>
		<p>① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항, 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.</p>
		<p>② 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 회원은 수신거절을 할 수 있습니다.</p>

		<h6>&emsp;제 14 조 (면책조항)</h6>
		<p>① 천재지변, 전쟁, 금융회사 또는 제휴사의 사유, 기타 불가항력 등 회사의 귀책사유 없이 본 서비스를 제공할 수 없을 경우에 회사는 본 서비스 제공에 관한 책임을 지지 아니합니다.</p>
		<p>② 회원이 본 서비스를 이용함에 있어서 본 약관 또는 관계 법령을 위반하여 야기되는 문제나 손실에 대하여는 회사가 책임을 지지 아니하며, 이로 인하여 회사가 손해를 입을 경우에는 회원이 회사에 배상하여야 합니다.</p>
		<p>③ 회사는 재고수불 서비스를 통해 제공되는 자료의 진실성을 보장하지 않고, 회사가 재고수불 서비스를 통해 제공하는 자료의 최종적인 확인책임은 회원에게 있으며, 회원은 재고수불 서비스를 통해 제공받은 정보, 자료, 사실의 오류로 인한 손해배상 청구 등을 회사에 청구할 수 없습니다.</p>

		<h6>제 15 조(서비스 업무의 위탁 및 동의)</h6>
		<p>회원은 업무의 원활과 효율성을 위해 본 서비스에 대한 일체의 업무를 ㈜핀플러스글로벌에게 위탁할 수 있고, 회원은 특별한 사유가 아니면 이의 없이 동의합니다.</p>
		<p className="ms-3">1. 재고수불 제공에 필요한 정보 수정</p>
		<p className="ms-3">2. 기타 정보 수정</p>

		<h6>&emsp;제 16 조 (손해배상)</h6>
		<p>① 회사는 회사가 제공하는 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사는 회원이 재고수불 서비스를 이용하여 제공받은 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 손해에 대하여는 책임을 부담하지 아니합니다.</p>
		<p>② 회사의 고의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만 회사는 회사의 고의, 과실 없이 발생된 다음 각 호의 손해에 대해서는 책임을 부담하지 않습니다.</p>
		<p className="ms-3">1. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해</p>
		<p className="ms-3">2. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우</p>
		<p className="ms-3">3. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해</p>
		<p className="ms-3">4. 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해</p>
		<p className="ms-3">5. 제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해</p>
		<p className="ms-3">6. 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</p>
		<p className="ms-3">7. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해</p>
		<p className="ms-3">8. 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</p>
		<p>② 유료서비스인 ‘프리미엄서비스’를 이용 중인 회원의 고의 과실로 인하여 회사가 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회사는 회원에게 손해배상을 청구할 수 있고, 회원은 손해배상금 외에 상환일까지의 이자와 소송비용을 부담해야 합니다.</p>

		<h6>&emsp;제 17 조 (회사의 의무)</h6>
		<p>① 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 재고수불 서비스를 제공하는데 최선을 다하여야 합니다.</p>
		<p>② 회사는 회원이 안전하게 재고수불을 이용할 수 있도록 회원의 개인정보보호를 위한 보안 시스템을 갖추어 이용자의 개인정보보호에 최선을 다하여야 합니다.</p>
		<p>③ 회사는 수신거절의 의사를 명확히 표시한 회원에 대해서는 영리목적의 광고성 문자메시지 및 전자우편을 발송하지 않습니다.</p>

		<h6>&emsp;제 18 조 (분쟁의 해결)</h6>
		<p>본 약관 또는 서비스는 대한민국 법령에 의하여 규정되고 이행됩니다. 재고수불 이용과 관련하여 회사와 회원 간에 분쟁이 발생하면 회사는 분쟁 해결을 위해 성실히 협의할 것입니다.</p>
		<p>그럼에도 불구하고 해결되지 않으면 민사소송법상의 관할법원에 소를 제기할 수 있습니다. 본 약정과 관련하여 회사와 회원간에 소송상 분쟁이 발생한 경우에는 회사의 주소지 관할법원을 전속적, 배타적 관할법원으로 하여 분쟁을 해결하기로 합니다.</p>
		<p className="ms-3">[부칙] 본 약관은 2022년 4월 1일부터 시행합니다.</p>
	</Container>
)

export default Terms