import React, {useState, useEffect, useMemo, useContext, useRef} from "react"
import {Modal} from 'react-bootstrap'
import {IColumn} from "../../../interfaces/common"
import {IAddModModal, IBeforeRecipe, IRecipe, ISelectedRecipe} from '../../../interfaces/production-manage/i-add-recipe'
import {SelectedParentProductContext} from './add-recipe-context'
import {InfoModalContext, NewRowContext, RecipeContext, SelectedRecipeContext} from './add-mod-modal-context'
import {RecipeContext as OriginalRecipeContext} from './add-recipe-context'
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import InfoModal from "../../../common-modals/info-modal";
import AddModModalList from "./add-mod-modal-list";
import client from "../../../../axios";
import SmlProductModal from "../../../common-modals/sml-product-modal";

export const defaultNewRow = {
	index: -1,
	item_p: '',
	item_p_usr: '',
	item_p_nm: '',
	spec_p: '',
	unit_p: '',
	unit_p_nm: '',
	item: '',
	item_usr: '',
	item_nm: '',
	spec: '',
	unit: '',
	unit_nm: '',
	acc_gbn: '',
	seq: -1,
	qty: '',
	loss_rate: '',
	bigo: ''
} as IRecipe

const AddModModal = ({ show, onHide, fetchRecipe, addModMode }: IAddModModal) => {

	/* item_nm 레퍼런스 */
	const itemNmRef = useRef<HTMLInputElement>(null)
	/* 컨텍스트 사용 */
	const { clickedParentProduct, setClickedParentProduct } = useContext(SelectedParentProductContext)
	const {
		recipe: originalRecipe,
		beforeRecipe: originalBeforeRecipe,
		productData
	} = useContext(OriginalRecipeContext)

	/* 새로운 열 표현 */
	const [ newRow, setNewRow ] = useState<IRecipe>(defaultNewRow)
	/* 레시피 */
	const [ recipe, setRecipe ] = useState<IRecipe[]>([])
	const [ beforeRecipe, setBeforeRecipe ] = useState<IBeforeRecipe[]>([])
	const [ searchData, setSearchData ] = useState({ item: '', item_usr: '', item_nm: '' })
	/* smlProductModal 전용 */
	const [ initialSmlPrdSearchData, setInitialSmlPrdSearchData ] = useState('')
	const [ showSmlPrdModal, setShowSmlPrdModal ] = useState(false)
	/* 선택된 상세 레시피 */
	const [ selectedRecipe, setSelectedRecipe ] = useState<ISelectedRecipe[]>([])
	/* 변경되었는지 확인 */
	const [ isChanged, setIsChanged ] = useState(false)
	/* 동작 실패 시 띄울 모달의 정보 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 변경 사항 저장 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)
	/* 저장 성공 시 띄울 모달의 정보 */
	const [ showSuccessModal, setShowSuccessModal ] = useState(false)
	const [ successMessage, setSuccessMessage ] = useState('')
	const [ successTitle, setSuccessTitle ] = useState('')
	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)
	/* 레시피 배율의 총합 */
	const [ total, setTotal ] = useState(0.0)

	/* 상세 레시피 테이블 칼럼 */
	const columns = useMemo(() => [
		{ name: '✓', style: { width: '20px', textAlign: 'center' } },
		{ name: 'No', style: {  width: '30px', textAlign: 'center' }  },
		{ name: '코드', style: { width: '40px', textAlign: 'center' }  },
		{ name: '품명(원재료)', style: { width: '200px', textAlign: 'center' }  },
		{ name: '규격', style: { width: '200px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '40px', textAlign: 'center' }  },
		{ name: '계정구분', style: { width: '70px', textAlign: 'center' } },
		{ name: '제조비율', style: { width: '70px', textAlign: 'center' } },
		{ name: 'loss율', style: { width: '70px', textAlign: 'center' } },
	] as IColumn[], [])
	/* 상세 레시피 테이블 데이터 */
	const data = useMemo(() => recipe.filter(element => clickedParentProduct.item_p === element.item_p)
			.concat(newRow),
		[recipe, newRow, searchData.item_nm, clickedParentProduct.item_p])

	/* SmlSearchData 보이기 */
	const handleSubmit = (prd: IProductData) => {
		const isOverlap = recipe.find(r_elem =>
			r_elem.item_p === prd.item) !== undefined

		if(isOverlap) {
			setFailedTitle('입력 실패')
			setFailedMessage('선택하신 항목에 대해 이미 레시피가 존재합니다.')
			setShowFailedModal(true)
		} else {
			setSearchData({ item_usr: prd.item_usr, item_nm: prd.item_nm, item: prd.item })
			setShowSmlPrdModal(false)
		}
	}

	/* 품명에 엔터 */
	const handleEnterOnItemNm = (e: any) => {
		if(e.key === 'Enter' && addModMode === 1) {
			const filteredPrdData = productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))

			if((e.target.value.length <= 1) || filteredPrdData.length === 0) {
				setInitialSmlPrdSearchData('')
				setShowSmlPrdModal(true)
			} else if (filteredPrdData.length >= 2) {
				setInitialSmlPrdSearchData(e.target.value)
				setShowSmlPrdModal(true)
			} else {
				const addingData = filteredPrdData[0]
				const isOverlap = recipe.find(r_elem =>
					r_elem.item_p === addingData.item) !== undefined

				if(isOverlap) {
					setFailedTitle('입력 실패')
					setFailedMessage('선택하신 항목에 대해 이미 레시피가 존재합니다.')
					setShowFailedModal(true)
				} else {
					setSearchData({ ...searchData, item_nm: addingData.item_nm, item_usr: addingData.item_usr, item: addingData.item })
				}
			}
			e.preventDefault()
		}
	}

	/* 내용 저장 */
	const applyChanged = async () => {

		if(searchData.item_usr === '') {
			setFailedMessage('모품명을 입력하세요.')
			setFailedTitle('저장 실패')
			setShowFailedModal(true)
			return
		}

		try {
			const response = await client.post('/api/production-manage/update-recipe',

				{
					modifiedRows: recipe.map((value, index) => ({
						beforeItemP: beforeRecipe[index].item_p,
						beforeItem: beforeRecipe[index].item,
						newData: {
							item_p: searchData.item,
							item: value.item,
							seq: value.seq,
							qty: value.qty === '' ? '0' : value.qty,
							loss_rate: value.loss_rate === '' ? '0' : value.loss_rate
						}
					}))
				})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				fetchRecipe()
				onHide()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 내용 삭제 */
	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post('/api/production-manage/delete-recipe',
				{
					deletedRows: selectedRecipe.map(element => ({
						item_p: element.item_p,
						item: element.item
					}))
				})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setSelectedRecipe([])
				fetchRecipe()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 변경 사항 저장 */
	const handleSaveData = async () => {
		try {
			await applyChanged()
			setShowSaveModal(false)
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 선택한 모품목에 따라 검색 데이터, newRow의 기본정보 설정 */
	useEffect(() => {
		setSearchData({ ...searchData, item_nm: clickedParentProduct.item_p_nm, item_usr: clickedParentProduct.item_p_usr, item: clickedParentProduct.item_p })
		setNewRow({
			...newRow,
			item_p: clickedParentProduct.item_p,
			item_p_usr: clickedParentProduct.item_p_usr,
			item_p_nm: clickedParentProduct.item_p_nm
		})
	}, [clickedParentProduct])

	/* 추가 모드일 시 검색 데이터 클리어, 선택된 데이터 해제 */
	useEffect(() => {
		if(show) {
			if(addModMode === 1) {
				setSearchData({ ...searchData, item_nm: '' })
				setClickedParentProduct({ index: -2, item_p: '', item_p_usr: '', item_p_nm: '' })
			} else {
				setRecipe(originalRecipe)
			}
		}
	}, [addModMode, show])

	/* 기존 데이터에서 복사해와 이 모달에서 사용 */
	useEffect(() => {
		setRecipe(originalRecipe)
		setBeforeRecipe(originalBeforeRecipe)
	}, [originalRecipe, originalBeforeRecipe])

	/* data 변수로부터 총합 계산 */
	useEffect(() => {
		let sum = 0
		for(let row of data)
			sum += isNaN(parseFloat(row.qty)) ? 0 : parseFloat(row.qty)
		setTotal(sum)
	}, [data])

	/* 추가 모드일 때 품명에 포커싱 */
	useEffect(() => {
		if(show && addModMode === 1)
			itemNmRef.current?.focus()
	}, [show, addModMode])

	return (
		<>
			<Modal show={show} onHide={() => {
				if(isChanged)
					setShowSaveModal(true)
				else {
					onHide()
				}

				setIsChanged(false)
			}} centered size={"xl"}
				   onExited={() => {
					   setSearchData({ ...searchData, item_nm: '' })
					   setNewRow({
						   ...defaultNewRow,
						   item_p: clickedParentProduct.item_p,
						   item_p_usr: clickedParentProduct.item_p_usr,
						   item_p_nm: clickedParentProduct.item_p_nm
					   })
				   }}>
				<Modal.Header closeButton>
					{"항목" + (addModMode === 1) ? "추가" : "수정"}
				</Modal.Header>

				<Modal.Body>
					<div className="d-flex flex-column mt-2">
						<div className="d-flex align-content-center my-1">
							<div className="d-flex align-content-center mb-2">
								<label style={{ width: "70px", textAlign: "end"}} className="me-2">품명</label>
								<div className="input-group input-group-sm">
									<span className="input-group-text addonSize">
										{searchData.item_usr}
									</span>
									<input ref={itemNmRef} type="text" className="form-control" readOnly={addModMode === 2}
										   value={searchData.item_nm} onChange={e => setSearchData({item_usr: '', item_nm: e.target.value, item: ''})}
										   onKeyDown={handleEnterOnItemNm}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex my-3 mx-3" style={{ overflow: 'auto' }}>
						<table className="table table-sm table-bordered table-hover table-condensed">
							<thead>
							<tr>
								{columns.map((value, index) =>
									<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
								)}
							</tr>
							</thead>

							<tbody>
							<RecipeContext.Provider value={{ recipe, setRecipe, beforeRecipe, setBeforeRecipe, productData, isChanged, setIsChanged }}>
								<NewRowContext.Provider value={{ newRow, setNewRow }}>
									<InfoModalContext.Provider value={{ setFailedTitle, setFailedMessage, setShowFailedModal }}>
										<SelectedRecipeContext.Provider value={{ selectedRecipe, setSelectedRecipe }}>
											<AddModModalList data={data} clickedParentProduct={clickedParentProduct}/>
										</SelectedRecipeContext.Provider>
									</InfoModalContext.Provider>
								</NewRowContext.Provider>
							</RecipeContext.Provider>
							</tbody>
						</table>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<div className="d-flex flex-grow-1 mb-2 mt-1 px-3 py-1 justify-content-end" style={{ flexBasis: '100%', background: '#eeeeee' }}>
						제조비율 합계: {total.toFixed(3)}%
					</div>
					<button className="btn btn-sm btn-danger me-2" onClick={() => {
						if(selectedRecipe.length !== 0)
							setShowDeleteModal(true)
					}}>선택 항목 삭제</button>
					<button className="btn btn-sm btn-primary" onClick={applyChanged}>저장</button>
				</Modal.Footer>
			</Modal>

			<SmlProductModal product={productData} show={showSmlPrdModal} onHide={() => setShowSmlPrdModal(false)}
							 onSubmit={handleSubmit} initialSearchData={initialSmlPrdSearchData} />
			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
			<InfoModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} title={successTitle}
					   message={successMessage} onButtonClick={() => setShowSuccessModal(false)} />

			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(true)} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						선택 항목 삭제
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 정보를 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>

			{/* 변경 내용 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => {
				setShowSaveModal(false)
			}} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						변경 사항 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					변경된 레시피 정보를 저장하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => {
						setShowSaveModal(false)
						setRecipe([])
						setBeforeRecipe([])
						onHide()
					}}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddModModal