import React, {useEffect, useMemo, useRef, useState} from 'react'
import {IColumn} from '../../../interfaces/common'
import {
	ISummaryData,
	IDetailData,
	IBuyData,
	ICusData, IBeforeBuyData, IYmdCus,
} from '../../../interfaces/in-out-manage/i-goods-buying'
import { useSelector, useDispatch, shallowEqual} from 'react-redux'
import {getDate} from '../../../../lib/util-func'
import client from '../../../../axios'
import {DetailList, SummaryList} from "./goods-buying-list";
import {convertDateWithHyphen} from '../../../../lib/converting-func'
import {CustomerSearchModal} from "../../../common-modals/customer-search-modal";
import {BuyDataContext, ClickedSummaryContext, SelectedCustomersContext} from './goods-buy-sell-context'
import {AddModModal} from "./add-mod-modal";
import {Modal} from "react-bootstrap";
import * as buttonActions from '../../../../redux/actions/button'
import {RootReducer} from '../../../../redux/reducers'
import InfoModal from "../../../common-modals/info-modal";

const GoodsBuying = ({ buySellMode }: {buySellMode: number}) => {

	const cusNmRef = useRef<HTMLInputElement>(null)

	/* 기본 검색 데이터 */
	const defaultSearchData = {
		ymd1: getDate(),
		ymd2: getDate(),
		cus_nm: ''
	}

	/* 전체 데이터 */
	const [ buyData, setBuyData ] = useState<IBuyData[]>([])
	/* 변경 전 데이터 */
	const [ beforeBuyData, setBeforeBuyData ] = useState<IBeforeBuyData[]>([])
	/* 검색 데이터 */
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	const [ applyData, setApplyData ] = useState(defaultSearchData)
	/* 요약에서 선택된 행 */
	const [ clickedCustomer, setClickedCustomer ] = useState({ cus: '', cus_usr: '', cus_nm: '', ymd: '' })
	/* 체크박스로부터 선택된 행의 모음 */
	const [ selectedCustomers, setSelectedCustomers ] = useState<IYmdCus[]>([])
	/* 모두 선택 */
	const [ selectAll, setSelectAll ] = useState(false)
	/* 거래처 데이터 */
	const [ cusData, setCusData ] = useState<ICusData[]>([])
	/* 거래처 찾기 */
	const [ showCusSearchModal, setShowCusSearchModal ] = useState(false)
	const [ showAddModModal, setShowAddModModal ] = useState(false)
	const [ addModMode, setAddModMode ] = useState(1)

	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)
	/* 동작 실패 시 띄울 모달의 정보 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	/* 요약 테이블의 칼럼 */
	const summaryCol = useMemo(() => [
		{
			name: <input type="checkbox" className="form-check-input" checked={selectAll} onChange={() => setSelectAll(!selectAll)} />,
			style: { width: '40px', textAlign: 'center' }
		},
		{ name: '일자', style: { width: '100px', textAlign: 'center' } },
		{ name: '거래처', style: { width: '200px', textAlign: 'center' } },
		{ name: '품명', style: { textAlign: 'center' } },
	] as IColumn[], [selectAll])

	/* 상세 테이블의 칼럼 */
	const detailCol = useMemo(() => [
		{ name: '품명', style: { width: '200px', textAlign: 'center' } },
		{ name: '규격', style: { width: '200px', textAlign: 'center' } },
		{ name: '단위', style: { width: '50px', textAlign: 'center' } },
		{ name: '수량', style: { width: '150px', textAlign: 'center' } },
		{ name: '비고', style: { textAlign: 'center' } }
	] as IColumn[], [])


	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 요약, 상세 데이터 */
	const summaryData = useMemo(() => buyData.reduce((prev, cur) => {
		/* 같은 거래처들끼리 모아놓음 */
		const foundIndex = prev.findIndex(element => element.cus === cur.cus && element.ymd === cur.ymd)
		if(foundIndex === -1) {
			prev = prev.concat({
				index: cur.index,
				corp: cur.corp,
				ymd: cur.ymd,
				cus: cur.cus,
				cus_usr: cur.cus_usr,
				cus_nm: cur.cus_nm,
				item_nm: cur.item_nm
			})
		}
		return prev
	}, [] as ISummaryData[])
		.map(element => {
			/* 여러 아이템이 있을 경우 외 n건 붙임 */
			const count = buyData.filter(bd_elem => {
				return (element.cus === bd_elem.cus && element.ymd === bd_elem.ymd)
			}).length

			if(count !== 1)
				element.item_nm = `${element.item_nm} 외 ${count - 1}건`

			return element
		})
			.filter(element => {
				/* 검색란에 검색 */
				let ymd = false, cus_nm = false
				let ymdNum = parseInt(element.ymd), ymd1Num = parseInt(applyData.ymd1), ymd2Num = parseInt(applyData.ymd2)

				if(applyData.cus_nm.length <= 1 || element.cus_nm.toLowerCase().includes(applyData.cus_nm.toLowerCase()))
					cus_nm = true

				if(ymdNum >= ymd1Num && ymdNum <= ymd2Num)
					ymd = true

				return ymd && cus_nm
			})
		, [buyData, applyData.ymd1, applyData.ymd2, applyData.cus_nm])

	const detailData = useMemo(() => buyData.filter(element => element.cus_usr === clickedCustomer.cus_usr && element.ymd === clickedCustomer.ymd)
		.reduce((prev, cur) => {
			return prev.concat({
				index: cur.index,
				item: cur.item,
				item_usr: cur.item_usr,
				item_nm: cur.item_nm,
				cus: cur.cus,
				spec: cur.spec,
				unit: cur.unit,
				unit_nm: cur.unit_nm,
				qty: cur.qty,
				bigo: cur.bigo
			})
		}, [] as IDetailData[]), [clickedCustomer, buyData])

	/* 버튼 클릭 시 취할 행동 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'remove' && selectedCustomers.length !== 0) {
			setShowDeleteModal(true)
		} else if(btnActions.active && btnActions.action === 'search') {
			if(parseInt(searchData.ymd1) > parseInt(searchData.ymd2)) {
				setShowFailedModal(true)
				setFailedTitle('잘못된 검색 데이터')
				setFailedMessage('올바르지 않은 기간입니다.')
			} else {
				setApplyData(searchData)
			}
			dispatch(buttonActions.clear())
		} else if (btnActions.active && btnActions.action === 'input') {
			setAddModMode(1)
			setShowAddModModal(true)
			setClickedCustomer({
				cus: '',
				cus_usr: '',
				cus_nm: '',
				ymd: ''
			})
		}

		dispatch(buttonActions.clear())
	}, [btnActions.action, btnActions.active, dispatch, selectedCustomers])

	/* 초기 데이터 불러오기 */
	useEffect(() => {
		fetchBuyData()
		fetchCusData()
	}, [])

	useEffect(() => {
		if(searchData.cus_nm.length === 0) {
			setApplyData({ ...applyData, cus_nm: '' })
		}
	}, [searchData.cus_nm.length])

	/* 입출고 데이터 불러오기 */
	const fetchBuyData = () => {
		client.get((buySellMode === 1 ? '/api/stock-manage/get-buy-data' : '/api/stock-manage/get-sell-data'))
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsBuyData } = res.data.payload
					setBuyData(rowsBuyData)
					setBeforeBuyData(rowsBuyData.map((bd: IBuyData) => ({
						index: bd.index,
						cus: bd.cus,
						item: bd.item,
						ymd: bd.ymd
					})))
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}
	/* 거래처 데이터 가져오기 */
	const fetchCusData = () => {
		client.get('/api/stock-manage/get-cus-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsCusData } = res.data.payload
					setCusData(rowsCusData)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 날짜, 거래처 검색 항목 수정 */
	const handleChangeOnYMD = (e: React.ChangeEvent<HTMLInputElement>, mode: number) => {
		if(mode === 1) {
			setSearchData({ ...searchData, ymd1: e.target.value.replaceAll("-", "") })
		} else if(mode === 2)
			setSearchData({ ...searchData, ymd2: e.target.value.replaceAll("-", "") })
	}

	/* 거래처 필드 엔터 이벤트 */
	const handleEnterOnCusNm = (e: any) => {
		if(e.key === 'Enter') {
			const foundIndex = cusData.findIndex(cd_elem => cd_elem.cus_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			const filtered = cusData.filter(elem => elem.cus_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			if(foundIndex === -1 || searchData.cus_nm.length === 0 || filtered.length >= 2) {
				setApplyData({ ...searchData, cus_nm: e.target.value })
				setShowCusSearchModal(true)
			} else {
				setSearchData({ ...searchData, cus_nm: filtered[0].cus_nm })
			}
		}
	}

	/* 검색 버튼 입력 */
	const handleClickOnSearchButton = () => {
		const value = cusNmRef.current === null ? '' : cusNmRef.current.value
		const foundIndex = cusData.findIndex(cd_elem => cd_elem.cus_nm.toLowerCase().includes(value.toLowerCase()))
		const filtered = cusData.filter(elem => elem.cus_nm.toLowerCase().includes(value.toLowerCase()))

		if(foundIndex === -1 || searchData.cus_nm.length === 0 || filtered.length >= 2) {
			setShowCusSearchModal(true)
		} else {
			setSearchData({ ...searchData, cus_nm: filtered[0].cus_nm })
		}
	}

	/* 거래처 검색 모달 제출 결과 */
	const handleSubmit = (cus: ICusData) => {
		setSearchData({ ...searchData, cus_nm: cus.cus_nm })
		setApplyData({ ...searchData, cus_nm: cus.cus_nm })
		setShowCusSearchModal(false)
	}

	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post((buySellMode === 1 ? '/api/stock-manage/remove-buy-data-by-cus' : '/api/stock-manage/remove-sell-data-by-cus'),
				{
				deletedRows: selectedCustomers
			})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setSelectedCustomers([])
				setSelectAll(false)
				fetchBuyData()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	return (
		<>
			{/* 검색 메뉴 */}
			<div className="d-flex mt-2 flex-column">
				{/* 날짜 입력창 */}
				<div className="d-flex ms-3 my-1">
					<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">일자</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchData.ymd1)}
								   onChange={e => handleChangeOnYMD(e, 1)}
							/>
						</div>
						<label className="col-auto align-items-center my-1 mx-2">~</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchData.ymd2)}
								   onChange={e => handleChangeOnYMD(e, 2)}
							/>
						</div>
					</div>
				</div>
				{/* 거래처 */}
				<div className="d-flex ms-3 my-1">
					<div className="d-flex flex-grow-1 justify-content-between align-content-center mx-3 py-2 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">거래처</label>
						<div className="input-group input-group-sm">
							<input ref={cusNmRef} type="text" className="form-control" style={{ maxWidth: "235px" }}
								   value={searchData.cus_nm}
								   onChange={e => setSearchData({ ...searchData, cus_nm: e.target.value })}
								   onKeyDown={handleEnterOnCusNm}/>
							<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
						</div>
					</div>

				</div>
			</div>

			{/*그래프*/}
			<div className="d-flex">
				{/* 그래프 1 */}
				<div className="d-flex flex-column col-4 mx-3 mt-2">
					<label className="ms-3 fw-bold">요약</label>
					<table className="table table-sm table-bordered table-hover table-condensed">

						<thead>
						<tr>
							{summaryCol.map((column, index) =>
								<th key={index} style={column.style} scope="column">{column.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<BuyDataContext.Provider value={{
							buyData,
							setBuyData,
							beforeBuyData,
							setBeforeBuyData,
							addModMode,
							setAddModMode,
							showAddModModal,
							setShowAddModModal,
							cusData,
							selectAll
						}}>
							<ClickedSummaryContext.Provider value={{clickedCustomer, setClickedCustomer}}>
								<SelectedCustomersContext.Provider value={{ selectedCustomers, setSelectedCustomers}} >
									<SummaryList data={summaryData} />
								</SelectedCustomersContext.Provider>
								<AddModModal show={showAddModModal} onHide={() => setShowAddModModal(false)} fetchBuyData={fetchBuyData} buySellMode={buySellMode} />
							</ClickedSummaryContext.Provider>
						</BuyDataContext.Provider>
						</tbody>

					</table>
				</div>

				{/*그래프 2*/}
				<div className="d-flex flex-column col-7 mx-3 mt-2">
					<label className="ms-3 fw-bold">상세</label>
					<table className="table table-sm table-bordered table-hover table-condensed">
						<thead>
						<tr>
							{detailCol.map((column, index) =>
								<th key={index} style={column.style} scope="column">{column.name}</th>
							)}
						</tr>
						</thead>
						<tbody>
						<DetailList data={detailData} />
						</tbody>
					</table>
				</div>
			</div>

			<CustomerSearchModal cusData={cusData} show={showCusSearchModal} onHide={() => setShowCusSearchModal(false)}
								 onSubmit={handleSubmit} initialSearchData={applyData.cus_nm}/>


			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
				<Modal.Header closeButton>
					<Modal.Title>
						{((buySellMode === 1) ? "입고" : "출고") + "정보 삭제"}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 {(buySellMode === 1) ? "입고" : "출고"} 정보를 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>
			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
		</>
	)
}

export default GoodsBuying