import React, {useState} from 'react'
import {CustomerListProps, CustomerRowProps} from '../../../interfaces/standard-info/i-customer'
import UpdateModal from "./modals/update-modal";


const CustomerList = ({ data, useCategory, onUpdate }: CustomerListProps) => {
	return (
		<>
			{data.map(customerRow => <CustomerRow key={`${customerRow.cus_usr}`}
												  customerRow={customerRow}
												  useCategory={useCategory}
												  onUpdate={onUpdate}/>)}
		</>
	)
}

const convertSaupNoReadable = (serial: string) => {
	return serial.length === 0 ? '' : serial.slice(0, 3) + '-' + serial.slice(3, 5) + '-' + serial.slice(5)
}

const CustomerRow = ({ customerRow, useCategory, onUpdate }: CustomerRowProps) => {

	let timeoutID
	const [ showUpdateModal, setShowUpdateModal ] = useState(false)
	const [ clickCount, setClickCount ] = useState(0)

	const handleClickOnRow = () => {
		setClickCount(clickCount + 1)
		timeoutID = setTimeout(() => setClickCount(0), 3000)

		if(clickCount === 1) {
			setClickCount(0)
			setShowUpdateModal(true)
			clearTimeout(timeoutID)
		}
	}

	return (
		<>
			<tr onClick={handleClickOnRow}>
				<th scope="row" style={{ textAlign: "center" }}>{customerRow.cus_usr}</th>
				<td>{customerRow.cus_nm_o}</td>
				<td>{customerRow.cus_nm}</td>
				<td style={{ textAlign: "center" }}>{convertSaupNoReadable(customerRow.saup_no)}</td>
				<td style={{ textAlign: "center" }}>{customerRow.owner}</td>
				<td style={{ textAlign: "center" }}>{customerRow.up}</td>
				<td style={{ textAlign: "center" }}>{customerRow.jong}</td>
				<td style={{ textAlign: "center" }}>{customerRow.tel}</td>
				<td style={{ textAlign: "center" }}>{customerRow.fax}</td>
				<td style={{ textAlign: "center" }}>{customerRow.zip}</td>
				<td>{customerRow.addr}</td>
				<td style={{ textAlign: "center" }}>{customerRow.tax_email}</td>
				<td style={{ textAlign: "center" }}>{customerRow.bank_owner}</td>
				<td style={{ textAlign: "center" }}>{customerRow.bigo}</td>
				<td style={{ textAlign: "center" }}>{customerRow.use_gbn === '1' ? '사용' : '미사용'}</td>
			</tr>

			<UpdateModal row={customerRow}
						 show={showUpdateModal}
						 onHide={() => setShowUpdateModal(false)}
						 onUpdate={onUpdate}
						 useCategory={useCategory} />
		</>
	)
}

export default CustomerList