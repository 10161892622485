import React, {useState, useEffect, useMemo} from 'react'
import {convertDateWithHyphen, convertFloat, convertReadable, convertString} from "../../../../lib/converting-func";
import {getDate} from "../../../../lib/util-func";
import {IColumn} from "../../../interfaces/common";
import {IMatPay, IMatPayStk} from "../../../interfaces/stock-manage/i-mat-pay";
import client from "../../../../axios";
import InfoModal from "../../../common-modals/info-modal";
import {MatPayContext} from "./mat-pay-context";
import MatPayList from "./mat-pay-list";
import * as buttonActions from '../../../../redux/actions/button'
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {Modal} from "react-bootstrap";
import PrintedTables from "./printed-tables";

const MatPay = () => {

	/* 검색 데이터 */
	const [ searchData, setSearchData ] = useState({ ymd: getDate() })
	/* 재고 데이터(일반 데이터와 1대 1 대응됨) */
	const [ matPayStk, setMatPayStk ] = useState<IMatPayStk[]>([])
	/* 데이터 */
	const [ matPay, setMatPay ] = useState<IMatPay[]>([])
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 재고조정 여부 */
	const [ adj, setAdj ] = useState(false)
	/* 저장 모달 show 여부 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)
	/* 수행 성공 모달 변수 */
	const [ showSuccessModal, setShowSuccessModal ] = useState(false)
	const [ successMessage, setSuccessMessage ] = useState('')
	const [ successTitle, setSuccessTitle ] = useState('')

	/* 버튼 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 칼럼 */
	const columns = useMemo(() => [
		{ name: '자재이름', style: { textAlign: 'center', width: '300px', minWidth: '200px' } },
		{ name: '단위', style: { textAlign: 'center', width: '60px', minWidth: '50px' } },
		{ name: '전일재고', style: { textAlign: 'center', width: '150px', minWidth: '130px' } },
		{ name: '금일입고량', style: { textAlign: 'center', width: '150px', minWidth: '130px' } },
		{ name: '폐기', style: { textAlign: 'center', width: '60px', minWidth: '60px' } },
		{ name: '금일투입량', style: { textAlign: 'center', width: '150px', minWidth: '130px' } },
		{ name: '금일재고', style: { textAlign: 'center', width: '150px', minWidth: '130px' } },
		{ name: '', style: {} }
	] as IColumn[], [])

	/* 원료수불부 불러오기 */
	const fetchMatPay = (ymd: string) => {
		client.post('/api/stock-manage/get-mat-pay', {
			payload: {ymd}
		})
			.then(res => {
				if (res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const rowsMatPayStk = [] as IMatPayStk[]
					const { rowsMatPay } = res.data.payload
					/* 불러온 데이터로 데이터 설정 */
					setMatPay(rowsMatPay.map((element: IMatPay) => {
						rowsMatPayStk.push({
							idx: element.idx,
							stk_bs: convertString(element.stk_bs),
							stk_now: convertString(element.stk_now),
							item: element.item
						})

						return {
							...element,
							stk_bs: convertString(element.stk_bs),
							stk_in: convertString(element.stk_in),
							thrown: convertString(element.thrown),
							stk_out: convertString(element.stk_out),
							stk_now: convertString(element.stk_now)
						}
					}))
					setMatPayStk(rowsMatPayStk)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 수불일자 수정 */
	const handleChangeOnYMD = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchData({...searchData, ymd: e.target.value.replaceAll("-", "")})
	}

	/* 저장 수행 */
	const handleSaveData = async () => {
		setShowSaveModal(false)
		await updateMatPay()
	}

	/* 재고조정 반영 */
	const updateMatPay = async () => {
		/* 이전과 비교해서 다른 것만 전송 */
		const beforeMatPay = [] as IMatPayStk[], afterMatPay = [] as IMatPayStk[]
		matPayStk.forEach((value, index) => {
			if(value.stk_bs !== matPay[index].stk_bs || value.stk_now !== matPay[index].stk_now) {
				beforeMatPay.push(value)
				afterMatPay.push({
					idx: matPay[index].idx,
					stk_bs: matPay[index].stk_bs,
					stk_now: matPay[index].stk_now,
					item: matPay[index].item
				})
			}
		})

		try {
			const response = await client.post('/api/stock-manage/update-mat-pay', {
				payload: {
					ymd: searchData.ymd,
					beforeMatPay,
					afterMatPay
				}
			})

			if(response.data.result === 'failed') {
				setShowFailedModal(true)
				setFailedTitle('재고조정 저장 실패')
				setFailedMessage('서버에 문제가 발생하였습니다.')
			} else {
				setShowSuccessModal(true)
				setSuccessTitle('재고조정 저장 성공')
				setSuccessMessage('성공적으로 재고를 조정했습니다.')
				fetchMatPay(searchData.ymd)
			}
		} catch (e: any) {
			setShowFailedModal(true)
			setFailedTitle('재고조정 저장 실패')
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
		}
	}

	/* 마운트 시 데이터 불러오기 */
	useEffect(() => {
		fetchMatPay(searchData.ymd)
	}, [])

	/* 검색 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'search') {
			fetchMatPay(searchData.ymd)
			dispatch(buttonActions.clear())
		} else if (btnActions.active && btnActions.action === 'save') {
			setShowSaveModal(true)
			dispatch(buttonActions.clear())
		}
	}, [btnActions.action, btnActions.active, dispatch])

	return (
		<>
			<div className="d-flex mt-2">
				<div className="d-flex ms-3 my-1 flex-column" style={{ width: '100%' }}>
					{/* 테이블 위 조건들 */}
					<div className="d-flex align-content-center justify-content-between mx-3 my-2 py-lg-0">
						{/* 날짜 */}
						<div className="d-flex">
							<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">일자</label>
							<div className="input-group input-group-sm">
								<input type="date" className="form-control"
									   value={convertDateWithHyphen(searchData.ymd)}
									   onChange={handleChangeOnYMD}
								/>
							</div>
						</div>
						{/* 재고조정 버튼 */}
						<div className="d-flex">
							<button className={`btn btn-sm ${adj ? 'btn-primary' : 'btn-secondary'} me-2`}
									onClick={() => setAdj(!adj)}
							>
								재고조정 &nbsp;
								{
									adj ?
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
											 fill="currentColor" className="bi bi-toggle-on" viewBox="0 0 16 16">
											<path
												d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
										</svg>
										:
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
											 fill="currentColor" className="bi bi-toggle-off" viewBox="0 0 16 16">
											<path
												d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
										</svg>
								}
							</button>
						</div>
					</div>

					{/* 테이블 */}
					<div className="my-3 scrollbar table-wrapper">
						<table className="table table-sm table-hover table-bordered table-condensed my-2">
							<thead className="sticky-head">
							<tr>
								{columns.map((column, index) =>
									<th key={index} style={column.style}>{column.name}</th>
								)}
							</tr>
							</thead>

							<tbody>
							<MatPayContext.Provider value={{ matPay, setMatPay, adj }}>
								<MatPayList ymd={searchData.ymd} data={matPay} />
							</MatPayContext.Provider>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center col-12">
				<PrintedTables matPay={matPay} ymd={searchData.ymd} />
			</div>

			{/* 정보 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => setShowSaveModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
				<Modal.Header closeButton>
					<Modal.Title>
						재고조정 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					입력하신 내용을 토대로 재고를 조정하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowSaveModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>



			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
			<InfoModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} title={successTitle}
					   message={successMessage} onButtonClick={() => setShowSuccessModal(false)} />
		</>
	)
}

export default MatPay