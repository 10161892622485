
export const addHyphenOnSaupNo = (serial: string) => {
	return serial.length === 0 ? '' : serial.slice(0, 3) + '-' + serial.slice(3, 5) + '-' + serial.slice(5)
}

export const convertSaupNoReadable = (value: string) => {
	const pure_saup_no = value.split('-').reduce((prev, cur) => prev + cur, '')

	if(value !== '') {
		if(value.charAt(value.length - 1) === '-') {
			value = value.slice(0, value.length - 1)
		}

		if(pure_saup_no.length === 4) {
			value = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3)
		} else if(pure_saup_no.length === 6) {
			value = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3, 5) + '-' + pure_saup_no.slice(5)
		}
	}

	return value
}

export const convertPhoneNoReadable = (value: string) => {
	const pureValue = value.split('-').reduce((prev, cur) => prev + cur, '')

	if(value !== '') {
		if(value.charAt(value.length - 1) === '-') {
			value = value.slice(0, value.length - 1)
		}

		if(pureValue.charAt(0) === '0') {            /* 0으로 시작할 때 */
			if(pureValue.charAt(1) === '2') {        /* 02일 때 */
				if(pureValue.length === 3) {
					value = pureValue.slice(0, 2) + '-' + pureValue.slice(2)
				} else if (pureValue.length === 7 || pureValue.length === 8) {
					value = pureValue.slice(0, 2) + '-' + pureValue.slice(2, 6) + '-' + pureValue.slice(6)
				} else if (pureValue.length === 9) {
					value = pureValue.slice(0,2) + '-' + pureValue.slice(2, 5) + '-' + pureValue.slice(5)
				}
			} else if(pureValue.charAt(1) !== '9') { /* 그 외(지역번호) */
				if(pureValue.length === 4) {
					value = pureValue.slice(0, 3) + '-' + pureValue.slice(3)
				} else if (pureValue.length === 8 || pureValue.length === 9) {
					value = pureValue.slice(0, 3) + '-' + pureValue.slice(3, 7) + '-' + pureValue.slice(7)
				} else if (pureValue.length === 10) {
					value = pureValue.slice(0, 3) + '-' + pureValue.slice(3, 6) + '-' + pureValue.slice(6)
				} else if (pureValue.length === 11 && pureValue.charAt(1) === '1') {
					value = pureValue.slice(0, 3) + '-' + pureValue.slice(3, 7) + '-' + pureValue.slice(7)
				}
			}
		} else if(value.charAt(0) === '1') {        /* 1으로 시작할 때 */
			if(pureValue.length === 5)
				value = pureValue.slice(0, 4) + '-' + pureValue.slice(4)
		}
	}

	return value
}

export const getDateWithHyphen = () => {
	const currentTime = new Date()
	return currentTime.getFullYear().toString() + '-' +
		('0' + (1 + currentTime.getMonth())).slice(-2) + '-' +
		('0' + currentTime.getDate()).slice(-2)
}

export const convertDateWithHyphen = (date: string) => {
	return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6)
}

/* string을 float로 바꾸기 */
export const convertFloat = (val: string) => {
	return isNaN(parseFloat(val)) ? 0 : parseFloat(val)
}

/* string에 콤마 넣지 않고 변화시키기 */
export const convertString = (val: string) => {
	return isNaN(parseFloat(val)) ? val : parseFloat(val).toString()
}

/* string에 콤마 넣기 */
export const convertReadable = (val: string, fractionDigits: number) => {
	return isNaN(parseFloat(val)) ? val : parseFloat(val).toLocaleString('en-US', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })
}