import React from 'react'
import {IMatPay} from "../../../interfaces/stock-manage/i-mat-pay";

export interface IMatPayContext {
	matPay: IMatPay[]
	setMatPay: (matPay: IMatPay[]) => void
	adj: boolean
}

export const MatPayContext = React.createContext<IMatPayContext>({
	matPay: [] as IMatPay[],
	setMatPay: () => {},
	adj: false
})