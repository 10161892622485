import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
	HiddenTableListProps,
	HiddenTableProps,
	HiddenTableRowProps,
	IMatPay,
	PrintTablesProps
} from "../../../interfaces/stock-manage/i-mat-pay";
import {useReactToPrint} from "react-to-print";
import client from "../../../../axios";
import * as timerActions from "../../../../redux/actions/timer";
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import * as buttonActions from "../../../../redux/actions/button";
import * as decActions from "../../../../redux/actions/dec"
import {IColumn} from "../../../interfaces/common";
import {convertDateWithHyphen, convertReadable} from "../../../../lib/converting-func";
import InfoModal from "../../../common-modals/info-modal";

const maxRow = 28

const PrintedTables = ({ matPay, ymd }: PrintTablesProps) => {
	const divRef = useRef<HTMLDivElement>(null)

	/* 회사명 */
	const [ corpNm, setCorpNm ] = useState('')
	/* 내비게이터 */
	const navigate = useNavigate()
	/* 버튼 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	const handlePrint = useReactToPrint({
		content: () => divRef.current,
		onAfterPrint: async () => {
			try {
				const response = await client.get('/api/auth/extend-login')
				if(response.data.result === 'success') {
					dispatch(timerActions.reset())
				} else {
					if(response.data.result === 'expired') {
						navigate('/login')
					} else {
						setFailedTitle('인쇄 실패')
						setFailedMessage('서버에 문제가 발생하였습니다.')
						setShowFailedModal(true)
					}
				}
			} catch(e: any) {
				setFailedTitle('인쇄 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				setShowFailedModal(true)
			}
		}
	})

	/* 회사명 맞추기 */
	useEffect(() => {
		client.get('/api/auth/get-user-profile')
			.then(res => setCorpNm(res.data.corp_nm))
	}, [])

	/* 인쇄 상태면 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'print') {
			dispatch(buttonActions.clear())
			handlePrint()
		}
	}, [btnActions.active, btnActions.action, dispatch])

	/* maxRow의 값에 따라 데이터 나누기 */
	const splittedData = useMemo(() => {
		const retData = [] as IMatPay[][]
		const bp = [] as number[]
		bp.push(0)

		for(let i = 1; i <= matPay.length; i++)
			if(i % maxRow === 0 || i === matPay.length)
				bp.push(i)

		for(let i = 0; i < bp.length - 1; i++)
			retData.push(matPay.slice(bp[i], bp[i + 1]))

		return retData
	}, [matPay])

	return (
		<>
			<div className="d-flex" style={{ height: '0', width: '0', overflow: 'hidden' }}>
				<div ref={divRef}>
					{splittedData.map((element, index) =>
						<HiddenTable idx={index} key={index} data={matPay} ymd={ymd} corpNm={corpNm} tot={splittedData.length}/>
					)}
				</div>
			</div>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
		</>
	)
}

const HiddenTable = ({idx, data, ymd, corpNm, tot}: HiddenTableProps) => {
	const week = ['일', '월', '화', '수', '목', '금', '토']

	const columns = useMemo(() => [
		{ name: '자재이름', style: { textAlign: 'center', width: '250px' } },
		{ name: '단위', style: { textAlign: 'center', width: '60px' } },
		{ name: '전일재고', style: { textAlign: 'center', width: '150px' } },
		{ name: '금일입고량', style: { textAlign: 'center', width: '150px' } },
		{ name: '폐기', style: { textAlign: 'center', width: '60px' } },
		{ name: '금일투입량', style: { textAlign: 'center', width: '150px' } },
		{ name: '금일재고', style: { textAlign: 'center', width: '150px' } }
	] as IColumn[], [])

	return (
		<div className="d-flex justify-content-center" style={{ pageBreakBefore: 'always', marginTop: '60px' }}>
			<div className="d-flex flex-column justify-content-center mx-5">
				<label className="text-secondary" style={{ fontSize: '12px' }}>페이지 {idx + 1}/{tot}</label>
				<h1 style={{ textAlign: 'center', textDecoration: 'underline', textUnderlinePosition: 'under' }}>원료수불부</h1>
				<div className="d-flex justify-content-between my-2">
					<label className="ms-4">{corpNm}</label>
					<label className="me-4">생산날짜&emsp;{convertDateWithHyphen(ymd)}({week[new Date(convertDateWithHyphen(ymd)).getDay()]})</label>
				</div>
				<table className="table border-dark table-sm table-condensed">
					<thead>
					<tr className="border-top border-dark border-start-0 border-end-0 border-2">
						{columns.map((column, index) =>
							<th key={index} style={column.style} scope="column">{column.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<HiddenTableList idx={idx} data={data} />
					</tbody>
				</table>
			</div>
		</div>
	)
}

const HiddenTableList = ({ data, idx }: HiddenTableListProps) => {

	const { dec } = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((row, index) =>
				<HiddenTableRow key={`${idx}-${index}`} row={row} isLast={index === data.length - 1} dec={dec} />
			)}
		</>
	)
}

const HiddenTableRow = ({ row, isLast, dec }: HiddenTableRowProps) => {
	return (
		<tr>
			<td
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{row.item_nm}
			</td>
			<td style={{ textAlign: 'center' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{row.unit_nm}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{convertReadable(row.stk_bs, dec)}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{convertReadable(row.stk_in, dec)}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{convertReadable(row.thrown, dec)}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{convertReadable(row.stk_out, dec)}
			</td>
			<td style={{ textAlign: 'end' }}
				className={`${isLast ? 'border-0 border-bottom border-dark border-2' : ''}`}>
				{convertReadable(row.stk_now, dec)}
			</td>
		</tr>
	)
}

export default PrintedTables