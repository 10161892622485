import {useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import InfoModal from "../../common-modals/info-modal";
import Terms from "./terms";
import PersonalInfo from "./personal-info";

const CheckTerms = () => {

	/* 체크박스 레퍼런스 */
	const checkboxRefs = [
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null)
	]

	/* 모두 동의하라는 모달 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	/* 네비게이트 */
	const navigate = useNavigate()

	/* 다음단계 넘어가기 */
	const handleProceed = () => {
		if(checkboxRefs[0].current !== null && checkboxRefs[1].current !== null) {
			if(checkboxRefs[0].current.checked && checkboxRefs[1].current.checked) {
				navigate('/register/input-info')
			} else {
				setFailedTitle('약관 동의')
				setFailedMessage('모든 약관에 동의해주세요.')
				setShowFailedModal(true)
			}
		}
	}

	return (
		<div className="container d-flex justify-content-center mt-5">
			<div className="card col-sm-11 col-lg-10 col-xl-9 col-xxl-8 border-0">
				<div className="card-body">
					<div className="justify-content-center">
						<h3>회원가입</h3>
						<hr/>

						<div className="mb-5">
							<h5 className="fw-bold">회원가입약관</h5>
							<div className="scrollbar border px-3 py-1 mb-3 border-4" style={{ height: '400px', overflow: 'overlay' }}>
								<Terms />
							</div>
							<div className="d-flex">
								<input ref={checkboxRefs[0]} id="acc-term" type="checkbox" className="form-check-input "/>
								<label htmlFor="acc-term" className="col-auto ms-2">[필수] 회원가입약관에 동의합니다.</label>
							</div>
							<hr/>
						</div>

						<div className="mb-5">
							<h5 className="fw-bold">개인정보취급방침</h5>
							<div className="scrollbar border px-3 py-1 mb-3 border-4" style={{ height: '400px', overflow: 'overlay' }} >
								<PersonalInfo />
							</div>
							<div className="d-flex">
								<input ref={checkboxRefs[1]} id="personal-info" type="checkbox" className="form-check-input "/>
								<label htmlFor="personal-info" className="col-auto ms-2">[필수] 개인정보취급방침에 동의합니다.</label>
							</div>
							<hr/>
						</div>

						<div className="d-flex justify-content-center">
							<Button variant="secondary" className="mx-1" onClick={() => navigate('/login')} >
								취소(돌아가기)
							</Button>
							<Button variant="primary" className="mx-1" onClick={handleProceed} >
								확인(다음단계)
							</Button>
						</div>
					</div>
				</div>
			</div>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)}
					   title={failedTitle} message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
		</div>
	)
}

export default CheckTerms