import React from 'react'
import {
	IBeforeProdPerf,
	IClickedProdPerf,
	IProdPerf,
	ISelectedProdPerf
} from "../../../interfaces/production-manage/i-prod-perf";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";

/* 선택된 생산실적 데이터를 담는 컨텍스트 */
export interface ISelectedProdPerfContext {
	clickedProdPerf: IClickedProdPerf
	setClickedProdPerf: (clickedProdPerf: IClickedProdPerf) => void
	selectedProdPerf: ISelectedProdPerf[]
	setSelectedProdPerf: (selectedProdPerf: ISelectedProdPerf[]) => void
	selectAll: boolean
}

export const SelectedProdPerfContext = React.createContext<ISelectedProdPerfContext>({
	clickedProdPerf: {} as IClickedProdPerf,
	setClickedProdPerf: () => {},
	selectedProdPerf: [] as ISelectedProdPerf[],
	setSelectedProdPerf: () => {},
	selectAll: false
})

/* 추가/변경 정보를 담는 컨텍스트 */
export interface IAddModModalContext {
	setAddModMode: (addModMode: number) => void
	setShowAddModModal: (showAddModModal: boolean) => void
}

export const AddModModalContext = React.createContext<IAddModModalContext>({
	setAddModMode: () => {},
	setShowAddModModal: () => {}
})

/* 거래처 정보를 담아주는 컨텍스트 */
export interface IOriginalProdPerfContext {
	prodPerf: IProdPerf[]
	beforeProdPerf: IBeforeProdPerf[]
	addModMode: number
	productData: IProductData[]
}
export const OriginalProdPerfContext = React.createContext<IOriginalProdPerfContext>({
	prodPerf: [] as IProdPerf[],
	beforeProdPerf: [] as IBeforeProdPerf[],
	addModMode: 0,
	productData: [] as IProductData[]
})