import React, {useEffect, useRef, useState} from 'react'
import client from "../../../axios";
import InfoModal from "../../common-modals/info-modal";
import infoModal from "../../common-modals/info-modal";

const PasswordChange = () => {



	/* 변경 성공 또는 실패 시 띄울 모달의 속성들 */
	const [ showInfoModal, setShowInfoModal ] = useState(false)
	const [ infoModalContent, setInfoModalContent ] = useState('')
	const [ infoModalTitle, setInfoModalTitle ] = useState('')

	/* ref 생성 */
	const inputRefs = [
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null)
	]
	const btnRef = useRef<HTMLButtonElement>(null)

	/* 모두 채워져있는지 검사 */
	const isEverythingFilled = () => {
		let result = true

		inputRefs.forEach(element => {
			if(element.current === null || element.current.value === '') {
				result = false
			}
		})

		return result
	}

	/* 엔터 입력 시 이벤트 */
	const handleEnterOnPwdOld = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter' && inputRefs[1].current !== null) {
			inputRefs[1].current.focus()
		}
	}
	const handleEnterOnPwdNew = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter' && inputRefs[2].current !== null) {
			inputRefs[2].current.focus()
		}
	}
	const handleEnterOnPwdAgn = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter' && btnRef.current !== null) {
			btnRef.current.click()
		}
	}

	/* password의 메뉴 */
	const passwordSettings = [
		{ name: "기존 비밀번호", handler: handleEnterOnPwdOld },
		{ name: "새 비밀번호", handler: handleEnterOnPwdNew },
		{ name: "새 비밀번호 확인", handler: handleEnterOnPwdAgn },
	]

	/* 마운트 시 기존 비밀번호 창에 포커싱 */
	useEffect(() => {
		if(inputRefs[0].current !== null)
			inputRefs[0].current.focus()
	}, [])

	/* 변경된 비밀번호 정보를 서버로 전달 */
	const handleChange = async () => {
		if(isEverythingFilled()) {
			try {
				const response = await client.post('/api/auth/change-password', {
					payload: {
						pwd_old: inputRefs[0].current?.value,
						pwd_new: inputRefs[1].current?.value,
						pwd_agn: inputRefs[2].current?.value
					}
				})

				if(response.data.result === 'failed') {
					setShowInfoModal(true)
					setInfoModalContent(response.data.reason)
					setInfoModalTitle('비밀번호 변경 오류')
				} else {
					setShowInfoModal(true)
					setInfoModalContent('성공적으로 비밀번호를 변경하였습니다.')
					setInfoModalTitle('비밀번호 변경 성공')
				}
			} catch (e: any) {
				setShowInfoModal(true)
				setInfoModalContent('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				setInfoModalTitle('비밀번호 변경 오류')
			}
		} else {
			setShowInfoModal(true)
			setInfoModalContent('모든 칸을 체워넣으세요.')
			setInfoModalTitle('비밀번호 변경 오류')
		}
	}

	return (
		<>
			<div className="mt-4 d-flex justify-content-sm-start justify-content-center" aria-label="password-change-box">
				<div id="password-container" className="d-inline border px-3" aria-label="password-field">
					{passwordSettings.map((content, index) =>
						<div key={index}  className="input-group input-group-sm my-4">
							<span className="input-group-text password-addon">{content.name}</span>
							<input id={`hello-${index}`} type="password" ref={inputRefs[index]} className="form-control" placeholder={index === 0 ? "" : "5자 이상 10자 이하"}
								   onKeyDown={content.handler}
							/>
						</div>
					)}

					<div className="d-flex justify-content-end my-4">
						<button ref={btnRef} className="btn btn-primary btn-sm px-3" onClick={handleChange}>변경</button>
					</div>

				</div>
			</div>

			<InfoModal show={showInfoModal} onHide={() => setShowInfoModal(false)} title={infoModalTitle}
					   message={infoModalContent} onButtonClick={() => setShowInfoModal(false)} />
		</>
	)
}

export default PasswordChange