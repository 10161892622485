/* 행의 집합 */
import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {IPrdStandardCode, IProduct} from '../../../interfaces/standard-info/i-product'
import {INameValue} from '../../../interfaces/common'
import CodeModal from './modals/code-modal'

import './styles.css'


interface ProductRowProps {
	productRow: IProduct;
	accCategory: INameValue[];
	useCategory: INameValue[];
	codeData: IPrdStandardCode[];
	/* 행 한개의 전체 데이터와 고유 품목 번호를 토대로 수정 수행 */
	handleModification: (row: IProduct, item: string) => void;
}

interface ProductListProps {
	data: IProduct[];
	accCategory: INameValue[];
	useCategory: INameValue[];
	codeData: IPrdStandardCode[];
	handleModification: (row: IProduct, code: string) => void;
}

interface IRow extends IProduct {
	unit_nm: string
}

/* Row 사용 */
export const ProductList = ({ data, accCategory, useCategory, codeData, handleModification }: ProductListProps) => {
	return (
		<>
			{data.map(productRow => <ProductRow key={`${productRow.item_usr}`}
												productRow={productRow}
												accCategory={accCategory}
												useCategory={useCategory}
												codeData={codeData}
												handleModification={handleModification}
												/>
			)}
		</>
	)
}

/* 행 하나를 나타내는 노드, 클릭할 때마다 데이터 수정 창 보이기 */
const ProductRow = ({ productRow, accCategory, useCategory, codeData, handleModification }: ProductRowProps) => {

	/* 더블클릭 수행 시 타임아웃 클리어 하기 위한 변수 */
	let timeoutID
	/*  */
	const [ showModModal, setShowModModal ] = useState(false)
	const [ clickCount, setClickCount ] = useState(0)

	const handleClickOnRow = () => {
		setClickCount(clickCount + 1)
		timeoutID = setTimeout(() => setClickCount(0), 3000)

		if(clickCount === 1) {
			setClickCount(0)
			setShowModModal(true)
			clearTimeout(timeoutID)
		}
	}


	/* 모달 보이기 */
	const ModModal = () => {

		/* 수정할 행의 "레퍼런스" */
		const [ modRow, setModRow ] = useState<IRow>({ ...productRow, unit_nm: codeData.find(element => element.sml === productRow.unit)?.sml_nm || '' })
		const [ errorOnInput, setErrorOnInput ] = useState({
			item_nm: false,
			unit: false
		})
		const [ errorMsg, setErrorMsg ] = useState("")
		const [ showErrorMsg, setShowErrorMsg ] = useState(false)
		/* 기준코드(단위) 찾을 때 사용 데이터 */
		const [ showCodeSearch, setShowCodeSearch ] = useState(false)
		/* 변경 사항 저장 */
		const [ showSaveModal, setShowSaveModal ] = useState(false)
		/* 변경되었는지 확인 */
		const [ isChanged, setIsChanged ] = useState(false)


		useEffect(() => {
			setTimeout(() => document.getElementById('item_nm')?.focus(), 200)
		}, [])

		/* 엔터 입력 시 항목 이동 */
		const handleEnter = (e: any) => {
			if(e.key === 'Enter') {
				/* form 레퍼런스 획득 */
				const form = e.target.form
				/* 현재 선택한 input/select의 index 얻기 */
				const index = Array.prototype.indexOf.call(form, e.target)

				if(index !== form.elements.length - 1) {
					if(form.elements[index].id === 'acc_gbn') {
						form.elements[index + 2].focus()
					} else {
						if(form.elements[index].id === 'unit_nm' && modRow.unit_nm === '') {
							setShowCodeSearch(true)
						}

						form.elements[index + 1].focus()
					}
				}

				e.preventDefault()
			}
		}

		/* 입력란에 쓰여있는 것을 토대로 단위 찾기 */
		const findUnit = (e: any) => {
			const val = e.target.value.toLowerCase() as string
			/* 기준코드 테이블에서 불러온 값을 토대로 찾는다. */
			let index = codeData.findIndex(element => element.sml_nm.toLowerCase() === val)
			if (index === -1) index = codeData.findIndex(element => element.sml_nm.toLowerCase().includes(val))

			setModRow({ ...modRow, unit: (index === -1 || val === '') ? '' : codeData[index].sml, unit_nm: (index === -1 || val === '') ? '' : codeData[index].sml_nm })
			setErrorOnInput({ ...errorOnInput, unit: (index === -1 || val === '')})
		}

		/*  */
		const handleChangeOnItemNm = (e: any) => {
			setIsChanged(true)
			setErrorOnInput({ ...errorOnInput, item_nm: e.target.value === '' })
			setModRow({ ...modRow, item_nm: e.target.value })
		}

		const handleChangeOnUnitNm = (e: any) => {
			setIsChanged(true)
			setModRow({ ...modRow, unit: '', unit_nm: e.target.value })
			setErrorOnInput({ ...errorOnInput, unit: true })
		}

		const handleClickOnButton = () => {
			if(errorOnInput.item_nm) {
				setErrorMsg('품목을 입력해주세요.')
				setShowErrorMsg(true)
			} else if(errorOnInput.unit) {
				setErrorMsg('정확한 단위를 입력해주세요.')
				setShowErrorMsg(true)
			} else {
				setShowErrorMsg(false)
				handleModification(modRow, modRow.item)
				setShowModModal(false)
			}
		}

		const handleHideCodeModal = () => {
			setShowCodeSearch(false)
		}

		const handleSubmit = (sml: string, sml_nm: string) => {
			setModRow({ ...modRow, unit: sml, unit_nm: sml_nm })
			setErrorOnInput({ ...errorOnInput, unit: false })
		}

		/* 변경 사항 저장 */
		const handleSaveData = () => {
			handleClickOnButton()
			setShowSaveModal(false)
		}

		return (
			<Modal show={showModModal} onHide={() => {
				if(isChanged)
					setShowSaveModal(true)
				else
					setShowModModal(false)
			}} centered>
				<Modal.Header closeButton>
					<Modal.Title>품목 수정</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="card-body">
						<form onSubmit={e => e.preventDefault()}>
							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">코드</span>
								<input id="code" type="text" className="form-control text-center" readOnly disabled value={modRow.item_usr} style={{ maxWidth: "80px" }} />
							</div>

							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">품명</span>
								<input id="item_nm" type="text" className="form-control" value={modRow.item_nm} onKeyDown={handleEnter}
									   onChange={handleChangeOnItemNm} autoFocus />
							</div>

							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">규격</span>
								<input id="spec" type="text" className="form-control" value={modRow.spec} onKeyDown={handleEnter}
									   onChange={e => {
										   setModRow({...modRow, spec: e.target.value})
										   setIsChanged(true)
									   }} autoFocus />
							</div>

							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">계정구분</span>
								<select id="acc_gbn" defaultValue={modRow.acc_gbn} className="form-select form-select-sm"
										style={{ maxWidth: '150px' }} onKeyDown={handleEnter} onChange={e => {
									setModRow({...modRow, acc_gbn: e.target.value})
									setIsChanged(true)
								}}>
									{accCategory.slice(1).map((category, index) =>
										<option key={index} value={category.value}>{`${index + 1}. ${category.name}`}</option>
									)}
								</select>
							</div>

							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">단위</span>
								<input id="unit" type="text" className="form-control text-center" readOnly disabled
									   value={modRow.unit} style={{ maxWidth: "40px" }} />
								<input id="unit_nm" type="text" className="form-control" onKeyDown={handleEnter} onBlur={findUnit}
									   value={modRow.unit_nm} onChange={handleChangeOnUnitNm} style={{ maxWidth: '110px' }}/>
							</div>

							<div className="input-group input-group-sm my-3">
								<span className="input-group-text addonSize">사용여부</span>
								<select id="use_gbn" defaultValue={modRow.use_gbn} className="form-select form-select-sm"
										style={{ maxWidth: '150px' }} onKeyDown={handleEnter} onChange={e => {
									setModRow({...modRow, use_gbn: e.target.value})
									setIsChanged(true)
								}}>
									{useCategory.slice(1).map((category, index) =>
										<option key={index} value={category.value}>{`${index + 1}. ${category.name}`}</option>
									)}
								</select>
							</div>


						</form>
					</div>
					<div className="d-flex justify-content-end">
						<label className="me-3 text-danger" hidden={!showErrorMsg}>{errorMsg}</label>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModModal(false)}>
						닫기
					</Button>
					<Button variant="primary" onClick={handleClickOnButton}>
						수정
					</Button>
				</Modal.Footer>

				<CodeModal show={showCodeSearch} codeData={codeData} onHide={handleHideCodeModal} onSubmit={handleSubmit} />

				{/* 변경 내용 저장 모달 */}
				<Modal show={showSaveModal} onHide={() => {
					setShowSaveModal(false)
				}} centered>
					<Modal.Header closeButton>
						<Modal.Title>
							입력 사항 저장
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						입력된 정보를 저장하시겠습니까?
					</Modal.Body>

					<Modal.Footer>
						<button className="btn btn-secondary my-2" onClick={() => {
							setShowSaveModal(false)
							setShowModModal(false)
						}}>취소</button>
						<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
					</Modal.Footer>
				</Modal>
			</Modal>
		)
	}

	return (
		<>
			<tr onClick={handleClickOnRow}>
				<th scope="row" style={{ textAlign: "center" }}>{productRow.item_usr}</th>
				<td>{productRow.item_nm}</td>
				<td>{productRow.spec}</td>
				<td style={{ textAlign: "center" }}>{accCategory.find(element => element.value === productRow.acc_gbn)?.name || ''}</td>
				<td style={{ textAlign: "center" }}>{codeData.find(element => element.sml === productRow.unit)?.sml_nm}</td>
				<td style={{ textAlign: "center" }}>{useCategory.find(element => element.value === productRow.use_gbn)?.name}</td>
				<td/>
			</tr>

			<ModModal />
		</>
	)
}

