import {currentPageTypes, keyVal} from '../actions/current-page'

const initialState = {
    prev_page: 'dashboard',
    current_page: 'dashboard',
    admin_gbn: '2'
}

type currentPageState = typeof initialState

/* reducer */
const currentPage = (state: currentPageState = initialState, action: currentPageTypes): currentPageState => {
    if(keyVal.findIndex(element => action.type === element) === -1)
        return state

    if(action.type === 'currentPage/RESETCHANGE')
        return { ...state, current_page: state.prev_page }

    if (action.type === 'currentPage/SETADMINGBN') {

        return {...state, admin_gbn: action.payload }
    }

    return { ...state, prev_page: state.current_page, current_page: action.payload }
}

export default currentPage