import {
	IStockPayDetail,
	IStockPaySummary,
	StockPayListProps,
	StockPayRowProps
} from "../../../interfaces/stock-manage/i-stock-pay";
import {ClickedItemContext} from "./stock-pay-context";
import React, {useContext} from "react";
import {convertDateWithHyphen, convertReadable} from "../../../../lib/converting-func";
import {useSelector, shallowEqual} from 'react-redux'
import {RootReducer} from "../../../../redux/reducers";

const StockPayList = ({ data }: StockPayListProps) => {

	const clickedItemContext = useContext(ClickedItemContext)
	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((row, index) =>
				<StockPayRow key={index} row={row} ciContext={clickedItemContext} dec={dec} />
			)}
		</>
	)
}

const StockPayRow = ({ row, ciContext, dec }: StockPayRowProps) => {

	const handleClickOnRow = (e: React.MouseEvent<HTMLTableRowElement>) => {
		if(ciContext.shownTable === 1) {
			ciContext.setClickedItem(row.item)
		}

		if(e.detail === 2 && ciContext.shownTable === 1) {
			ciContext.setShownTable(2)
		}
	}

	return (
		ciContext.shownTable === 1
			?
			<tr onClick={handleClickOnRow} style={(row as IStockPaySummary).item === ciContext.clickedItem ? { backgroundColor: '#eeeeee' } : {}}>
				<td style={{ textAlign: 'center' }}>{(row as IStockPaySummary).item_usr}</td>
				<td>{(row as IStockPaySummary).item_nm}</td>
				<td>{(row as IStockPaySummary).spec}</td>
				<td style={{ textAlign: 'center' }}>{(row as IStockPaySummary).unit}</td>
				<td style={{ textAlign: 'end' }}>{convertReadable((row as IStockPaySummary).stk_bs, dec)}</td>
				<td style={{ textAlign: 'end' }}>{convertReadable((row as IStockPaySummary).stk_in, dec)}</td>
				<td style={{ textAlign: 'end' }}>{convertReadable((row as IStockPaySummary).stk_out, dec)}</td>
				<td style={{ textAlign: 'end' }} className={`${parseFloat((row as IStockPaySummary).stk_now) < 0 ? 'text-danger' : ''}`}>
					{convertReadable((row as IStockPaySummary).stk_now, dec)}
				</td>
				<td style={{ textAlign: 'center' }}>{(row as IStockPaySummary).acc_gbn}</td>
				<td />
			</tr>
			:
			<tr onClick={handleClickOnRow}>
				<td style={{ textAlign: 'center' }}>{(row as IStockPayDetail).index === 0 ? (row as IStockPayDetail).item_usr : ''}</td>
				<td>{(row as IStockPayDetail).index === 0 ? (row as IStockPayDetail).item_nm : ''}</td>
				<td>{(row as IStockPayDetail).index === 0 ? (row as IStockPayDetail).spec : ''}</td>
				<td>{(row as IStockPayDetail).index === 0 ? (row as IStockPayDetail).bigo : ''}</td>
				<td style={{ textAlign: 'center' }}>{(row as IStockPayDetail).label === '[이월수량]' ? '-' : convertDateWithHyphen((row as IStockPayDetail).ymd)}</td>
				<td style={{ textAlign: 'center' }}>{(row as IStockPayDetail).label}</td>
				<td style={{ textAlign: 'end' }}>
					{(row as IStockPayDetail).stk_in === '0' || (row as IStockPayDetail).label === '[이월수량]'
						?
						''
						:
						convertReadable((row as IStockPayDetail).stk_in, dec)}
				</td>
				<td style={{ textAlign: 'end' }}>
					{
						(row as IStockPayDetail).stk_out === '0' || (row as IStockPayDetail).label === '[이월수량]'
							?
							''
							:
							convertReadable((row as IStockPayDetail).stk_out, dec)}
				</td>
				<td style={{ textAlign: 'end' }}>
					{(row as IStockPayDetail).etc_in === '0' || (row as IStockPayDetail).label === '[이월수량]'
						?
						''
						:
						convertReadable((row as IStockPayDetail).etc_in, dec)}
				</td>
				<td style={{ textAlign: 'end' }}>
					{(row as IStockPayDetail).etc_out === '0' || (row as IStockPayDetail).label === '[이월수량]'
						?
						''
						:
						convertReadable((row as IStockPayDetail).etc_out, dec)}
				</td>
				<td style={{ textAlign: 'end' }} className={`${parseFloat((row as IStockPayDetail).stk_now) < 0 ? 'text-danger' : ''}`}>
					{(row as IStockPayDetail).stk_now === '0' ? '' : convertReadable((row as IStockPayDetail).stk_now, dec)}
				</td>
				<td />
			</tr>
	)
}

export default StockPayList