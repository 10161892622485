export const DECREASE = 'timer/DECREASE'
export const RESET = 'timer/RESET'

export const decrease = () => ({
	type: DECREASE
})

export const reset = () => ({
	type: RESET
})

export type timerTypes =
	ReturnType<typeof decrease> |
	ReturnType<typeof reset>