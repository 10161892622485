import React, {useMemo, useState, useEffect, useRef} from 'react'
import {IColumn} from '../../../interfaces/common'
import {IBeforeRecipe, IChildProduct, IParentProduct, IRecipe} from '../../../interfaces/production-manage/i-add-recipe'
import client from "../../../../axios";
import InfoModal from "../../../common-modals/info-modal";
import {ChildProductList, ParentProductList} from "./add-recipe-list";
import {
	AddModModalContext,
	RecipeContext,
	SelectedChildProductContext,
	SelectedParentProductContext
} from "./add-recipe-context";
import * as buttonActions from '../../../../redux/actions/button'
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import AddModModal from './add-mod-modal'
import {Modal} from "react-bootstrap";
import SmlProductModal from "../../../common-modals/sml-product-modal";

const AddRecipe = () => {

	const defaultSearchData = { item: '', item_usr: '', item_nm: '' }
	/* item_nm 검색창의 ref */
	const itemNmRef = useRef<HTMLInputElement>(null)

	/* 레시피 데이터, 수정/추가를 위한 다른 데이터 추가 */
	const [ recipe, setRecipe ] = useState<IRecipe[]>([])
	const [ beforeRecipe, setBeforeRecipe ] = useState<IBeforeRecipe[]>([])
	/* 품목 데이터 */
	const [ productData, setProductData ] = useState<IProductData[]>([])
	/* 검색창 입력/실제 검색 데이터 */
	const [ searchInputData, setSearchInputData ] = useState(defaultSearchData)
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	/* 선택된 모품목/자품목 */
	const [ clickedParentProduct, setClickedParentProduct ] = useState({ index: -2, item_p_nm: '', item_p: '', item_p_usr: '' })
	const [ selectedParentProduct, setSelectedParentProduct ] = useState<IParentProduct[]>([])
	const [ selectedChildProduct, setSelectedChildProduct ] = useState<IChildProduct[]>([])
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* product list 보이기 */
	const [ showSmlProductList, setShowSmlProductList ] = useState(false)
	/* 추가/변경 모달 보이기, 그 모드(1이면 추가, 2이면 변경) */
	const [ showAddModModal, setShowAddModModal ] = useState(false)
	const [ addModMode, setAddModMode ] = useState(1)
	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)
	/* 전체 선택 */
	const [ selectAll, setSelectAll ] = useState(false)

	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 제품 테이블의 칼럼 */
	const columnsProduct = useMemo(() => [
		{
			name: <input type="checkbox" className="form-check-input" checked={selectAll} onChange={() => setSelectAll(!selectAll)} />,
			style: { width: '30px', textAlign: 'center' }
		},
		{ name: '코드', style: {  width: '50px', textAlign: 'center' }  },
		{ name: '품명', style: { width: '200px', textAlign: 'center' }  },
		{ name: '규격', style: { width: '200px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '50px', textAlign: 'center' }  },
	] as IColumn[], [selectAll])

	/* 레시피 테이블의 칼럼 */
	const columnsRecipe = useMemo(() => [
		{ name: 'No', style: {  width: '30px', textAlign: 'center' }  },
		{ name: '코드', style: { width: '40px', textAlign: 'center' }  },
		{ name: '품명(원재료)', style: { width: '200px', textAlign: 'center' }  },
		{ name: '규격', style: { width: '200px', textAlign: 'center' }  },
		{ name: '단위', style: { width: '40px', textAlign: 'center' }  },
		{ name: '계정구분', style: { width: '70px', textAlign: 'center' } },
		{ name: '제조비율', style: { width: '70px', textAlign: 'center' } },
		{ name: 'loss율', style: { width: '70px', textAlign: 'center' } },
	] as IColumn[], [])

	/* 모/자품목 데이터 */
	const parentProduct = useMemo(() =>
		recipe
			.reduce((prev, cur) =>
					prev.findIndex(elem => elem.item_p === cur.item_p) === -1 ? prev.concat(cur as IParentProduct) : prev
				, [] as IParentProduct[])
			.filter(pd_elem => {
				return pd_elem.item_p_nm.toLowerCase().includes(searchData.item_nm.toLowerCase())
			})
		, [recipe, searchData.item_nm])
	const childProduct = useMemo(() =>
		recipe
			.filter(element => element.item_p === clickedParentProduct.item_p)
			.reduce((prev, cur) => {
				return prev.concat(cur as IChildProduct)
			}, [] as IChildProduct[])
		, [recipe, clickedParentProduct])

	/* 레시피, 제품 정보 불러옴 */
	const fetchRecipeData = () => {
		client.get('/api/production-manage/get-recipe')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsRecipe } = res.data.payload

					setRecipe(rowsRecipe.map((element: IRecipe) => (
						{
							...element,
							qty: (parseFloat(element.qty) * 100).toString(),
							loss_rate: (parseFloat(element.loss_rate)).toString()
						}
					)))
					setBeforeRecipe(rowsRecipe.map((element: IRecipe) => (
						{
							index: element.index,
							item_p: element.item_p,
							item: element.item
						}
					)))
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}
	const fetchProductData = () => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProduct } = res.data.payload
					setProductData(rowsProduct)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}


	const handleChangeOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputData({...searchInputData, item_nm: e.target.value, item: '', item_usr: ''})
	}

	/* 검색창 엔터 입력 */
	const handleEnterOnInput = (e: any) => {
		if(e.key === 'Enter') {
			const filtered = productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()) || pd_elem.item_usr.includes(e.target.value) || pd_elem.spec.includes(e.target.value)
			)
			const target = filtered[0]
			if(filtered.length !== 1) {
				setShowSmlProductList(true)
			} else {
				setSearchInputData({ ...searchData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
				setSearchData({ ...searchData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
			}
		}
	}

	/* 검색 버튼 클릭 */
	const handleClickOnSearchButton = () => {
		const value = itemNmRef.current === null ? '' : itemNmRef.current.value
		const filtered = productData.filter(elem =>
			(elem.item_nm.toLowerCase().includes(value.toLowerCase()) || elem.item_usr.includes(value) || elem.spec.includes(value))
		)
		if(filtered.length !== 1) {
			setShowSmlProductList(true)
		} else {
			setSearchInputData({ ...searchData, item_nm: filtered[0].item_nm })
		}
	}

	/* 품목 검색 모달 입력 완료시 */
	const handleSubmitOnSmlProductSearch = (data: IProductData) => {
		setSearchInputData({ ...searchData, item: data.item, item_usr: data.item_usr, item_nm: data.item_nm })
		setShowSmlProductList(false)
	}

	/* 삭제 수행 */
	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post('/api/production-manage/delete-whole-recipe',
				{
					deletedRows: selectedParentProduct.map(element => ({
						item_p: element.item_p
					}))
				})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setSelectedParentProduct([])
				setSelectAll(false)
				fetchRecipeData()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 레시피 데이터 가져오기 */
	useEffect(() => {
		fetchRecipeData()
		fetchProductData()
	}, [])

	/* 버튼 클릭 시 취할 행동 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'remove' && selectedParentProduct.length !== 0) {
			setShowDeleteModal(true)
		} else if(btnActions.active && btnActions.action === 'search') {
			setSearchData(searchInputData)
		} else if (btnActions.active && btnActions.action === 'input') {
			setAddModMode(1)
			setShowAddModModal(true)
			setClickedParentProduct({ index: -2, item_p_nm: '', item_p: '', item_p_usr: '' })
		}
		dispatch(buttonActions.clear())
	}, [btnActions.action, btnActions.active, dispatch, searchInputData])

	/* 조회 칸이 ''이면 왼쪽 테이블 되돌림 */
	useEffect(() => {
		if(searchInputData.item_nm.length === 0)
			setSearchData(defaultSearchData)
	}, [searchInputData.item_nm.length])

	return (
		<>
			{/* 상단 메뉴 */}
			<div className="d-flex mt-3">
				<div className="flex-grow-1 d-flex ms-3 flex-column flex-lg-row">
					<div className="d-flex align-content-center mx-3">
						<label className="col-auto align-items-center my-1 mx-2">제품명</label>
						<div className="input-group input-group-sm" style={{ textAlign: 'center' }}>
							<input ref={itemNmRef} type="text" className="form-control" style={{ width: '250px'}} value={searchInputData.item_nm}
								   onChange={handleChangeOnInput}
								   onKeyDown={handleEnterOnInput}/>
							<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>

			{/* 테이블1 */}
			<div className="d-flex">
				{/* 테이블 1(제품) */}
				<div className="d-flex flex-column mx-3 mt-4" style={{width: '600px', display: 'block', overflow: 'auto'}}>

					<label className="ms-3 fw-bold">제품</label>
					<table className="table table-sm table-hover table-bordered table-condensed" style={{ width: '100%' }}>
						<thead>
						<tr>
							{columnsProduct.map((column, index) =>
								<th key={index} style={column.style} scope="column">{column.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<SelectedParentProductContext.Provider value={
							{ selectedParentProduct, setSelectedParentProduct, clickedParentProduct, setClickedParentProduct, selectAll }
						}>
							<AddModModalContext.Provider value={{ setAddModMode, setShowAddModModal }}>
								<ParentProductList data={parentProduct} />
							</AddModModalContext.Provider>
							<RecipeContext.Provider value={{ recipe, beforeRecipe, productData }}>
								<AddModModal show={showAddModModal} onHide={() => setShowAddModModal(false)} fetchRecipe={fetchRecipeData} addModMode={addModMode} />
							</RecipeContext.Provider>
						</SelectedParentProductContext.Provider>
						</tbody>
					</table>
				</div>

				{/* 테이블 2(레시피) */}
				<div className="d-flex flex-column flex-grow-1 mx-3 mt-4" style={{ width: '900px', overflow: 'auto', display: 'block' }}>
					<label className="ms-3 fw-bold">레시피</label>
					<table className="table table-sm table-hover table-bordered table-condensed">
						<thead>
						<tr>
							{columnsRecipe.map((column, index) =>
								<th key={index} style={column.style} scope="column">{column.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<SelectedChildProductContext.Provider value={{ selectedChildProduct, setSelectedChildProduct }}>
							<ChildProductList data={childProduct} />
						</SelectedChildProductContext.Provider>
						</tbody>
					</table>
				</div>
			</div>

			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
				<Modal.Header closeButton>
					<Modal.Title>
						레시피 삭제
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 레시피를 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />

			<SmlProductModal product={productData} show={showSmlProductList} onHide={() => setShowSmlProductList(false)}
							 onSubmit={handleSubmitOnSmlProductSearch} initialSearchData={searchInputData.item_nm} />
		</>
	)
}

export default AddRecipe