/* 이 프로젝트에서 사용할 axios 인스턴스의 설정 */
import axios from 'axios'

const cancelToken = axios.CancelToken
const source = cancelToken.source()

/* 새 인스턴스 설정 */
const client = axios.create({
  baseURL: 'https://subul.co.kr',
  // baseURL: 'http://localhost:8080',
  withCredentials: true,
  cancelToken: source.token
})
client.defaults.headers.post['Content-Type'] = 'application/json'
client.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://subul.co.kr'
client.defaults.headers.get['Cache-Control'] = 'no-cache'
client.defaults.headers.get['Pragma'] = 'no-cache'

export default client