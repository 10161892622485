import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import client from '../../axios'
import './styles.css'
import {Link} from 'react-router-dom'

const LoginForm = () => {

    /* 체크박스 레퍼런스 */
    const checkboxRef = useRef<HTMLInputElement>(null)

    /* 로그인 실패 시 뜨게 할 여부 */
    const [ loginFailed, setLoginFailed ] = useState({
        failed: false,
        message: ''
    })
    /* 계정 정보를 useState로 state를 만들어서 관리 */
    const [ userInfo, setUserInfo ] = useState({
        id: '',
        pwd: '',
        user_nm: '',
    })

    /* 포커싱을 위해 useRef 사용 */
    const idInput = useRef<HTMLInputElement>(null),
        pwdInput = useRef<HTMLInputElement>(null),
        loginButton = useRef<HTMLButtonElement>(null)
    /* 라우팅을 위해 useNavigate 사용 */
    const navigate = useNavigate()

    /* 로컬 스토리지에 저장된 아이디가 있으면 그것 사용 */
    useEffect(() => {
        setUserInfo({ ...userInfo, id: localStorage.getItem("id") !== null ? localStorage.getItem("id") as string : "" })
    }, [])

    /* 로그인 폼에서 엔터 키를 눌렀을 때 실행되는 함수 */
    const onEnterKeyPress = async (e: any) => {
        if(e.key === 'Enter') {
            switch(document.activeElement?.id) {
                case 'id':
                    pwdInput.current?.focus()
                    break

                case 'pwd':
                    loginButton.current?.focus()
                    break

                default:
            }
        }
    }
    /* id나 pwd칸이 바뀌었을 때 실행될 함수, userInfo를 바꿈 */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        /* 어느 입력란이냐(id, pwd)에 따라 바뀌는 state 값이 다름 */
        setUserInfo({
            ...userInfo,
            [e.target.id]: e.target.value
        })
    }

    const handleChangeOnID = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({ ...userInfo, id: e.target.value })
    }

    /* 로그인 시행 */
    const requestLogin = async () => {
        try {
            /* 로그인 요청 */
            const response = await client.post('/api/auth/login-request', {
                id: userInfo.id,
                pwd: userInfo.pwd,
            })

            /* 응답 데이터의 result 값에 따른 처리 */
            switch(response.data.result) {
                /* 로그인 성공 */
                case 'success':
                    if(checkboxRef.current) {
                        if(checkboxRef.current.checked) {
                            localStorage.setItem("id", userInfo.id)
                        }
                        else
                            localStorage.removeItem("id")
                    }
                    navigate('/')
                    break

                /* 로그인 실패 */
                case 'failed':
                    setLoginFailed({
                        failed: true,
                        message: '계정 정보를 찾을 수 없습니다.'
                    })
                    break

                case 'error':
                    setLoginFailed({
                        failed: true,
                        message: '서버에 문제가 발생하였습니다.'
                    })
            }
        } catch (e: any) {
            setLoginFailed({
                failed: true,
                message: '에러가 발생하였습니다.'
            })
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="text-center login-form-style">


                {/* 아이디, 비밀번호 입력칸 */}
                <div className="mb-1 mt-3 mx-1">
                    <input id="id" type="text" onChange={handleChangeOnID} autoFocus onKeyDown={onEnterKeyPress} ref={idInput} value={userInfo.id}
                           className={`form-control border-0 mb-2 border-bottom rounded-0 ${loginFailed.failed ? 'border-danger' : ''} shadow-none`}
                           placeholder="ID"/>

                    <input id="pwd" type="password" onChange={onChange} onKeyDown={onEnterKeyPress} ref={pwdInput}
                           className={`form-control border-0 mb-2 border-bottom rounded-0 ${loginFailed.failed ? 'border-danger' : ''} shadow-none`}
                           placeholder="비밀번호"/>
                </div>
                <div className="mx-1 mb-3 text-start" style={{ fontSize: '10pt' }}>
                    {loginFailed.failed ? <label className="text-danger">{loginFailed.message}</label> : ''}
                </div>

                {/* 아이디 저장 체크박스 */}
                <div className="d-flex justify-content-between my-3 mx-3">
                    <label>
                        <input id="check" type="checkbox" ref={checkboxRef} className="form-check-input"
                               defaultChecked={localStorage.getItem("id") !== null} />
                        &nbsp;
                        <label htmlFor="check">아이디 저장</label>
                    </label>
                    <Link className="text-secondary" style={{ textUnderlinePosition: 'under', pointerEvents: 'none' }} to={'/register/use-terms'}>
                        회원가입
                    </Link>
                </div>

                {/* 로그인 버튼 */}
                <button id="loginButton" className="w-100 btn btn-lg btn-secondary"
                         ref={loginButton} onClick={requestLogin}>로그인</button>
            </div>
        </div>
    )
}

export default LoginForm