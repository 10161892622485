import React, {useState, useEffect, useContext} from 'react'
import {MatPayListProps, MatPayRowProps} from "../../../interfaces/stock-manage/i-mat-pay";
import {MatPayContext} from "./mat-pay-context";
import {convertFloat, convertReadable} from "../../../../lib/converting-func";
import * as changeMenuActions from "../../../../redux/actions/change-menu";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";

const MatPayList = ({ ymd, data }: MatPayListProps) => {

	/* 컨텍스트 */
	const mpContext = useContext(MatPayContext)

	/* 소수점 */
	const { dec } = useSelector((state: RootReducer) => state.dec, shallowEqual)

	/* 포커싱된 행의 번호 */
	const [ focusedIndex, setFocusedIndex ] = useState(-1)

	return (
		<>
			{data.map((row, index) => {
					return <MatPayRow key={index} row={row} ymd={ymd} mpContext={mpContext} dec={dec}
									  focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex}/>
				})
			}
		</>
	)
}

const MatPayRow = ({ row, mpContext, focusedIndex, setFocusedIndex, dec }: MatPayRowProps) => {

	const [ focusMode, setFocusMode ] = useState({
		stk_bs: false,
		stk_now: false
	})

	/* dispatch 인스턴스 생성 */
	const dispatch = useDispatch()

	/* 재고조정이 꺼지면 포커스 풀기 */
	useEffect(() => {
		setFocusMode({ stk_bs: false, stk_now: false })
	}, [mpContext.adj])

	/* focusedIndex에 따라 행 포커싱 */
	useEffect(() => {
		if(row.idx === focusedIndex)
			if(!focusMode.stk_now && !focusMode.stk_bs)
				setFocusMode({ ...focusMode, stk_bs: true})
	})



	/* 테이블 셀 선택, 블러 */
	const handleClickOnStkBsCell = () => {
		if(mpContext.adj) {
			setFocusedIndex(row.idx)
			setFocusMode({ stk_bs: true, stk_now: false })
		}
	}
	const handleClickOnStkNowCell = () => {
		if(mpContext.adj) {
			setFocusedIndex(row.idx)
			setFocusMode({ stk_bs: false, stk_now: true })
		}
	}
	const handleBlurOnStkBsCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ ...focusMode, stk_bs: false })
	}
	const handleBlurOnStkNowCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ ...focusMode, stk_now: false })
	}

	/* stk_bs */
	const handleChangeOnStkBs = (e: React.ChangeEvent<HTMLInputElement>) => {
		mpContext.setMatPay(mpContext.matPay.map(element => ({
			...element,
			stk_bs: row.idx === element.idx ? e.target.value : element.stk_bs
		})))
	}
	const handleBlurOnStkBs = (e: React.FocusEvent<HTMLInputElement>) => {
		const tmp = parseFloat((e.target.value.replaceAll(/[^0-9.]+/g, '')))
		const modifiedStkBs = isNaN(tmp) ? '' : tmp.toString()

		mpContext.setMatPay(mpContext.matPay.map(element => ({
			...element,
			stk_bs: row.idx === element.idx ? modifiedStkBs : element.stk_bs
		})))
	}
	const handleEnterOnStkBs = (e: any) => {
		if(e.key === 'Enter') {
			setFocusMode({ stk_bs: false, stk_now: true })
		}
	}

	/* stk_now */
	const handleChangeOnStkNow = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(changeMenuActions.change())
		mpContext.setMatPay(mpContext.matPay.map(element => ({
			...element,
			stk_now: row.idx === element.idx ? e.target.value : element.stk_now
		})))
	}
	const handleBlurOnStkNow = (e: React.FocusEvent<HTMLInputElement>) => {
		const tmp = parseFloat((e.target.value.replaceAll(/[^0-9.]+/g, '')))
		const modifiedStkBs = isNaN(tmp) ? '' : tmp.toString()

		mpContext.setMatPay(mpContext.matPay.map(element => ({
			...element,
			stk_now: row.idx === element.idx ? modifiedStkBs : element.stk_now
		})))
	}
	const handleEnterOnStkNow = (e: any) => {
		if(e.key === 'Enter') {
			setFocusedIndex(getNextFocusingCell())
			setFocusMode({ stk_bs: true, stk_now: false })
		}
	}

	/* 다음 포커싱 행 구하기 */
	const getNextFocusingCell = () => {
		return row.idx === mpContext.matPay.length ? 1 : row.idx + 1
	}

	return (
		<>
			<tr>
				<td style={{ textAlign: row.item_nm === '합계' ? 'center' : 'start' }}>{row.item_nm}</td>
				<td style={{ textAlign: 'center'}}>{row.unit_nm}</td>
				<td style={{ textAlign: 'end' }}
					onClick={handleClickOnStkBsCell}
					onBlur={handleBlurOnStkBsCell}
				>
					{
						focusMode.stk_bs && mpContext.adj
							?
							<input type="text" className={`form-control form-control-sm shadow-none ${convertFloat(row.stk_bs) < 0 ? "text-danger" : ""}`}
								   style={{textAlign: "end"}}
								   value={row.stk_bs} autoFocus
								   onChange={handleChangeOnStkBs}
								   onKeyDown={handleEnterOnStkBs}
								   onBlur={handleBlurOnStkBs}
							/>
							:
							convertReadable(row.stk_bs, dec)
					}
				</td>
				<td style={{ textAlign: 'end' }}>{convertReadable(row.stk_in, dec)}</td>
				<td style={{ textAlign: 'end' }}>{convertReadable(row.thrown, dec)}</td>
				<td style={{ textAlign: 'end' }}>{convertReadable(row.stk_out, dec)}</td>
				<td style={{ textAlign: 'end' }}
					onClick={handleClickOnStkNowCell}
					onBlur={handleBlurOnStkNowCell}
				>
					{
						focusMode.stk_now && mpContext.adj
							?
							<input type="text" className={`form-control form-control-sm shadow-none ${convertFloat(row.stk_now) < 0 ? "text-danger" : ""}`}
								   style={{ textAlign: "end" }}
								   value={row.stk_now} autoFocus
								   onChange={handleChangeOnStkNow}
								   onKeyDown={handleEnterOnStkNow}
								   onBlur={handleBlurOnStkNow}
							/>
							:
							convertReadable(row.stk_now, dec)
					}
				</td>
				<td />
			</tr>
		</>
	)
}

export default MatPayList