import React from 'react'
import { createRoot } from 'react-dom/client'
import $ from 'jquery'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'

import Login from './pages/login'
import Main from './pages/main'
import client from './axios'
import rootReducer from './redux/reducers'


import 'bootstrap/dist/css/bootstrap.min.css'
import InputAccInfo from "./pages/register/input-acc-info";

import './components/main-styles.css'
import UseTerms from "./pages/register/use-terms";
import Header from "./components/register/header";

const store = createStore(rootReducer, composeWithDevTools())

// 창을 닫는지 판별하는 변수
let closing_window = false

/* 화면에 포커스가 있다면 창을 종료하는 것이 아님 */
$(window).on('focus', () => {
    closing_window = false
})

/* 화면에 포커스가 사라지면 창을 닫을 확률이 높다 */
$(window).on('blur', () => {

    closing_window = document.hidden;
    $(window).on('resize', () => {
        closing_window = false
    })
    $(window).off('resize')
})

/* 브라우저 화면에서 커서가 나가면 종료 버튼을 누를 확률이 크다. */
$('html').on('mouseleave', () => {
    closing_window = true
})

/* 브라우저 화면에 커서가 들어오면 종료 버튼을 누를 확률이 작다. */
$('html').on('mouseenter', () => {
    closing_window = false
})

/* 새로고침 키(F5 또는 Ctrl+R)가 눌리거나 Alt+Tab 이 눌리면 종료하는 것이 아니다 */
$(document).on('keydown', (e) => {
    if(e.key === 'Alt' || (e.altKey && e.key === 'Tab')) {
        closing_window = false
    }
    if(e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
        closing_window = false
        console.log(closing_window)
    }
})

/* anchor를 누르는 것은 종료하는 것이 아니다 */
$(document).on('click', 'a', () => {
    closing_window = false
})

/* button을 누르는 것은 종료하는 것이 아니다. */
$(document).on('click', 'button', () => {
    closing_window = false
})

/* form에서 submit을 클릭하는 것은 종료하는 것이 아니다. */
$(document).on('submit', 'form', () => {
    closing_window = false
})

/* submit을 누르는 것은  */
$(document).on('click', 'input[type=submit]', () => {
    closing_window = false
})

window.addEventListener('beforeunload', () => {
    if(closing_window) {
        client.delete('/api/auth/logout').then(() => {
        })
    }
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/" element={<Main/>} />
                <Route path="/register" element={<Header />}>
                    <Route path="use-terms" element={<UseTerms />} />
                    <Route path="input-info" element={<InputAccInfo />} />
                </Route>
                {/*<Route path="/franchise-nara">*/}
                {/*    <Route path='announcement' element={<Announcement />} />*/}
                {/*    <Route path='announcement/*' element={<ViewOrEdit />} />*/}
                {/*</Route>*/}
            </Routes>
        </BrowserRouter>
    </Provider>
)