import {buttonActionsTypes} from '../actions/button'

/* initial state */
const initialState = {
    action: 'none',
    active: false
}

/* state의 타입 */
type buttonActionsState = typeof initialState

/* 리듀서 */
const button = (state: buttonActionsState = initialState, action: buttonActionsTypes): buttonActionsState => {
    switch (action.type) {
        case "buttonActions/SEARCH":
            return {
                action: 'search',
                active: action.payload
            }

        case "buttonActions/INPUT":
            return {
                action: 'input',
                active: action.payload
            }

        case "buttonActions/SAVE":
            return {
                action: 'save',
                active: action.payload
            }

        case "buttonActions/REMOVE":
            return {
                action: 'remove',
                active: action.payload
            }

        case "buttonActions/PRINT":
            return {
                action: 'print',
                active: action.payload
            }

        case "buttonActions/EXCEL":
            return {
                action: 'excel',
                active: action.payload
            }

        case "buttonAcions/CLEAR":
            return {
                action: 'none',
                active: false
            }

        default:
            return state
    }
}

export default button