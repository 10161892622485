import React, {useContext, useEffect, useState} from 'react'
import {
	ChildProductListProps, ChildProductRowProps,
	ParentProductListProps, ParentProductRowProps
} from '../../../interfaces/production-manage/i-add-recipe'
import {accCategory} from "../../../../lib/common-args";
import {AddModModalContext, SelectedParentProductContext} from "./add-recipe-context";
import {defaultNewRow} from "./add-mod-modal";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";


/* 모품목 리스트 */
export const ParentProductList = ({ data }: ParentProductListProps) => {

	const parentContext = useContext(SelectedParentProductContext)
	const addModModalContext = useContext(AddModModalContext)

	useEffect(() => {
		if(parentContext.selectAll)
			parentContext.setSelectedParentProduct(data)
		else
			parentContext.setSelectedParentProduct([])

	}, [parentContext.selectAll])

	return (
		<>
			{data.map((row, index) =>
				<ParentProductRow key={index} row={row} amContext={addModModalContext} parentContext={parentContext} />
			)}
		</>
	)
}

/* 모품목 행 */
export const ParentProductRow = ({ row, parentContext, amContext }: ParentProductRowProps) => {

	/* 체크박스 체크 여부 */
	const [ checked, setChecked ] = useState(false)

	/* 행 선택 시 clickedCustomer 설정 */
	const handleClickOnRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
		parentContext.setClickedParentProduct(row)

		if(e.detail === 2) {
			amContext.setAddModMode(2)
			amContext.setShowAddModModal(true)
		}
	}

	/* 행 선택 시 selected 리스트에 추가 */
	useEffect(() => {
		if(checked) {
			if(parentContext.selectedParentProduct.findIndex(element => element.item_p === row.item_p) === -1)
				parentContext.setSelectedParentProduct(parentContext.selectedParentProduct.concat(row))
		} else {
			if(parentContext.selectedParentProduct.findIndex(element => element.item_p === row.item_p) === -1)
				parentContext.setSelectedParentProduct(parentContext.selectedParentProduct.filter(element => element.item_p !== row.item_p))
		}
	}, [checked])

	useEffect(() => {
		if(parentContext.selectedParentProduct.length === 0)
			setChecked(false)
	}, [parentContext.selectedParentProduct.length])

	useEffect(() => {
		if(parentContext.selectAll)
			setChecked(true)
		else
			setChecked(false)
	}, [parentContext.selectAll])

	return (
		<tr style={row.index === parentContext.clickedParentProduct.index ? { backgroundColor: '#eeeeee' } : {}}>
			<th style={{ textAlign: 'center' }} onClick={handleClickOnRow}>
				<input type="checkbox" className="form-check-input" checked={checked} onChange={() => setChecked(!checked)}/>
			</th>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>{row.item_p_usr}</td>
			<td onClick={handleClickOnRow}>{row.item_p_nm}</td>
			<td onClick={handleClickOnRow}>{row.spec_p}</td>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>{row.unit_p_nm}</td>
		</tr>
	)
}

/* 자품목 리스트 */
export const ChildProductList = ({ data }: ChildProductListProps) => {

	const [ total, setTotal ] = useState(0)
	/* 소수점 */
	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	useEffect(() => {
		let sum = 0
		for(let row of data) {
			sum += isNaN(parseFloat(row.qty)) ? 0 : parseFloat(row.qty)
		}
		setTotal(sum)
	}, [data])

	return (
		<>
			{data.concat({ ...defaultNewRow, qty: total.toFixed(3)}).map((row, index) =>
				<ChildProductRow key={index} row={row} dec={dec} />
			)}
		</>
	)
}

/* 자품목 행 */
export const ChildProductRow = ({ row, dec }: ChildProductRowProps) => {

	return (
		<tr>
			<td style={{ textAlign: 'center' }}>{row.seq === -1 ? '' : row.seq}</td>
			<td style={{ textAlign: 'center' }}>{row.item_usr}</td>
			<td colSpan={row.seq === -1 ? 4 : 0}
				style={row.seq === -1 ? { color: '#0000ff', fontWeight: 'bold', textAlign: 'center' } : {}}>{row.seq === -1 ? '합  계' : row.item_nm}</td>
			{
				row.seq === -1
					?
					null
					:
					<>
						<td>{row.spec}</td>
						<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
						<td style={{ textAlign: 'center' }}>{accCategory.find((element) => element.value === row.acc_gbn)?.name}</td>
					</>
			}
			<td style={{ textAlign: 'end', color: `${row.seq === -1 ? '#0000ff' : '#000000'}` }}>{convertReadable(row.qty, dec) + '%'}</td>
			<td style={{ textAlign: 'end' }}>{convertReadable(row.loss_rate, dec) + '%'}</td>
		</tr>
	)
}