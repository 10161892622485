
export type currentPageTypes =
    ReturnType<typeof dashboard> |

    ReturnType<typeof usercom> |
    ReturnType<typeof standardCode> |
    ReturnType<typeof customer> |
    ReturnType<typeof product> |

    ReturnType<typeof addRecipe> |
    ReturnType<typeof prodPerf> |
    ReturnType<typeof prodDaily> |
    ReturnType<typeof prodMonthly> |

    ReturnType<typeof inputBuy> |
    ReturnType<typeof inputSell> |
    ReturnType<typeof initialStock> |
    ReturnType<typeof stockStatus> |
    ReturnType<typeof stockPay> |

    ReturnType<typeof settings> |

    ReturnType<typeof resetChange>


/* 대시보드 */
const DASHBOARD = 'currentPage/DASHBOARD'

export const dashboard = () => ({
    type: DASHBOARD,
    payload: 'dashboard'
})


/* 입출고관리 */
/* 입고(구매) 입력 */
const INPUTBUY = 'currentPage/INPUTBUY'
/* 출고(판매) 입력 */
const INPUTSELL = 'currentPage/INPUTSELL'
export const inputBuy = () => ({
    type: INPUTBUY,
    payload: 'input-buy'
})

export const inputSell = () => ({
    type: INPUTSELL,
    payload: 'input-sell'
})


/* 기준정보 */
/* 사용회사 정보 */
const USERCOM = 'currentPage/USERCOM'
/* 기준코드 */
const STANDARDCODE = 'currentPage/STANDARDCODE'
/* 거래처 */
const CUSTOMER = 'currentPage/CUSTOMER'
/* 품목 */
const PRODUCT = 'currentPage/PRODUCT'

export const usercom = () => ({
    type: USERCOM,
    payload: 'user-com'
})

export const standardCode = () => ({
    type: STANDARDCODE,
    payload: 'standard-code'
})

export const customer = () => ({
    type: CUSTOMER,
    payload: 'customer'
})

export const product = () => ({
    type: PRODUCT,
    payload: 'product'
})



/* 생산관리 */
/* 레시피 */
const ADDRECIPE = 'currentPage/ADDRECIPE'
/* 생산실적 */
const PRODPERF = 'currentPage/PRODPERF'
/* 생산일보 */
const PRODDAILY = 'currentPage/PRODDAILY'
/* 생산월보 */
const PRODMONTHLY = 'currentPage/PRODMONTHLY'

export const addRecipe = () => ({
    type: ADDRECIPE,
    payload: 'add-recipe'
})

export const prodPerf = () => ({
    type: PRODPERF,
    payload: 'prod-perf'
})

export const prodDaily = () => ({
    type: PRODDAILY,
    payload: 'prod-daily'
})

export const prodMonthly = () => ({
    type: PRODMONTHLY,
    payload: 'prod-monthly'
})


/* 재고관리 */
/* 기초재고 */
const INITIALSTOCK = 'currentPage/INITIALSTOCK'
/* 재고현황 */
const STOCKSTATUS = 'currentPage/STOCKSTATUS'
/* 재고수불부 */
const STOCKPAY = 'currentPage/STOCKPAY'
/* 원료수불부 */
const MATPAY = 'currentPage/MATPAY'

export const initialStock = () => ({
    type: INITIALSTOCK,
    payload: 'initial-stock'
})

export const stockStatus = () => ({
    type: STOCKSTATUS,
    payload: 'stock-status'
})

export const stockPay = () => ({
    type: STOCKPAY,
    payload: 'stock-pay'
})

export const matPay = () => ({
    type: MATPAY,
    payload: 'mat-pay'
})

/* 환경설정 */
const SETTINGS = 'currentPage/SETTINGS'
export const settings = () => ({
    type: SETTINGS,
    payload: 'settings'
})


/* 체인지 변경 */
const RESETCHANGE = 'currentPage/RESETCHANGE'

export const resetChange = () => ({
    type: RESETCHANGE,
    payload: 'reset-change'
})

/* 어드민 구분 설정 */
const SETADMINGBN = 'currentPage/SETADMINGBN'

export const setAdminGbn = (admin_gbn: string) => ({
    type: SETADMINGBN,
    payload: admin_gbn
})

/* 위의 모든 키가 다 들어가있어야 함 */
export const keyVal = [
    DASHBOARD,
    INPUTBUY, INPUTSELL,
    USERCOM, STANDARDCODE, CUSTOMER, PRODUCT,
    ADDRECIPE, PRODPERF, PRODDAILY,
    INITIALSTOCK, STOCKSTATUS, STOCKPAY, MATPAY,
    SETTINGS,
    RESETCHANGE,
    SETADMINGBN
]