import React, {useState, useEffect, useMemo, useContext, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import {IBeforeBuyData, IBuyData, ICusData} from "../../../interfaces/in-out-manage/i-goods-buying";
import {IColumn} from "../../../interfaces/common";
import {BuyDataContext, ClickedSummaryContext} from "./goods-buy-sell-context";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import client from "../../../../axios";
import {AddModModalList} from "./add-mod-modal-list";
import {CustomerSearchModal} from "../../../common-modals/customer-search-modal";
import {convertDateWithHyphen} from "../../../../lib/converting-func";
import {getDate} from "../../../../lib/util-func";
import InfoModal from "../../../common-modals/info-modal";

export interface ISelectedBuyData {
	index: number;
	ymd: string;
	item: string;
	cus: string;
}

interface IAddModModal {
	show: boolean;
	onHide: () => any;
	fetchBuyData: () => void;
	buySellMode: number;
}

export interface ISelectedBuyContext {
	selectedBuyData: ISelectedBuyData[]
	setSelectedBuyData: (selectedBuyData: ISelectedBuyData[]) => void
}

export const SelectedBuyContext = React.createContext<ISelectedBuyContext>({
	selectedBuyData: [] as ISelectedBuyData[],
	setSelectedBuyData: () => {}
})

export interface INewRowContext {
	newRow: IBuyData;
	setNewRow: (newRow: IBuyData) => void;
	buyData: IBuyData[];
	setBuyData: (buyData: IBuyData[]) => void;
	beforeBuyData: IBeforeBuyData[];
	setBeforeBuyData: (beforeBuyData :IBeforeBuyData[]) => void;
	productData: IProductData[];
	ymd: string;
	setShowFailedModal: (flag: boolean) => void;
	setFailedMessage: (message: string) => void;
	setFailedTitle: (title: string) => void;
	isChanged: boolean
	setIsChanged: (isChanged: boolean) => void
}

export const NewRowContext = React.createContext<INewRowContext>({
	newRow: {} as IBuyData,
	setNewRow: () => {},
	buyData: [] as IBuyData[],
	beforeBuyData: [] as IBeforeBuyData[],
	setBeforeBuyData: () => {},
	setBuyData: () => {},
	productData: [] as IProductData[],
	ymd: '',
	setShowFailedModal: () => {},
	setFailedMessage: () => {},
	setFailedTitle: () => {},
	isChanged: false,
	setIsChanged: () => {}
})

/* addModMode가 1이면 추가, 2이면 수정 */
export const AddModModal = ({ show, onHide, fetchBuyData, buySellMode }: IAddModModal) => {

	const cusNmRef = useRef<HTMLInputElement>(null)

	const { clickedCustomer, setClickedCustomer } = useContext(ClickedSummaryContext)
	const {
		addModMode,
		buyData: originalBuyData,
		cusData,
		beforeBuyData: originalBeforeBuyData,
	} = useContext(BuyDataContext)

	const [ buyData, setBuyData ] = useState<IBuyData[]>([])
	const [ beforeBuyData, setBeforeBuyData ] = useState<IBeforeBuyData[]>([])
	const [ newRow, setNewRow ] = useState<IBuyData>({
		index: -1,
		corp: '',
		ymd: '',
		cus: clickedCustomer.cus,
		cus_usr: clickedCustomer.cus_usr,
		cus_nm: clickedCustomer.cus_nm,
		item: '',
		item_usr: '',
		item_nm: '',
		spec: '',
		unit: '',
		unit_nm: '',
		qty: '',
		bigo: ''
	})
	const [ selectedBuyData, setSelectedBuyData ] = useState<ISelectedBuyData[]>([])
	const [ searchData, setSearchData ] = useState({ ymd: '', cus_usr: '', cus_nm: '' })
	const [ productData, setProductData ] = useState<IProductData[]>([])
	const [ initialCusSearchData, setInitialCusSearchData ] = useState('')
	const [ showCusModal, setShowCusModal ] = useState(false)

	/* 변경 사항 저장 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)

	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)

	/* 동작 실패 시 띄울 모달의 정보 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	/* 변경되었는지 확인 */
	const [ isChanged, setIsChanged ] = useState(false)

	const columns = useMemo(() => [
		{ name: '✓', style: { width: '50px' } },
		{ name: '코드', style: { width: '70px', textAlign: 'center' } },
		{ name: '품명', style: { width: '200px', textAlign: 'center' } },
		{ name: '규격', style: { width: '200px', textAlign: 'center' } },
		{ name: '단위', style: { width: '70px', textAlign: 'center' } },
		{ name: '수량', style: { width: '150px', textAlign: 'center' } },
		{ name: '비고', style: { textAlign: 'center' } }
	] as IColumn[], [])

	const data = useMemo(() => buyData.filter(element => clickedCustomer.ymd === element.ymd && clickedCustomer.cus_usr === element.cus_usr)
		.concat(newRow), [buyData, newRow, searchData.cus_nm])

	useEffect(() => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				const { rowsProduct } = res.data.payload
				setProductData(rowsProduct)
			})
	}, [])

	useEffect(() => {
		setSearchData({
			ymd: clickedCustomer.ymd,
			cus_usr: clickedCustomer.cus_usr,
			cus_nm: clickedCustomer.cus_nm
		})
		setNewRow({
			...newRow,
			cus: clickedCustomer.cus,
			cus_usr: clickedCustomer.cus_usr,
			cus_nm: clickedCustomer.cus_nm,
		})
	}, [clickedCustomer])

	useEffect(() => {
		if(addModMode === 1 && show) {
			setSearchData({ ...searchData, ymd: getDate() })
			setClickedCustomer({ ymd: getDate(), cus: '', cus_nm: '', cus_usr: '' })
			setTimeout(() => cusNmRef.current?.focus(), 50)
		}
	}, [addModMode, show])


	useEffect(() => {
		setBuyData(originalBuyData)
	}, [originalBuyData])

	useEffect(() => {
		setBeforeBuyData(originalBeforeBuyData)
	}, [originalBeforeBuyData])

	const handleChangeOnYmd = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(parseInt(e.target.value.replaceAll("-", "")) <= parseInt(getDate())) {
			setSearchData({...searchData, ymd: e.target.value.replaceAll("-", "")})
			setClickedCustomer({ ...clickedCustomer, ymd: e.target.value.replaceAll("-", "") })
		}
	}

	const handleSubmit = (cus: ICusData) => {
		setSearchData({ ...searchData, cus_usr: cus.cus_usr, cus_nm: cus.cus_nm })
		setClickedCustomer({ ...clickedCustomer, cus: cus.cus, cus_usr: cus.cus_usr, cus_nm: cus.cus_nm })
		setIsChanged(true)
		setShowCusModal(false)
	}

	const handleEnterOnCusNm = (e: any) => {
		if(e.key === 'Enter' && addModMode === 1) {
			const filteredCusData = cusData.filter(cd_elem =>
				cd_elem.cus_nm.toLowerCase().includes(e.target.value.toLowerCase()))

			if((e.target.value.length <= 1) || filteredCusData.length === 0) {
				setInitialCusSearchData('')
				setShowCusModal(true)
			} else if(filteredCusData.length >= 2) {
				setInitialCusSearchData(e.target.value)
				setShowCusModal(true)
			} else {
				const addingData = filteredCusData[0]
				const isOverlap = buyData.findIndex(bd_elem =>
					bd_elem.cus === addingData.cus && bd_elem.ymd === clickedCustomer.ymd) !== -1

				if(isOverlap) {
					setFailedTitle('입력 실패')
					setFailedMessage('같은 날, 같은 이름의 거래처가 이미 존재합니다.')
					setShowFailedModal(true)
					setInitialCusSearchData('')
				} else {
					setSearchData({ ...searchData, cus_nm: addingData.cus_nm, cus_usr: addingData.cus_usr })
					setClickedCustomer({...clickedCustomer, cus_nm: addingData.cus_nm, cus_usr: addingData.cus_usr})
				}
			}

			setIsChanged(true)
			e.preventDefault()
		}
	}

	const applyChanged = async () => {
		if(searchData.cus_usr === '') {
			setFailedTitle('저장 실패')
			setFailedMessage('거래처를 입력해주세요.')
			setShowFailedModal(true)
		} else {
			const refinedBuyData = buyData.filter(element => element.item_usr !== '' && element.qty !== '' && element.qty !== '0')
			const refinedBeforeBuyData = (beforeBuyData.filter((element, index) => buyData[index].item_usr !== '' && buyData[index].qty !== '' && buyData[index].qty !== '0'))

			try {
				const response = await client.post((buySellMode === 1 ? '/api/stock-manage/update-cus-buy-data' : '/api/stock-manage/update-cus-sell-data'),
					{
						modifiedRows: refinedBuyData.map((value, index) => ({
							beforeItem: refinedBeforeBuyData[index].item,
							newData: {
								cus: value.cus,
								item: value.item,
								ymd: value.ymd,
								qty: value.qty,
								bigo: value.bigo
							}
						}))
					})
				if(response.data.result === 'expired') {
					setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
					setFailedTitle('저장 실패')
					setShowFailedModal(true)
				} else if(response.data.result === 'failed') {
					setFailedMessage('서버에 문제가 발생하였습니다.')
					setFailedTitle('저장 실패')
					setShowFailedModal(true)
				} else {
					fetchBuyData()
					onHide()
				}
			} catch (e: any) {
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				setFailedTitle('통신 에러')
				setShowFailedModal(true)
			}
		}
	}

	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post((buySellMode === 1 ? '/api/stock-manage/remove-buy-data-by-item' : '/api/stock-manage/remove-sell-data-by-item'),
				{
				deletedRows: selectedBuyData.map(element => ({
					cus: element.cus,
					ymd: element.ymd,
					item: element.item
				}))
			})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setSelectedBuyData([])
				fetchBuyData()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 변경 사항 저장 */
	const handleSaveData = async () => {
		try {
			await applyChanged()
			setShowSaveModal(false)
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	return (
		<Modal show={show} onHide={() => {
			if(isChanged)
				setShowSaveModal(true)
			else {
				onHide()
			}

			setIsChanged(false)
		}} centered size="xl"
			   onExited={() => {
				   setBuyData(originalBuyData)
				   setBeforeBuyData(originalBeforeBuyData)
				   setSearchData({ ymd: '', cus_usr: '', cus_nm: '' })
				   setNewRow({
					   index: -1,
					   corp: '',
					   ymd: '',
					   cus: clickedCustomer.cus,
					   cus_usr: clickedCustomer.cus_usr,
					   cus_nm: clickedCustomer.cus_nm,
					   item: '',
					   item_usr: '',
					   item_nm: '',
					   spec: '',
					   unit: '',
					   unit_nm: '',
					   qty: '',
					   bigo: ''
				   })
				   setSelectedBuyData([])
			   }}>
			<Modal.Header closeButton>
				{"항목" + (addModMode === 1) ? "추가" : "수정"}
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex flex-column mt-2">
					<div className="d-flex align-content-center my-1">
						<div className="d-flex align-content-center mb-2">
							<label style={{ width: "80px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">일자</label>
							<div className="input-group input-group-sm">
								<input type="date" className="form-control" readOnly={addModMode === 2}
									   value={convertDateWithHyphen(searchData.ymd)}
									   onChange={handleChangeOnYmd}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex align-content-center my-1">
						<div className="d-flex align-content-center mt-2">
							<label style={{ width: '80px', textAlign: 'end' }} className="me-2">거래처</label>
							<div className="input-group input-group-sm">
								<span className="input-group-text addonSize">
									{searchData.cus_usr}
								</span>
								<input ref={cusNmRef} type="text" className="form-control" readOnly={addModMode === 2}
									   value={searchData.cus_nm} onChange={e => setSearchData({ ...searchData, cus_nm: e.target.value })}
									   onKeyDown={handleEnterOnCusNm}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex my-3 mx-3" style={{ overflow: 'auto' }}>
					<table className="table table-sm table-bordered table-hover table-condensed">
						<thead>
						<tr>
							{columns.map((value, index) =>
								<th key={index} scope="col" style={{ ...value.style, textAlign: 'center' }}>{value.name}</th>
							)}
						</tr>
						</thead>

						<tbody>
						<SelectedBuyContext.Provider value={{ selectedBuyData, setSelectedBuyData }}>
							<NewRowContext.Provider value={
								{
									newRow,
									setNewRow,
									buyData,
									setBuyData,
									productData,
									ymd: clickedCustomer.ymd,
									beforeBuyData,
									setBeforeBuyData,
									setFailedTitle,
									setFailedMessage,
									setShowFailedModal,
									isChanged,
									setIsChanged
								}
							}>
								<AddModModalList data={data} />
							</NewRowContext.Provider>
						</SelectedBuyContext.Provider>
						</tbody>
					</table>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<button className="btn btn-sm btn-danger me-2" onClick={() => {
					if(selectedBuyData.length !== 0)
						setShowDeleteModal(true)
				}}>선택 항목 삭제</button>
				<button className="btn btn-sm btn-primary" onClick={applyChanged}>저장</button>
			</Modal.Footer>

			{
				addModMode === 1
					?
					<CustomerSearchModal cusData={cusData.filter(cd => buyData.findIndex(bd => searchData.ymd === bd.ymd && cd.cus === bd.cus) === -1) }
										 show={showCusModal} onHide={() => setShowCusModal(false)}
										 onSubmit={handleSubmit} initialSearchData={initialCusSearchData} />
					:
					null
			}

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />

			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						선택 항목 삭제
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 정보를 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>

			{/* 변경 내용 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => {
				setShowSaveModal(false)
			}} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						변경 사항 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					변경된 정보를 저장하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => {
						setShowSaveModal(false)
						setBuyData([])
						setBeforeBuyData([])
						onHide()
					}}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>

		</Modal>
	)
}