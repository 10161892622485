import React from 'react'
import {IBeforeProdPerf, IProdPerf, ISelectedProdPerf} from "../../../interfaces/production-manage/i-prod-perf";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";

export interface IProdPerfContext {
    prodPerf: IProdPerf[]
    setProdPerf: (prodPerf: IProdPerf[]) => void
    beforeProdPerf: IBeforeProdPerf[]
    setBeforeProdPerf: (beforeProdPerf: IBeforeProdPerf[]) => void
    productData: IProductData[]
    isChanged: boolean
    setIsChanged: (isChanged: boolean) => void
}

export const ProdPerfContext = React.createContext<IProdPerfContext>({
    prodPerf: [] as IProdPerf[],
    setProdPerf: () => {},
    beforeProdPerf: [] as IBeforeProdPerf[],
    setBeforeProdPerf: () => {},
    productData: [] as IProductData[],
    isChanged: false,
    setIsChanged: () => {}
})

export interface INewRowContext {
    newRow: IProdPerf
    setNewRow: (newRow: IProdPerf) => void
}

export const NewRowContext = React.createContext<INewRowContext>({
    newRow: {} as IProdPerf,
    setNewRow: () => {}
})


/* 선택된 제품 컨텍스트 */
export interface ISelectedProdPerfContext {
    selectedProdPerf: ISelectedProdPerf[]
    setSelectedProdPerf: (selectedProduct: ISelectedProdPerf[]) => void
}

export const SelectedProdPerfContext = React.createContext<ISelectedProdPerfContext>({
    selectedProdPerf: [] as ISelectedProdPerf[],
    setSelectedProdPerf: () => {}
})

/* 실패 모달 등을 조절하는 컨텍스트 */
export interface IInfoModalContext {
    setShowFailedModal: (flag: boolean) => void;
    setFailedMessage: (message: string) => void;
    setFailedTitle: (title: string) => void;
}

export const InfoModalContext = React.createContext<IInfoModalContext>({
    setShowFailedModal: () => {},
    setFailedMessage: () => {},
    setFailedTitle: () => {}
})

/* 선택된 제품 컨텍스트 */
export interface ISelectedProductContext {
    selectedProduct: IProductData[]
    setSelectedProduct: (selectedProduct: IProductData[]) => void
}

export const SelectedProductContext = React.createContext<ISelectedProductContext>({
    selectedProduct: [] as IProductData[],
    setSelectedProduct: () => {}
})
