
import React, {useContext, useEffect, useState} from 'react'
import {ProdPerfListProps, ProdPerfRowProps} from "../../../interfaces/production-manage/i-prod-perf";
import {AddModModalContext, SelectedProdPerfContext} from "./production-performance-context";
import {convertDateWithHyphen, convertReadable} from "../../../../lib/converting-func";
import {accCategory} from "../../../../lib/common-args";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";

const ProdPerfList = ({ data }: ProdPerfListProps) => {

	/* 컨텍스트 불러오기 */
	const selectedProdPerfContext = useContext(SelectedProdPerfContext)
	const addModModalContext = useContext(AddModModalContext)

	/* 소수점 불러오기 */
	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	const isCheckboxShown = (code: string, seq: number, mode: string) => {
		if(mode === 'checkbox' || mode === 'code') {
			let filtered = data
				.filter(element => element.code === code)
				.sort((a, b) => a.seq - b.seq)

			return filtered[0].seq === seq;
		} else if (mode === 'date') {
			let filtered = data
				.filter(element => element.code.includes(code.slice(0, 6)))
				.sort((a, b) => parseInt(a.code) - parseInt(b.code))

			return filtered[0].code === code && filtered[0].seq === seq;
		}

		return false
	}

	useEffect(() => {
		if(selectedProdPerfContext.selectAll)
			selectedProdPerfContext.setSelectedProdPerf(data)
		else
			selectedProdPerfContext.setSelectedProdPerf([])

	}, [selectedProdPerfContext.selectAll])

	return (
		<>
			{data.map((row, index) =>
				<ProdPerfRow key={index} row={row} isShown={isCheckboxShown} dec={dec}
							 spContext={selectedProdPerfContext} amContext={addModModalContext} />
			)}
		</>
	)
}

const ProdPerfRow = ({ row, spContext, amContext, isShown, dec }: ProdPerfRowProps) => {

	/* 체크박스 체크 여부 */
	const [ checked, setChecked ] = useState(false)

	/* 행 더블클릭 */
	const handleClickOnRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
		spContext.setClickedProdPerf(row)

		if(e.detail === 2) {
			amContext.setAddModMode(2)
			amContext.setShowAddModModal(true)
		}
	}

	useEffect(() => {
		if(checked) {
			if(spContext.selectedProdPerf.findIndex(element => element.code === row.code) === -1)
				spContext.setSelectedProdPerf(spContext.selectedProdPerf.concat(row))
		} else {
			if(spContext.selectedProdPerf.findIndex(element => element.code === row.code) !== -1)
				spContext.setSelectedProdPerf(spContext.selectedProdPerf.filter(element => element.code !== row.code))
		}
	}, [checked])

	useEffect(() => {
		if(spContext.selectAll)
			setChecked(true)
		else
			setChecked(false)
	}, [spContext.selectAll])

	return (
		<tr>
			<th style={{ textAlign: 'center' }} >
				{
					isShown(row.code, row.seq, "checkbox")
						?
						<input type="checkbox" className="form-check-input" checked={checked} onChange={() => setChecked(!checked)}/>
						:
						''
				}
			</th>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>
				{
					isShown(row.code, row.seq, "date")
						?
						convertDateWithHyphen(row.ymd)
						:
						''
				}
			</td>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>
				{
					isShown(row.code, row.seq, "code")
						?
						row.code.charAt(row.code.length - 1)
						:
						''
				}
			</td>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>{row.item_usr}</td>
			<td onClick={handleClickOnRow}>{row.item_nm}</td>
			<td onClick={handleClickOnRow}>{row.spec}</td>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>{row.unit_nm}</td>
			<td style={{ textAlign: 'end' }} onClick={handleClickOnRow}>{convertReadable(row.qty, dec)}</td>
			<td style={{ textAlign: 'center' }} onClick={handleClickOnRow}>{accCategory.find((element) => element.value === row.acc_gbn)?.name}</td>
			<td>{row.bigo}</td>
			<td />
		</tr>
	)
}

export default ProdPerfList