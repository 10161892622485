import React, {useState} from 'react'
import {IPrdStandardCode} from '../../../../interfaces/standard-info/i-product'
import {Button, Modal} from 'react-bootstrap'

interface ICodeModal {
	show: boolean;
	codeData: IPrdStandardCode[];
	onHide: () => any;
	onSubmit: (sml: string, sml_nm: string) => void
}

const CodeModal = ({show, onHide, codeData, onSubmit}: ICodeModal) => {

	let timeoutID
	/* 기준코드(단위) 찾을 때 사용 데이터 */
	const [ unitCode, setUnitCode ] = useState<IPrdStandardCode>({ sml: '', sml_nm: '', use_gbn: '' })
	const [ codeSearchData, setCodeSearchData ] = useState('')
	const [ unit, setUnit ] = useState('')
	const [ clickCount, setClickCount ] = useState(0)

	const handleHideCodeModal = () => {
		setCodeSearchData('')
		onHide()
	}

	const handleSubmit = () => {
		onSubmit(unitCode.sml, unitCode.sml_nm)
		onHide()
	}

	const handleClickOnRow = (sml: string, sml_nm: string) => {
		timeoutID = setTimeout(() => setClickCount(0), 3000)
		setClickCount(clickCount + 1)
		setUnit(sml)
		setUnitCode({ use_gbn: '1', sml, sml_nm })

		if(clickCount === 1 && sml === unitCode.sml) {
			setClickCount(0)
			clearTimeout(timeoutID)
			onSubmit(sml, sml_nm)
			onHide()
		} else if(sml !== unitCode.sml) {
			setClickCount(0)
			clearTimeout(timeoutID)
		}
	}

	return (

		<Modal show={show} onHide={handleHideCodeModal} centered>
			<Modal.Header closeButton>
				<Modal.Title>
					단위 검색
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex align-content-center my-3">
					<label className="col-auto align-items-center my-1 mx-1">검색어</label>
					<div className="input-group input-group-sm">
						<input type="text" className="form-control" value={codeSearchData} onChange={e => setCodeSearchData(e.target.value)}/>
					</div>
				</div>

				<div style={{ height: '50vh', overflow: 'auto' }}>
					<table id="codeTable" className="table table-sm table-bordered table-hover">
						<thead className="table-dark">
						<tr>
							<th scope="col">코드</th>
							<th scope="col">단위</th>
						</tr>
						</thead>
						<tbody>
						{codeData
							.filter(element => {
								/* 단위 코드, 단위명 */
								let unit = false, unit_nm = false
								let lowered = element.sml_nm.toLowerCase()

								if(codeSearchData === '') {
									unit = unit_nm = true
								} else {
									if(lowered.includes(codeSearchData.toLowerCase()))
										unit_nm = true

									if(element.sml.includes(codeSearchData))
										unit = true
								}

								return unit_nm || unit
							})
							.map((element, index) => {
								return (element.use_gbn === '1') ?
									(<tr key={`sml-${index}`} className={element.sml === unit ? 'code-row-selected' : ''}
										 onClick={() => handleClickOnRow(element.sml, element.sml_nm)}>
										<th scope="row">{element.sml}</th>
										<td>{element.sml_nm}</td>
									</tr>) :
									null
							})}
						</tbody>
					</table>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={handleHideCodeModal}>
					닫기
				</Button>
				<Button variant="primary" onClick={handleSubmit}>
					입력
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default CodeModal