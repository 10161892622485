import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {accCategory} from '../../../../lib/common-args'
import {IInitialStockContext} from './initial-stock-context'
import {IProductData} from '../../../interfaces/stock-manage/i-initial-stock'

/* 추가 모달의 props */
interface CarryOverAddModalProps {
	show: boolean;
	onHide: () => any;
	onSubmit: (startIndex: number, selectedData: IProductData[]) => void;
	context: IInitialStockContext;
	initialSearchData: string;
	startIndex: number;
	mode: number;
}

/* selected row context */
const SelectedProductContext = React.createContext({
	selectedProduct: [] as IProductData[],
	setSelectedProduct: (selectedProduct: IProductData[]) => {}
})

const AddModal = ({ show, onHide, onSubmit, context, initialSearchData, startIndex, mode }: CarryOverAddModalProps) => {

	/* 기본 검색 데이터 */
	const defaultSearchData = {
		item_nm: '',
		acc_gbn: '00000'
	}
	/* 검색 데이터 */
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	/* 선택한 데이터 */
	const [ selectedProduct, setSelectedProduct ] = useState<IProductData[]>([])

	const columns = useMemo(() => [
		{ name: '✓', style: { width: '50px' } },
		{ name: '코드', style: {  width: '70px' } },
		{ name: '품명', style: { width: '300px' } },
		{ name: '규격', style: { width: '200px' } },
		{ name: '계정구분', style: { width: '140px' } },
		{ name: '현재고', style: { width: '150px' } },
	], [])

	const data = useMemo(() => context.productData.map(product => {
		return {
			item: product.item,
			item_usr: product.item_usr,
			item_nm: product.item_nm,
			spec: product.spec,
			unit: product.unit,
			unit_nm: product.unit_nm,
			acc_gbn: product.acc_gbn,
			acc_nm: product.acc_nm,
			qty_bs: ''
		}
	})
		.filter(element => {
			let item_nm = false, acc_gbn = false
			let no_overlap = context.carryOverData.findIndex(co_elem => (context.yy === co_elem.yy) && (co_elem.item_usr === element.item_usr)) === -1

			if(searchData.item_nm === '')
				item_nm = true

			if(element.item_nm.toLowerCase().includes(searchData.item_nm.toLowerCase()))
				item_nm = true

			if(element.item_usr.includes(searchData.item_nm))
				item_nm = true

			if(element.spec.includes(searchData.item_nm))
				item_nm = true

			if(searchData.acc_gbn === '00000')
				acc_gbn = true
			else if(element.acc_gbn === searchData.acc_gbn)
				acc_gbn = true

			return item_nm && acc_gbn && no_overlap
		}), [context.productData, searchData.acc_gbn, searchData.item_nm])

	useEffect(() => {
		setSearchData({ ...searchData, item_nm: initialSearchData })
	}, [initialSearchData])

	const handleClick = () => {
		if(selectedProduct.length !== 0) {
			onSubmit(startIndex, selectedProduct)
			onHide()
		}
	}

	return (
		<Modal show={show} onHide={onHide} size="lg" centered
			   onEntered={() => setSelectedProduct([])}
			   onExit={() => context.setNewRow({
				   index: -1,
				   yy: '',
				   item: '',
				   item_usr: '',
				   item_nm: '',
				   spec: '',
				   unit: '',
				   unit_nm: '',
				   qty_bs: '',
			   })}>
			<Modal.Header closeButton>
				<Modal.Title>
					품목 추가
				</Modal.Title>
			</Modal.Header>

			{/* 모달의 내용물 */}
			<Modal.Body>
				<div className="card-body">
					<div className="d-flex">
						{/* 항목 이름 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">품명</label>
							<div className="input-group input-group-sm" style={{ width: '200px' }}>
								<input type="text" className="form-control" value={searchData.item_nm}
									   onChange={e => setSearchData({ ...searchData, item_nm: e.target.value })}/>
							</div>
						</div>
						{/* 계정구분 */}
						<div className="d-flex align-content-center mx-3">
							<label className="col-auto align-items-center my-1 mx-1">계정구분</label>
							{/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
							<select className="form-select form-select-sm" value={searchData.acc_gbn}
									onChange={e => {setSearchData({ ...searchData, acc_gbn: e.target.value })}}>
								{accCategory.map((category, index) =>
									<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
								)}
							</select>
						</div>
					</div>

					<div className="my-3 mx-3 scrollbar product-table-wrapper">
						<table className="table table-sm table-hover table-bordered table-condensed">
							<thead className="sticky-head">
								<tr>
									{columns.slice(mode === 1 ? 0 : 1, ).map((value, index) =>
										<th key={index} scope="col" style={{ textAlign: 'center', width: value.style.width }}>{value.name}</th>
									)}
								</tr>
							</thead>

							<tbody>
								<SelectedProductContext.Provider value={{selectedProduct, setSelectedProduct } } >
									<AddModalList onSubmit={onSubmit} startIndex={startIndex} data={data} mode={mode} />
								</SelectedProductContext.Provider>
							</tbody>
						</table>
					</div>
				</div>
			</Modal.Body>


			<Modal.Footer>
				{
					(mode === 1)
						?
						<div className="d-flex justify-content-end">
							<div className="d-flex align-content-center mx-3 ">
								<button className="btn btn-secondary my-3" onClick={handleClick}>
									입력
								</button>
							</div>
						</div>
						:
						null
				}
			</Modal.Footer>
		</Modal>
	)
}

interface CarryOverAddModalListProps {
	data: IProductData[],
	startIndex: number
	onSubmit: (startIndex: number, selectedData: IProductData[]) => void;
	mode: number;
}

const AddModalList = ({ data, startIndex, onSubmit, mode }: CarryOverAddModalListProps) => {
	return (
		<>
			{data.map((row, index) =>
				<AddModalRow key={index} onSubmit={onSubmit} startIndex={startIndex} row={row} mode={mode} />
			)}
		</>
	)
}

interface CarryOverAddModalRowProps {
	row: IProductData,
	startIndex: number,
	onSubmit: (startIndex: number, selectedData: IProductData[]) => void;
	mode: number;
}

const AddModalRow = ({ row, startIndex, onSubmit, mode }: CarryOverAddModalRowProps) => {

	const { selectedProduct, setSelectedProduct } = useContext(SelectedProductContext)

	const [ checked, setChecked ] = useState(false)

	useEffect(() => {
		if(checked) {
			if(selectedProduct.findIndex(elem => elem.item === row.item) === -1)
				setSelectedProduct(selectedProduct.concat(row))
		} else {
			if(selectedProduct.findIndex(elem => elem.item === row.item) !== -1)
				setSelectedProduct(selectedProduct.filter(elem => elem.item !== row.item))
		}
	}, [checked])

	const handleDoubleClick = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
		if(e.detail === 2) {
			onSubmit(startIndex, [row])
		}
	}

	return (
		<>
			<tr>
				{
					(mode === 1)
						?
						<th scope='row' style={{textAlign: 'center'}}>
							<input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
						</th>
						:
						null
				}
				<td style={{textAlign: 'center' }} onClick={handleDoubleClick}>{row.item_usr}</td>
				<td onClick={handleDoubleClick}>{row.item_nm}</td>
				<td onClick={() => onSubmit(startIndex, [row])}>{row.spec}</td>
				<td style={{ textAlign: 'center' }} onClick={handleDoubleClick}>{row.acc_nm}</td>
				<td onClick={handleDoubleClick}/>
			</tr>
		</>
	)
}

export default AddModal