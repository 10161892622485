import CheckTerms from '../../components/register/accept-terms/check-terms'
import React from 'react'

const UseTerms = () => {

	return (
		<>
			<CheckTerms />

			<footer className="border-top d-flex justify-content-center mx-2" style={{ marginTop: '150px' }}>
				<label className='text-secondary my-4' style={{ fontSize: '10pt', textAlign: 'center' }}>
					Copyright© Checking Co.,Ltd ALL Rights Reserved.&emsp;사업자 : 체킹주식회사&emsp;대표이사 : 하영길&emsp;사업자등록번호 : 826-87-00908&emsp;
					경상남도 창원시 성산구 중앙대로 33 대흥인터빌 823호&emsp;사용문의 Tel. 1600-0317&emsp;
					Hompage : <a href="http://checking.co.kr" style={{ color: 'inherit' }}>www.checking.co.kr</a>
				</label>
			</footer>
		</>
	)
}

export default UseTerms