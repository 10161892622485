import React from 'react'
import {
	IBeforeRecipe,
	IChildProduct,
	IClickedParent,
	IParentProduct,
	IRecipe
} from '../../../interfaces/production-manage/i-add-recipe'
import {IProductData} from '../../../interfaces/stock-manage/i-initial-stock'

/* 선택된 모품목 컨텍스트 */
export interface ISelectedParentProductContext {
	clickedParentProduct: IClickedParent
	setClickedParentProduct: (clickedParentProduct: IClickedParent) => void
	selectedParentProduct: IParentProduct[]
	setSelectedParentProduct: (selectedParentProduct: IParentProduct[]) => void
	selectAll: boolean
}

export const SelectedParentProductContext = React.createContext<ISelectedParentProductContext>({
	clickedParentProduct: { index: -2, item_p_nm: '', item_p: '', item_p_usr: '' },
	setClickedParentProduct: () => {},
	selectedParentProduct: [] as IParentProduct[],
	setSelectedParentProduct: () => {},
	selectAll: false
})

/* 선택된 자품목 컨텍스트 */
export interface ISelectedChildProductContext {
	selectedChildProduct: IChildProduct[];
	setSelectedChildProduct: (selectedChildProduct: IChildProduct[]) => void;
}

export const SelectedChildProductContext = React.createContext<ISelectedChildProductContext>({
	selectedChildProduct: [] as IChildProduct[],
	setSelectedChildProduct: () => {}
})

/* 레시피 전체와 그 관련 데이터를 담는 컨텍스트 */
export interface IRecipeContext {
	recipe: IRecipe[]
	beforeRecipe: IBeforeRecipe[]
	productData: IProductData[]
}

export const RecipeContext = React.createContext<IRecipeContext>({
	recipe: [] as IRecipe[],
	beforeRecipe: [] as IBeforeRecipe[],
	productData: [] as IProductData[]
})

export interface IAddModModalContext {
	setAddModMode: (addModMode: number) => void
	setShowAddModModal: (showAddModModal: boolean) => void
}

export const AddModModalContext = React.createContext<IAddModModalContext>({
	setAddModMode: () => {},
	setShowAddModModal: () => {}
})