import React, {useContext, useEffect, useState} from 'react'
import {IDetailData, ISummaryData} from '../../../interfaces/in-out-manage/i-goods-buying'
import {
	ISummaryContext,
	ClickedSummaryContext,
	BuyDataContext,
	IBuyDataContext,
	ISelectedCustomersContext, SelectedCustomersContext
} from "./goods-buy-sell-context";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";

/* 요약 테이블의 전체/개별 행 */
interface SummaryListProps {
	data: ISummaryData[],
}

interface SummaryRowProps {
	row: ISummaryData
	scContext: ISelectedCustomersContext
	csContext: ISummaryContext
	bdContext: IBuyDataContext
}

export const SummaryList = ({ data }: SummaryListProps) => {

	const scContext = useContext(SelectedCustomersContext)
	const csContext = useContext(ClickedSummaryContext)
	const bdContext = useContext(BuyDataContext)

	useEffect(() => {
		if(bdContext.selectAll) {
			scContext.setSelectedCustomers(data)
		} else {
			scContext.setSelectedCustomers([])
		}
	}, [bdContext.selectAll])

	return (
		<>
			{data.map((row, index) =>
				<SummaryRow key={index} row={row} scContext={scContext} csContext={csContext} bdContext={bdContext} />
			)}
		</>
	)
}

const SummaryRow = ({ row, scContext, csContext, bdContext }: SummaryRowProps) => {

	/* 행 클릭됐을 때 */
	const [ isClicked, setIsClicked ] = useState(false)
	/* 체크박스로 행을 선택했을 때 */
	const [ checked, setChecked ] = useState(false)

	/* 행을 클릭함 */
	const handleClickOnRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
		csContext.setClickedCustomer({ cus: row.cus, cus_usr: row.cus_usr, cus_nm: row.cus_nm, ymd: row.ymd })

		if(e.detail === 2) {
		    bdContext.setAddModMode(2)
			bdContext.setShowAddModModal(true)
		}
	}

	/* 고객 선택/해제 로직 */
	useEffect(()=> {
		if(checked) {
			if(scContext.selectedCustomers.findIndex(element => element.cus === row.cus && element.ymd === row.ymd) === -1)
				scContext.setSelectedCustomers(scContext.selectedCustomers.concat({
					ymd: row.ymd,
					cus: row.cus,
					cus_usr: row.cus_usr,
					cus_nm: row.cus_nm
				}))
		} else {
			if(scContext.selectedCustomers.findIndex(element => element.cus === row.cus && element.ymd === row.ymd) !== -1)
				scContext.setSelectedCustomers(scContext.selectedCustomers.filter(element => !(element.cus === row.cus && element.ymd === row.ymd)))
		}
	}, [checked])

	/* 혹시 모르니 선택된 고객이 없으면 체크박스를 전부 풀음 */
	useEffect(() => {
		if(scContext.selectedCustomers.length === 0) {
			setChecked(false)
		}
	}, [scContext.selectedCustomers])

	useEffect(() => {
		if(row.cus_usr === csContext.clickedCustomer.cus_usr && row.ymd === csContext.clickedCustomer.ymd)
			setIsClicked(true)
		else
			setIsClicked(false)

	}, [row.cus_usr, csContext.clickedCustomer])

	/* 전체 선택 시 전부 선택 */
	useEffect(() => {
		if(bdContext.selectAll)
			setChecked(true)
		else
			setChecked(false)
	}, [bdContext.selectAll])

	return (
		<tr  style={isClicked ? { backgroundColor: '#eeeeee' } : {}}>
			<th scope="row" style={{ textAlign: 'center' }}>
				<input type="checkbox" className="form-check-input" checked={checked} onChange={() => setChecked(!checked)} />
			</th>
			<td onClick={handleClickOnRow} style={{ textAlign: 'center' }}>{row.ymd.slice(0, 4) + "-" + row.ymd.slice(4, 6) + "-" + row.ymd.slice(6)}</td>
			<td onClick={handleClickOnRow}>{row.cus_nm}</td>
			<td onClick={handleClickOnRow}>{row.item_nm}</td>
		</tr>
	)
}

/* 상세 테이블의 전체/개별 행 */
interface DetailListProps {
	data: IDetailData[]
}

interface DetailRowProps {
	dec: number
	row: IDetailData
}

export const DetailList = ({ data }: DetailListProps) => {

	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((row, index) =>
				<DetailRow key={index} row={row} dec={dec} />
			)}
		</>
	)
}

const DetailRow = ({ row, dec }: DetailRowProps) => {
	return (
		<tr>
			<td>{row.item_nm}</td>
			<td>{row.spec}</td>
			<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
			<td style={{ textAlign: 'end' }}>{convertReadable(row.qty, dec)}</td>
			<td>{row.bigo}</td>
		</tr>
	)
}