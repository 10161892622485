import React, {useEffect, useMemo, useRef, useState} from 'react'
import styled from "styled-components";
import {isSaupNo} from "../../../lib/util-func";
import client from "../../../axios";
import InfoModal from "../../common-modals/info-modal";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
`

function typedKeys<T>(o: T): (keyof T)[] {
	// @ts-ignore
	return Object.keys(o) as (keyof T)[];
}


const RegisterForm = () => {

	const defaultError = {
		corp_nm: false,
		user_nm: false,
		tel: false,
		id: false,
		check_dup: false,
		pwd: false,
		pwd_agn: false
	}

	const registerFormContents = [
		{ name: '회사명', ref: useRef<HTMLInputElement>(null) },
		{ name: '사용자명', ref: useRef<HTMLInputElement>(null) },
		{ name: '사용자 전화번호', ref: useRef<HTMLInputElement>(null) },
		{ name: '아이디', ref: useRef<HTMLInputElement>(null) },
		{ name: '비밀번호', ref: useRef<HTMLInputElement>(null) },
		{ name: '비밀번호 확인', ref: useRef<HTMLInputElement>(null) },
	]

	/* ref 반환 */
	const getRef = (index: number) => {
		return registerFormContents[index].ref
	}

	/* 네비게이터 */
	const navigate = useNavigate()

	/* 각 항목들의 에러 저장 */
	const [errorOnInput, setErrorOnInput] = useState(defaultError)
	/* 에러 메시지 */
	const [inputErrorContent, setInputErrorContent] = useState('ddd')
	/* 에러가 존재하는지 확인 */
	const isOkay = useMemo(() => {
		let flag = true
		typedKeys(errorOnInput).forEach(element => {
			if(errorOnInput[element])
				flag = false
		})
		return flag
	}, [errorOnInput])

	const [checkedDup, setCheckedDup] = useState(false)

	/* 수행 성공 모달 변수 */
	const [ showSuccessModal, setShowSuccessModal ] = useState(false)
	const [ successMessage, setSuccessMessage ] = useState('')
	const [ successTitle, setSuccessTitle ] = useState('')

	/* 변경 사항 저장 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)

	/* 제출 버튼 클릭 */
	const handleClickOnSubmit = async () => {
		setErrorOnInput(defaultError)

		if(getRef(0).current?.value === '' || getRef(0).current === null) {
			setInputErrorContent('회사명을 입력하세요.')
			setErrorOnInput({ ...errorOnInput, corp_nm: true })
		} else if(getRef(1).current?.value === '' || getRef(1).current === null) {
			setInputErrorContent('사용자명을 입력하세요.')
			setErrorOnInput({ ...errorOnInput, user_nm: true })
		} else if(getRef(2).current?.value === '' || getRef(2).current === null) {
			setInputErrorContent('전화번호를 입력하세요.')
			setErrorOnInput({ ...errorOnInput, tel: true })
		} else if(getRef(3).current?.value === '' || getRef(3).current === null) {
			setInputErrorContent('아이디를 입력하세요.')
			setErrorOnInput({ ...errorOnInput, id: true })
		} else if((getRef(4).current?.value as string).length < 5 || (getRef(4).current?.value as string).length > 10 || getRef(4).current === null) {
			setInputErrorContent('비밀번호를 5자 이상 10자 이하로 설정해주십시오.')
			setErrorOnInput({ ...errorOnInput, pwd: true })
		} else if(getRef(5).current?.value === '' || getRef(5).current === null) {
			setInputErrorContent('비밀번호 확인란을 입력하세요.')
			setErrorOnInput({ ...errorOnInput, pwd: true })
		} else if(getRef(4).current?.value !== getRef(5).current?.value) {
			setInputErrorContent('비밀번호 확인란이 비밀번호와 일치하지 않습니다.')
			setErrorOnInput({ ...errorOnInput, pwd_agn: true })
		} else {
			setShowSaveModal(true)
		}
	}

	/* 입력란에 엔터 확인 */
	const handleEnterOnInput = async (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		/* 엔터키일 때에만 작동, 마지막 입력란이면 제출 */
		if(e.key === 'Enter') {
			if(index === registerFormContents.length - 1) {
				await handleClickOnSubmit()
			} else {
				registerFormContents[index + 1].ref.current?.focus()
			}
		}
	}

	/* 중복확인 */
	const handleClickOnDupBtn = async () => {
		if(getRef(3).current !== null) {
			let id = getRef(3).current?.value

			if((id as string).length < 5 || (id as string).length > 12) {
				setInputErrorContent('아이디를 5자 이상 12자 이하로 설정해주십시오.')
				setErrorOnInput({ ...errorOnInput, check_dup: true })
			} else {
				try {
					const response = await client.get('/api/auth/check-id-dup', {
						params: {
							id
						}
					})

					if(response.data.result === 'dup') {
						setInputErrorContent('이미 존재하는 아이디입니다.')
						setErrorOnInput({ ...errorOnInput, check_dup: true })
					} else {
						setCheckedDup(true)
						setErrorOnInput({ ...errorOnInput, check_dup: false })
						getRef(4).current?.focus()
					}
				} catch (e: any) {

				}
			}
		}
	}

	const handleSave = async () => {
		setShowSaveModal(false)

		try {
			const response = await client.post('/api/register/create-new-account', {
				corp_nm: getRef(0).current?.value,
				user_nm: getRef(1).current?.value,
				tel: getRef(2).current?.value,
				id: getRef(3).current?.value,
				pwd: getRef(4).current?.value,
			})

			if(response.data.result === 'success') {
				setShowSuccessModal(true)
				setSuccessTitle('회원가입 성공')
				setSuccessMessage('성공적으로 회원가입을 완료하였습니다.')
			}
		} catch (e: any) {

		}
	}

	return (
		<Container>
			<div className="container">
				<div className="row justify-content-center align-content-center">
					<div className="col-md-11 col-lg-7 col-xl-5 col-10">
						<div className="card shadow">
							<div className="card-header">
								<h5 className="my-1">회원가입</h5>
							</div>
							<div className="card-body">
								{registerFormContents.map((content, index) =>
									<div key={index} className="input-group input-group-sm my-3">
										<label className="col-sm-3 col-12 text-sm-end me-3 regist-label my-auto">{content.name}</label>
										<input ref={content.ref} type={index === 4 || index === 5 ? "password" : "text"} className="form-control col-6"
											   onKeyDown={e => handleEnterOnInput(e, index)} disabled={checkedDup && index === 3} autoComplete="off"
											   readOnly onFocus={e => e.target.removeAttribute("readonly")}
										/>
										<button className={`btn ${checkedDup ? 'btn-outline-success' : 'btn-outline-secondary'}`}
												onClick={handleClickOnDupBtn}
												hidden={content.name !== '아이디'} disabled={checkedDup}>
											{checkedDup ? '확인됨' : '중복확인'}
										</button>
									</div>
								)}
								<div className="d-flex mb-3 justify-content-end" >
									<label className="text-danger" style={{ fontSize: '13px' }} hidden={isOkay}>{inputErrorContent}</label>
								</div>
								<div className="d-flex justify-content-end">
									<button className="btn btn-secondary btn-sm mx-2" onClick={() => navigate(-1)}>
										돌아가기
									</button>
									<button className="btn btn-primary btn-sm" onClick={handleClickOnSubmit}>
										가입하기
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<InfoModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} title={successTitle}
					   message={successMessage} onButtonClick={() => {
				setShowSuccessModal(false)
				navigate('/login')
			}} />

			{/* 정보 삭제 모달 */}
			<Modal show={showSaveModal} onHide={() => setShowSaveModal(false)} centered>
				<Modal.Body>
					입력하신 정보를 토대로 회원가입을 진행하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowSaveModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSave}>회원가입</button>
				</Modal.Footer>
			</Modal>

		</Container>
	)
}

export default RegisterForm