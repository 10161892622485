import React, {useState, useEffect, useContext} from 'react'
import {IBuyData} from "../../../interfaces/in-out-manage/i-goods-buying";
import {INewRowContext, ISelectedBuyContext, NewRowContext, SelectedBuyContext} from "./add-mod-modal"
import ProductModal from "./product-modal";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";

interface AddModModalListProps {
	data: IBuyData[]
}

interface AddModModalRowProps {
	row: IBuyData
	focusedIndex: number
	setFocusedIndex: (index: number) => void
	selectedContext: ISelectedBuyContext
	context: INewRowContext
	dec: number
}

export const AddModModalList = ({ data }: AddModModalListProps) => {


	const selectedContext = useContext(SelectedBuyContext)
	const newRowContext = useContext(NewRowContext)

	const [ focusedIndex, setFocusedIndex ] = useState(-1)

	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)


	return (
		<>
			{data.map((element, index) =>
				<AddModModalRow key={index} row={element} context={newRowContext} selectedContext={selectedContext}
								focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} dec={dec} />
			)}
		</>
	)
}

const AddModModalRow = ({ row, focusedIndex, setFocusedIndex, context, selectedContext, dec }: AddModModalRowProps) => {

	const [ focusMode, setFocusMode ] = useState({
		item_nm: false,
		qty: false,
		bigo: false
	})
	const [ initialProductData, setInitialProductData ] = useState('')
	const [ checked, setChecked ] = useState(false)

	const [ showProductModal, setShowProductModal ] = useState(false)

	useEffect(() => {
		if(row.index === focusedIndex) {
			if(!focusMode.item_nm && !focusMode.qty && !focusMode.bigo) {
				setFocusMode({ ...focusMode, item_nm: true})
			}
		}
	}, [focusedIndex])

	useEffect(() => {
		if(row.index !== -1) {
			if (checked) {
				if(selectedContext.selectedBuyData.findIndex(elem => elem.ymd === row.ymd && elem.item === row.item) === -1) {
					selectedContext.setSelectedBuyData(selectedContext.selectedBuyData.concat(row))
				}
			} else {
				if(selectedContext.selectedBuyData.findIndex(elem => elem.ymd === row.ymd && elem.item === row.item) !== -1) {
					selectedContext.setSelectedBuyData(selectedContext.selectedBuyData.filter(elem => !(elem.ymd === row.ymd && elem.item === row.item)))
				}
			}
		}
	},[row.index, checked])

	useEffect(() => {
		if(selectedContext.selectedBuyData.length === 0)
			setChecked(false)
	}, [selectedContext.selectedBuyData])

	/* 테이블 셀 */
	const handleClickOnItemNmCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: true, qty: false, bigo: false})
	}
	const handleClickOnQtyCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: false, qty: true, bigo: false})
	}
	const handleClickOnBigoCell = () => {
		setFocusedIndex(row.index)
		setFocusMode({ item_nm: false, qty: false, bigo: true})
	}
	const handleBlurOnItemNmCell = () => {
		setFocusMode({ ...focusMode, item_nm: false })
	}
	const handleBlurOnQtyCell = () => {
		setFocusMode({ ...focusMode, qty: false })
	}
	const handleBlurOnBigoCell = () => {
		setFocusMode({ ...focusMode, bigo: false })
	}

	/* item_nm */
	const handleChangeOnItemNm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if(index === -1) {
			context.setNewRow({ ...context.newRow, item_usr: '', item_nm: e.target.value })
		} else {
			context.setBuyData(context.buyData.map(element => {
				return (index === element.index)
					?
					{ ...element, item_usr: '', item_nm: e.target.value, item: '' }
					:
					element
			}))
		}
	}
	const handleEnterOnItemNm = (e: any, index: number) => {
		if(e.key === 'Enter' && row.item_usr === '') {
			const filteredProductData = context.productData.filter(pd_elem =>
				pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))

			if((e.target.value.length <= 1) || filteredProductData.length === 0) {
				/* 입력란의 문자 개수가 1개 이하이거나 입력란에 따라 찾을 수 없다면 */
				setInitialProductData('')
				setShowProductModal(true)
			} else if(filteredProductData.length >= 2) {
				/* 입력란의 검색 결과가 2개 이상이라면 */
				setInitialProductData(e.target.value)
				setShowProductModal(true)
			} else {
				const addingData = filteredProductData[0]
				const isOverlap = context.buyData
					.findIndex(co_elem => co_elem.item === addingData.item && context.ymd === co_elem.ymd && co_elem.item_usr !== '') !== -1

				if(isOverlap) {
					context.setFailedTitle('입력 실패')
					context.setFailedMessage('이미 중복되는 품목이 있습니다.')
					context.setShowFailedModal(true)
					setInitialProductData('')

					if(index === -1) {
						context.setNewRow({
							index: -1,
							corp: '',
							ymd: '',
							cus: row.cus,
							cus_usr: row.cus_usr,
							cus_nm: row.cus_nm,
							item: '',
							item_usr: '',
							item_nm: '',
							spec: '',
							unit: '',
							unit_nm: '',
							qty: '',
							bigo: ''
						})
					} else {
						context.setBuyData(context.buyData.map(elem =>
							(elem.index === row.index)
								?
								{ ...elem, item: '', item_usr: '', item_nm: '' }
								:
								elem
						))
					}
				} else {
					context.setIsChanged(true)
					if(index === -1) {
						const newIndex = getNewIndex()
						context.setBuyData(context.buyData.concat({
							index: newIndex,
							corp: '',
							ymd: context.ymd,
							cus: row.cus,
							cus_usr: row.cus_usr,
							cus_nm: row.cus_nm,
							item: addingData.item,
							item_usr: addingData.item_usr,
							item_nm: addingData.item_nm,
							spec: addingData.spec,
							unit: addingData.unit,
							unit_nm: addingData.unit_nm,
							qty: row.qty,
							bigo: row.bigo
						}))
						context.setBeforeBuyData(context.beforeBuyData.concat({
							index: newIndex,
							ymd: context.ymd,
							cus: '',
							item: ''
						}))
						context.setNewRow({
							index: -1,
							corp: '',
							ymd: '',
							cus: row.cus,
							cus_usr: row.cus_usr,
							cus_nm: row.cus_nm,
							item: '',
							item_usr: '',
							item_nm: '',
							spec: '',
							unit: '',
							unit_nm: '',
							qty: '',
							bigo: ''
						})
						setFocusedIndex(newIndex)
						setFocusMode({ item_nm: false, qty: true, bigo: false })
					} else {
						context.setBuyData(context.buyData.map(elem =>
							(elem.index === index)
								?
								{
									...elem,
									item: addingData.item,
									item_usr: addingData.item_usr,
									item_nm: addingData.item_nm,
									spec: addingData.spec,
									unit: addingData.unit,
									unit_nm: addingData.unit_nm
								}
								:
								elem
						))
						setFocusMode({ item_nm: false, qty: true, bigo: false })
					}
					setInitialProductData('')
				}
			}
			e.preventDefault()
		} else if(e.key === 'Enter' && row.item_usr !== '') {
			setFocusMode({ item_nm: false, qty: true, bigo: false })
			e.preventDefault()
		}
	}

	/* qty */
	const handleChangeOnQty = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		context.setIsChanged(true)
		if(index === -1 ) {
			context.setNewRow({ ...context.newRow, qty: e.target.value })
		} else {
			context.setBuyData(context.buyData.map(element => {
				return (element.index === index) ?
					{ ...element, qty: e.target.value }
					:
					element
			}))
		}
	}
	const handleBlurOnQty = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
		const modifiedQtyBs = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, '')).toString()

		if(index === -1) {
			context.setNewRow(
				{
					...context.newRow, qty:
						(e.target.value === '')
							?
							''
							:
							modifiedQtyBs
				})
		} else {
			context.setBuyData(context.buyData.map(element => {
				return (element.index === index)
					?
					{
						...element, qty:
							(e.target.value === '')
								?
								''
								:
								modifiedQtyBs
					}
					:
					element
			}))
		}
	}
	const handleEnterOnQty = (e: any) => {
		if(e.key === 'Enter') {
			setFocusMode({ item_nm: false, qty: false, bigo: true })
		}
	}

	/* bigo */
	const handleChangeOnBigo = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		context.setIsChanged(true)
		if(index === -1 ) {
			context.setNewRow({ ...context.newRow, bigo: e.target.value })
		} else {
			context.setBuyData(context.buyData.map(element => {
				return (element.index === index) ?
					{ ...element, bigo: e.target.value }
					:
					element
			}))
		}
	}
	const handleEnterOnBigo = (e: any) => {
		if(e.key === 'Enter') {
			setFocusedIndex(getNextFocusingCell())
			setFocusMode({ item_nm: false, qty: false, bigo: false })
		}
	}

	/* 다음 포커싱 행 찾기 */
	const getNextFocusingCell = () => {
		let flag: boolean = false, currentIndex: number = row.index, nextIndex: number = 0

		if(currentIndex === -1) return -1

		for(let i = currentIndex; i < context.buyData.length; i++) {
			if(context.buyData[i].ymd === context.ymd && context.buyData[i].index > currentIndex && context.buyData[i].cus === row.cus) {
				flag = true
				nextIndex = i
				break
			}
		}

		return flag ? nextIndex : -1
	}

	/* 품목 모달에서 받아온 데이터를 추가 */
	const handleSubmit = (startIndex: number, selectedData: IProductData[]) => {
		const newIndex = getNewIndex()
		const reducedSelectedData =
			selectedData.reduce((previousValue, currentValue, index) => {
				return previousValue.concat({
					corp: '',
					index: newIndex + index,
					ymd: context.ymd,
					cus: row.cus,
					cus_usr: row.cus_usr,
					cus_nm: row.cus_nm,
					item: currentValue.item,
					item_usr: currentValue.item_usr,
					item_nm: currentValue.item_nm,
					spec: currentValue.spec,
					unit: currentValue.unit,
					unit_nm: currentValue.unit_nm,
					qty: '',
					bigo: '',
				})
			}, [] as IBuyData[])
		if(row.index === -1) {
			context.setBuyData(context.buyData.concat(reducedSelectedData))
			context.setBeforeBuyData(context.beforeBuyData.concat(
				reducedSelectedData.map(rsd => ({
					index: rsd.index,
					ymd: rsd.ymd,
					cus: '',
					item: ''
				}))
			))
			setFocusedIndex(newIndex)
		} else {
			const newData = reducedSelectedData[0]
			context.setBuyData(context.buyData.map(elem =>
				(startIndex === elem.index)
					?
					{
						...elem,
						item: newData.item,
						item_usr: newData.item_usr,
						item_nm: newData.item_nm,
						spec: newData.spec,
						unit: newData.unit,
						unit_nm: newData.unit_nm
					}
					:
					elem
			))
		}
		context.setIsChanged(true)
		setShowProductModal(false)
		setTimeout(() => {
			setFocusMode({ item_nm: false, qty: true, bigo: false })
		}, 200)
	}

	const getNewIndex = () => {
		return context.buyData.length
	}

	return (
		<>
			<tr>
				<th scope='row' style={{ textAlign: 'center'}}>
					<input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
				</th>
				<td style={{ textAlign: 'center' }}>{row.item_usr}</td>
				<td
					onClick={handleClickOnItemNmCell}
					onBlur={handleBlurOnItemNmCell}>
					{
						focusMode.item_nm
							?
							<input type="text" className="form-control form-control-sm shadow-none"
								   value={row.item_nm} autoFocus
								   onChange={e => handleChangeOnItemNm(e, row.index)}
								   onKeyDown={e => handleEnterOnItemNm(e, row.index)} />
							:
							row.item_nm
					}
				</td>
				<td>{row.spec}</td>
				<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
				<td style={{ textAlign: 'end' }}
					onClick={handleClickOnQtyCell}
					onBlur={handleBlurOnQtyCell}>
					{
						focusMode.qty
							?
							<input type="text" className="form-control form-control-sm shadow-none" style={{textAlign: "end"}}
								   value={row.qty} autoFocus
								   onKeyDown={handleEnterOnQty}
								   onChange={e => handleChangeOnQty(e, row.index)}
								   onBlur={e => handleBlurOnQty(e, row.index)}
							/>
							:
							convertReadable(row.qty, dec)
					}
				</td>
				<td
					onClick={handleClickOnBigoCell}
					onBlur={handleBlurOnBigoCell}>
					{
						focusMode.bigo
							?
							<input type="text" className="form-control form-control-sm shadow-none"
								   value={row.bigo} autoFocus
								   onKeyDown={handleEnterOnBigo}
								   onChange={e => handleChangeOnBigo(e, row.index)}
							/>
							:
							row.bigo
					}
				</td>
			</tr>

			<ProductModal show={showProductModal} onHide={() => setShowProductModal(false)} onSubmit={handleSubmit} context={context}
						  initialSearchData={initialProductData} startIndex={row.index} mode={row.index === -1 ? 1 : 0} />
		</>
	)
}