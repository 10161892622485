import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Index from '../components'
import Navbar from '../components/navbar/navbar'
import client from '../axios'

const Main = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        client.get('/api/auth/verify')
            .then(res => {
                if (res.data.result === 'failed' || res.data.result === 'expired')
                    navigate('/login')
                else
                    setIsLoggedIn(true)
            })
            .catch(e => {

            })
    }, [navigate])

    return isLoggedIn ? (
        <>
            <Navbar />
            <Index />
        </>
    ) : <div />
}

export default Main