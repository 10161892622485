import {combineReducers} from 'redux'

/* reducers */
import currentPage from './current-page'
import button from './button'
import timer from './timer'
import changeMenu from './change-menu'
import dec from './dec'

/* 리듀서 합치기 */
const rootReducer = combineReducers({
    currentPage,
    buttonActions: button,
    timer,
    changeMenu,
    dec
})

export default rootReducer

export type RootReducer = ReturnType<typeof rootReducer>