/* 기준코드 테이블의 열 하나 */
import React, { useState } from "react";
import { IStandardCode } from "./standard-code";
import { useDispatch } from 'react-redux'
import * as changeMenuActions from '../../../../redux/actions/change-menu'

interface CodeRowProps {
    codeRow: IStandardCode;
    toggleUseGbn: (big: string, sml: string) => void;
    onChangeSmlNm: (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => void;
    onChangeBigo: (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => void;
}

interface CodeListProps {
    data: IStandardCode[];
    toggleUseGbn: (big: string, sml: string) => void;
    codeBig: string;
    onChangeSmlNm: (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => void;
    onChangeBigo: (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => void;
}

const CodeRow = ({ codeRow, toggleUseGbn, onChangeBigo, onChangeSmlNm }: CodeRowProps) => {

	/* 열의 현재 상태 (레이블/인풋) */
	const [ smlNmMode, setSmlNmMode ] = useState('label')
	const [ bigoMode, setBigoMode ] = useState('label')

    /* dispatch 인스턴스 생성 */
    const dispatch = useDispatch()

    /* sml_nm, bigo의 click, blur */
    const handleClickSmlNm = () => {
        if (smlNmMode === 'label' && codeRow.use_gbn === '1' && codeRow.upd_gbn === '1')
            setSmlNmMode('input')
    }
    const handleBlurSmlNm = () => {
        if (smlNmMode === 'input')
            setSmlNmMode('label')
    }
    const handleClickBigo = () => {
        if (bigoMode === 'label' && codeRow.use_gbn === '1' && codeRow.upd_gbn === '1')
            setBigoMode('input')
    }
    const handleBlurBigo = () => {
        if (bigoMode === 'input')
            setBigoMode('label')
    }

    /* 바뀌면 changed on */
    const handleChangeOnSmlNm = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeMenuActions.change())
        onChangeSmlNm(e, codeRow.big, codeRow.sml)
    }
    const handleChangeOnBigo = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeMenuActions.change())
        onChangeBigo(e, codeRow.big, codeRow.sml)
    }

    return (
        <tr className={`${(codeRow.use_gbn === '0') ? "row-disabled" : (codeRow.upd_gbn === '0' ? "text-danger" : "")}`}>
            <th scope="row">
                <select defaultValue={codeRow.use_gbn} onChange={() => toggleUseGbn(codeRow.big, codeRow.sml)}>
                    <option value="1">⬤</option>
                    <option value="0">✕</option>
                </select>
            </th>
            <td className="text-center">{codeRow.sml}</td>
            <td onClick={handleClickSmlNm} onBlur={handleBlurSmlNm}>
                {
                    smlNmMode === 'label'
                        ?
                        codeRow.sml_nm
                        :
                        <input className="form-control form-control-sm shadow-none" type="text" autoFocus value={codeRow.sml_nm}
                               onChange={handleChangeOnSmlNm} />
                }
            </td>
            <td
                onClick={handleClickBigo}
                onBlur={handleBlurBigo}
            >
                {
                    bigoMode === 'label'
                        ?
                        codeRow.bigo
                        :
                        <input className="form-control form-control-sm shadow-none" type="text" autoFocus value={codeRow.bigo}
                            onChange={handleChangeOnBigo} />
                }
            </td>
            <td />
        </tr>
    )
}

/* 기준코드 테이블의 열의 집합 */
const CodeList = ({ data, toggleUseGbn, codeBig, onChangeBigo, onChangeSmlNm }: CodeListProps) => {
    return (
        <>
            {data
                .filter(codeRow => codeRow.big === codeBig)
                .map(codeRow => <CodeRow key={`${codeRow.big}-${codeRow.sml}`} codeRow={codeRow} toggleUseGbn={toggleUseGbn}
                                         onChangeBigo={onChangeBigo} onChangeSmlNm={onChangeSmlNm} />)}
        </>
    )
}

export default CodeList