import React, {useContext, useEffect, useState} from "react";
import {
	ProdDailyDetailListProps,
	ProdDailyDetailRowProps,
	ProdDailySummaryListProps,
	ProdDailySummaryRowProps
} from "../../../interfaces/production-manage/i-prod-daily";
import {ClickedItemContext, ProdDailyDetailContext} from "./prod-daily-context";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as changeMenuActions from '../../../../redux/actions/change-menu'
import {convertReadable} from "../../../../lib/converting-func";
import {RootReducer} from "../../../../redux/reducers";

export const ProdDailySummaryList = ({ data }: ProdDailySummaryListProps) => {

	const clickedItemContext = useContext(ClickedItemContext)
	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((row, index) =>
				<ProdDailySummaryRow key={index} row={row} ciContext={clickedItemContext} dec={dec} />
			)}
		</>
	)
}

export const ProdDailySummaryRow = ({ row, ciContext, dec }: ProdDailySummaryRowProps) => {

	const handleClickOnRow = () => {
		ciContext.setClickedItem(row.item)
	}

	return (
		<tr onClick={handleClickOnRow} style={row.item === ciContext.clickedItem ? { backgroundColor: '#eeeeee' } : {}}>
			<td style={{ textAlign: 'center' }}>{row.item_usr}</td>
			<td>{row.item_nm}</td>
			<td style={{ textAlign: 'end' }}>{convertReadable(row.qty, dec)}</td>
			<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
		</tr>
	)
}

export const ProdDailyDetailList = ({ data, lossRateCheck }: ProdDailyDetailListProps) => {

	const pddContext = useContext(ProdDailyDetailContext)
	const [ focusedIndex, setFocusedIndex ] = useState(0)

	const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

	return (
		<>
			{data.map((row, index) =>
				<ProdDailyDetailRow key={index} row={row} lossRateCheck={lossRateCheck} pddContext={pddContext}
									focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} dec={dec}/>
			)}
		</>
	)
}

export const ProdDailyDetailRow = ({ row, lossRateCheck, pddContext, focusedIndex, setFocusedIndex, dec }: ProdDailyDetailRowProps) => {

	/* 포커싱된 셀 */
	const [ focusMode, setFocusMode ] = useState({ loss_rate: false })
	/* dispatch 인스턴스 생성 */
	const dispatch = useDispatch()

	/* loss율 셀 클릭 시 전환 */
	const handleClickOnLossRateCell = () => {
		if(lossRateCheck)
			setFocusMode({ loss_rate: true })
	}

	/* 포커스 해제 시 원래대로 돌아가기 */
	const handleBlurOnLossRateCell = () => {
		setFocusedIndex(-1)
		setFocusMode({ loss_rate: false })
	}

	const handleChangeOnLossRate = (e: React.ChangeEvent<HTMLInputElement>) => {
		pddContext.setProdDailyDetail(pddContext.prodDailyDetail.map(element =>
			({
				...element,
				loss_rate: row.index === element.index ? e.target.value : element.loss_rate
			})
		))
		dispatch(changeMenuActions.change())
	}

	const handleBlurOnLossRate = (e: React.FocusEvent<HTMLInputElement>) => {
		const tmp = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, ''))
		const modifiedQtyBs = isNaN(tmp) ? '' : tmp.toString()

		pddContext.setProdDailyDetail(pddContext.prodDailyDetail.map(element =>
			element.index === row.index
				?
				({
					...element,
					loss_rate:
						(e.target.value === '')
							?
							''
							:
							modifiedQtyBs
				})
				:
				element
		))
	}

	const handleEnterOnLossRate = (e: any) => {
		if(e.key === 'Enter') {
			setFocusedIndex((row.index + 1) % 5)
		}
	}

	useEffect(() => {
		if(row.index === focusedIndex)
			setFocusMode({ loss_rate: true })
	}, [focusedIndex])

	/* lossRateCheck가 false일 때 모든 포커싱 해제 */
	useEffect(() => {
		if(!lossRateCheck)
			setFocusMode({ loss_rate: false })
	}, [lossRateCheck])


	return (
		<tr>
			<td>{row.item_nm}</td>
			<td style={{ textAlign: 'end' }}>{convertReadable(row.qty, dec)}</td>
			<td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
			<td style={{ textAlign: 'end' }}
				onClick={handleClickOnLossRateCell}
				onBlur={handleBlurOnLossRateCell}
			>
				{
					focusMode.loss_rate && lossRateCheck
						?
						<input type="text" className="form-control form-control-sm shadow-none text-end"
							   autoFocus value={row.loss_rate}
							   onChange={handleChangeOnLossRate}
							   onBlur={handleBlurOnLossRate}
							   onKeyDown={handleEnterOnLossRate}
						/>
						:
						convertReadable(row.loss_rate, dec) + '%'
				}
			</td>
		</tr>
	)
}