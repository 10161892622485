import {INameValue} from "../components/interfaces/common"

export const accCategory = [
    { name: '전체', value: '00000' },
    { name: '상품', value: '14600' },
    { name: '원재료', value: '15300' },
    { name: '부재료', value: '16200' },
    { name: '제품', value: '15000' },
    { name: '재공품', value: '16900' },
    { name: '소모품', value: '17500' }
] as INameValue[]

export const annCategory = [
    { name: '사내', value: '1' },
    { name: '가맹점', value: '9' }
] as INameValue[]