import {
	IBeforeBuyData,
	IBuyData,
	ICusData,
	IYmdCus
} from "../../../interfaces/in-out-manage/i-goods-buying";
import React from 'react'

export interface ISummaryContext {
	clickedCustomer: IYmdCus;
	setClickedCustomer: (selectedCustomer: IYmdCus) => void;

}
export const ClickedSummaryContext = React.createContext<ISummaryContext>({
	clickedCustomer:  { cus: '', cus_usr: '', cus_nm: '', ymd: '' },
	setClickedCustomer: () => {},
})

export interface ISelectedCustomersContext {
	selectedCustomers: IYmdCus[];
	setSelectedCustomers: (selectedSummary: IYmdCus[]) => void;
}

export const SelectedCustomersContext = React.createContext<ISelectedCustomersContext>({
	selectedCustomers: [] as IYmdCus[],
	setSelectedCustomers: () => {}
})

export interface IBuyDataContext {
	buyData: IBuyData[];
	setBuyData: (buyData: IBuyData[]) => void;
	beforeBuyData: IBeforeBuyData[];
	setBeforeBuyData: (beforeBuyData: IBeforeBuyData[]) => void;
	addModMode: number;
	setAddModMode: (addModMode: number) => void;
	showAddModModal: boolean;
	setShowAddModModal: (showAddModModal: boolean) => void;
	cusData: ICusData[];
	selectAll: boolean

}

export const BuyDataContext = React.createContext<IBuyDataContext>({
	buyData: [] as IBuyData[],
	setBuyData: () => {},
	beforeBuyData: [] as IBeforeBuyData[],
	setBeforeBuyData: () => {},
	addModMode: 0,
	setAddModMode: () => {},
	showAddModModal: false,
	setShowAddModModal: () => {},
	cusData: [] as ICusData[],
	selectAll: false,
})