import React, {useCallback, useState} from 'react'
import PasswordChange from "./password-change";
import button from "../../../redux/reducers/button";
import DecChange from "./dec-change";

const Settings = () => {

	const btnMenu = [
		{ name: '비밀번호 변경', val: 'password-change' },
		{ name: '소수점 표시 설정', val: 'dec-change' }
	]

	/* password의 메뉴 */
	const passwordSettings = [
		{ name: "기존 비밀번호" },
		{ name: "새 비밀번호" },
		{ name: "새 비밀번호 확인" },
	]

	/* 현재 보이는 메뉴 */
	const [ shownComponent, setShownComponent ] = useState('password-change')

	const ShownSettings = useCallback(() => {
		if(shownComponent === 'password-change')
			return <PasswordChange />
		else if(shownComponent === 'dec-change')
			return <DecChange />
		else
			return <></>
	}, [shownComponent])


	return (
		<>
			<div className="ms-4 mt-3">
				{/* 버튼 그룹 */}
				<div className="btn-group btn-group-sm" role="group">
					{btnMenu.map((content, index) =>
						<button key={index}
								className={`btn ${content.val === shownComponent ? 'btn-secondary' : 'btn-outline-secondary'}`}
								onClick={() => setShownComponent(content.val)}
						>
							{content.name}
						</button>
					)}
				</div>

				<ShownSettings />
			</div>
		</>
	)
}

export default Settings