/* 웬만하면 접어두기 */

import React, {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Dropdown, Modal} from 'react-bootstrap'
import DaumPostcode, {Address} from 'react-daum-postcode'
import {RootReducer} from '../../../../redux/reducers'
import * as buttonActions from '../../../../redux/actions/button'
import client from '../../../../axios'
import {convertPhoneNoReadable} from '../../../../lib/converting-func'
import './style.css'

/* 윤년 확인 */
const isLeap = (year: number) => {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
}

const convertDateReadable = (date: string) => {
    return date.length === 0 ? '' : date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6)
}
const addHyphenOnSaupNo = (serial: string) => {
    return serial.length === 0 ? '' : serial.slice(0, 3) + '-' + serial.slice(3, 5) + '-' + serial.slice(5)
}
const convertCorpNoReadable = (serial: string) => {
    return serial.length === 0 ? '' : serial.slice(0, 6) + '-' + serial.slice(6)
}

const UserCompanyInfo = () => {

    /* refresh를 위한 navigate 사용 */
    const navigate = useNavigate()
    /* state 사용 */
    const [ signSource, setSignSource ] = useState('')
    const [ userCom, setUserCom ] = useState({
        corp: '',
        corp_nm: '',
        corp_no: '',
        ymd_open: '',
        saup_no: '',
        owner: '',
        comp_gbn: '',
        up: '',
        jong: '',
        email: '',
        addr: '',
        zip: '',
        tel:  '',
        fax: ''
    })
    const [ errorOnInput, setErrorOnInput ] = useState({
        ymd_open: false,
        saup_no: false,
        corp_no: false,
        email: false,
    })
    /* Daum 주소 찾기 모달 띄울 지 여부 */
    const [ daumPost, setDaumPost ] = useState(false)
    /* 저장 모달 띄울 지 여부 */
    const [ saveModal, setSaveModal ] = useState(false)
    /* 성공, 실패 모달 */
    const [ saveModalSuccess, setSaveModalSuccess ] = useState(false)
    const [ saveModalFailed, setSaveModalFailed ] = useState({
        message: '',
        state: false
    })
    /* 초기에 정보가 fetch 되었는지 검사 */
    const [ isFetched, setIsFetched ] = useState(false)

    /* redux에서 버튼 액션 불러오기 */
    const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)

    /* useRef 사용 */
    /* const hiddenFileUploader = useRef<HTMLInputElement>(null) */

    /* dispatch로 초기 btnAction state clear */
    const dispatch = useDispatch()
    
    /* 버튼 활성화 시 저장 물어보기 */
    useEffect(() => {
        if(btnActions.active && btnActions.action === 'save') {
            setSaveModal(true)
        }
        dispatch(buttonActions.clear())
    }, [btnActions.action, btnActions.active, dispatch])
    
    /* 초기 데이터 불러오기 */
    useEffect(() => {
        client.get('/api/standard-info/get-user-company-data')
            .then(res => {
                const payload = res.data.payload
                //setSignSource(window.btoa(payload.sign.data.reduce((data: string, byte: number) => data + String.fromCharCode(byte), '')))
                if(res.data.result === 'success') {
                    setUserCom({
                        corp: payload.corp || '',
                        corp_nm: payload.corp_nm || '',
                        ymd_open: convertDateReadable(payload.ymd_open || ''),
                        saup_no: addHyphenOnSaupNo(payload.saup_no || ''),
                        corp_no: convertCorpNoReadable(payload.corp_no || ''),
                        owner: payload.owner || '',
                        comp_gbn: payload.comp_gbn || '1',
                        up: payload.up || '',
                        jong: payload.jong || '',
                        email: payload.email || '',
                        addr: payload.addr || '',
                        zip: payload.zip || '',
                        tel: payload.tel || '',
                        fax: payload.fax || ''
                    })
                }
            })
            .catch(e => {
                console.log(e.message)
            })
    }, [])

    useEffect(() => console.log(userCom), [userCom])
    
    
    /* 엔터 입력 시 다음 필드로 */
    const handleEnter = (e: any) => {
        if(e.key === 'Enter') {
            const form = e.target.form
            const index = Array.prototype.indexOf.call(form, e.target)

            switch(form.elements[index].id) {
                case 'ymd_open':
                    if(errorOnInput.ymd_open)
                        break
                    else
                        form.elements[index + 1].focus()
                    break

                case 'saup_no' :
                    if(errorOnInput.saup_no) {
                        break
                    } else {
                        if(userCom.comp_gbn === '1')
                            form.elements[index + 2].focus()
                        else
                            form.elements[index + 3].focus()
                    }
                    break

                case 'corp_no' :
                    if(errorOnInput.corp_no) {
                        break
                    } else {
                        form.elements[index + 1].focus()
                    }
                    break

                case 'email':
                    if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userCom.email) && userCom.email.length !== 0) {
                        setErrorOnInput({...errorOnInput, email: true})
                    } else {
                        setErrorOnInput({...errorOnInput, email: false})
                        form.elements[index + 2].focus()
                    }
                    break

                case 'addr':
                    form.elements[index + 2].focus()
                    break

                case 'fax':
                    break

                default:
                    form.elements[index + 1].focus()
            }

            e.preventDefault()
        }
    }


    /* 날짜 바뀔 때 입력란 확인 */
    const handleChangeOnYmdOpen = (e: ChangeEvent<HTMLInputElement>) => {
        let ymd_open = e.target.value.slice(0, 10)
        let pure_ymd_open = ymd_open.split('-').reduce((prev, cur) => prev + cur, '')
        const yyyy = pure_ymd_open.slice(0, 4)
        const mm = pure_ymd_open.slice(4, 6)
        const dd = pure_ymd_open.slice(6)
        const ytoNum = parseInt(yyyy)
        const mtoNum = parseInt(mm)
        const dtoNum = parseInt(dd)
        
        if(ymd_open !== '') {
            if(ymd_open.charAt(ymd_open.length - 1) === '-') {
                ymd_open = ymd_open.slice(0, ymd_open.length - 1)
            }

            if(pure_ymd_open.length === 5) {
                ymd_open = pure_ymd_open.slice(0, 4) + '-' + pure_ymd_open.slice(4)
            } else if(pure_ymd_open.length === 7) {
                ymd_open = pure_ymd_open.slice(0, 4) + '-' + pure_ymd_open.slice(4, 6) + '-' + pure_ymd_open.slice(6)
            }
        }

        if(pure_ymd_open.length === 0) {
            setErrorOnInput({ ...errorOnInput, ymd_open: false })
        } else if(isNaN(parseInt(pure_ymd_open))) {
            setErrorOnInput({ ...errorOnInput, ymd_open: true })
        } else if(pure_ymd_open.length !== 8) {
            setErrorOnInput({ ...errorOnInput, ymd_open: true })
        } else if(isNaN(ytoNum) || isNaN(mtoNum) || isNaN(dtoNum)) {
            setErrorOnInput({ ...errorOnInput, ymd_open: true })
        } else {
            if(yyyy.slice(0, 1) === '0')
                setErrorOnInput({ ...errorOnInput, ymd_open: true })

            if (mtoNum >= 1 && mtoNum <= 12) {
                if(mtoNum === 2) {
                    if(isLeap(ytoNum) && (dtoNum >= 1 && dtoNum <= 29)) {
                        setErrorOnInput({ ...errorOnInput, ymd_open: false })
                    }
                    else if (dtoNum >= 1 && dtoNum <= 28)
                        setErrorOnInput({ ...errorOnInput, ymd_open: false })
                    else
                        setErrorOnInput({ ...errorOnInput, ymd_open: true })

                } else if ((mtoNum === 4) || (mtoNum === 6) || (mtoNum === 9) || (mtoNum === 11)) {
                    if(dtoNum >= 1 && dtoNum <= 30)
                        setErrorOnInput({ ...errorOnInput, ymd_open: false })
                    else
                        setErrorOnInput({ ...errorOnInput, ymd_open: true })
                } else {
                    if(dtoNum >= 1 && dtoNum <= 31)
                        setErrorOnInput({ ...errorOnInput, ymd_open: false })
                    else
                        setErrorOnInput({ ...errorOnInput, ymd_open: true })
                }
            } else {
                setErrorOnInput({ ...errorOnInput, ymd_open: true })
            }
        }

        setUserCom({ ...userCom, ymd_open })
    }
    /* 사업자등록번호 바뀔 때 입력란 확인 */
    const handleChangeOnSaupNo = (e: ChangeEvent<HTMLInputElement>) => {
        let saup_no = e.target.value.slice(0, 12)
        const pure_saup_no = saup_no.split('-').reduce((prev, cur) => prev + cur, '')

        if(saup_no !== '') {
            if(saup_no.charAt(saup_no.length - 1) === '-') {
                saup_no = saup_no.slice(0, saup_no.length - 1)
            }

            if(pure_saup_no.length === 4) {
                saup_no = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3)
            } else if(pure_saup_no.length === 6) {
                saup_no = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3, 5) + '-' + pure_saup_no.slice(5)
            }
        }

        if(pure_saup_no.length === 0) {
            setErrorOnInput({...errorOnInput, saup_no: false})
        } else if(isNaN(parseInt(pure_saup_no))) {
            setErrorOnInput({...errorOnInput, saup_no: true})
        } else if(pure_saup_no.length === 10) {
            /* number로 변환 */
            const saup_num = pure_saup_no.split('').map(item => parseInt(item, 10))
            const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5]
            let checkSum = 0

            for(let i = 0; i < multiply.length; i++)
                checkSum += saup_num[i] * multiply[i]

            checkSum += Math.floor((multiply[8] * saup_num[8]) / 10)
            checkSum = 10 - (checkSum % 10)
            if(saup_num[9] === checkSum)
                setErrorOnInput({ ...errorOnInput, saup_no: false })
            else
                setErrorOnInput({ ...errorOnInput, saup_no: true })
        } else {
            setErrorOnInput({ ...errorOnInput, saup_no: true })
        }

        setUserCom({ ...userCom, saup_no  })
    }
    /* 법인등록번호 바뀔 때 입력란 확인 */
    const handleChangeOnCorpNo = (e: ChangeEvent<HTMLInputElement>) => {
        let corp_no = e.target.value.slice(0, 14)
        const pure_corp_no = corp_no.split('-').reduce((prev, cur) => prev + cur, '')

        if (corp_no !== '') {
            if(corp_no.charAt(corp_no.length - 1) === '-') {
                corp_no = corp_no.slice(0, corp_no.length - 1)
            }

            if(pure_corp_no.length === 7) {
                corp_no = pure_corp_no.slice(0, 6) + '-' + pure_corp_no.slice(6)
            }
        }
        if(pure_corp_no.length === 0) {
            setErrorOnInput({ ...errorOnInput, corp_no: false })
        } else if(isNaN(parseInt(pure_corp_no))) {
            setErrorOnInput({ ...errorOnInput, corp_no: true })
        } else if(pure_corp_no.length === 13) {
            /* number로 변환 */
            const corp_num = pure_corp_no.split('').map((item) => parseInt(item, 10))
            const multiply = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]
            let checkSum = 0

            for(let i = 0; i < multiply.length; i++)
                checkSum += corp_num[i] * multiply[i]

            checkSum = 10 - (checkSum % 10)

            if(checkSum === corp_num[corp_num.length - 1])
                setErrorOnInput({ ...errorOnInput, corp_no: false })
            else
                setErrorOnInput({ ...errorOnInput, corp_no: true })
        } else {
            setErrorOnInput({ ...errorOnInput, corp_no: true })
        }

        setUserCom({ ...userCom, corp_no })
    }
    /* 이메일 입력 */
    const handleChangeOnEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value
        if(email.length === 0)
            setErrorOnInput({ ...errorOnInput, email: false })
        setUserCom({ ...userCom, email })
    }
    /* 이메일 확인(onBlur) */
    const handleCheckEmail = () => {
        if(userCom.email === '') {
            return setErrorOnInput({ ...errorOnInput, email: false })
        } else {
            if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userCom.email))
                return setErrorOnInput({ ...errorOnInput, email: false })
            else
                return setErrorOnInput({ ...errorOnInput, email: true})
        }
    }

    /* 전화번호 입력 */
    const handleChangeTelFax = (e: ChangeEvent<HTMLInputElement>) => {
        let value =  convertPhoneNoReadable(e.target.value)

        if(e.target.id === 'tel') {
            setUserCom({ ...userCom, tel: value })
        } else {
            setUserCom({ ...userCom, fax: value })
        }
    }

    /* 개인/법인 정보 선택 */
    const handleSelect = (e: string | null) => {
        setUserCom({ ...userCom, comp_gbn: e! })
    }

    /* 주소 빈 칸일 시 우편번호 없애기 */
    const handleChangeOnAddr = (e: any) => {
        if(e.target.value === '') {
            return setUserCom({ ...userCom, addr: e.target.value, zip: e.target.value })
        }

        return setUserCom({ ...userCom, addr: e.target.value })
    }

    /* 다음 주소 api 사용 */
    const handleComplete = (data: Address) => {
        let fullAddress = data.address;
        let extraAddress = ''

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
        }

        setUserCom({
            ...userCom,
            addr: fullAddress,
            zip: data.zonecode
        })

        setTimeout(() => document.getElementById('addr')?.focus(), 500)
        setDaumPost(false)
    }

    /* 파일 업로드 버튼 사용 */
    /*
    const handleClickUpload = (e: any) => {
        e.preventDefault()
        hiddenFileUploader.current?.click()
    }
    */

    /* 이미지 업로드 시 실행할 화면 */
    /*
    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files)

        if(e.target.files) {
            let reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])

            reader.onloadend = () => {
                const base64 = reader.result
                console.log(base64)

                if(base64) {
                    const base64String = base64.toString()
                    setSignSource(base64String)
                }
            }
        }
    }
    */

    /* 저장 취소할 때 실행 */
    const handleCancelSave = () => {
        dispatch(buttonActions.clear())
        setSaveModal(false)
    }

    /* 저장 수행 */
    const handleInfoSave = async () => {
        dispatch(buttonActions.clear())
        setSaveModal(false)

        if(errorOnInput.ymd_open) { /* 입력 검사 */
            setSaveModalFailed({ message: '날짜를 정확히 입력해주세요.', state: true })
        } else if(errorOnInput.saup_no) {
            setSaveModalFailed({ message: '사업자등록번호를 정확히 입력해주세요.', state: true })
        } else if(errorOnInput.corp_no && userCom.comp_gbn === '1') {
            setSaveModalFailed({ message: '법인등록번호를 정확히 입력해주세요.', state: true })
        } else if(errorOnInput.email) {
            setSaveModalFailed({ message: '이메일을 정확히 입력해주세요.', state: true })
        } else {                   /* 모두 정확히 입력했으면 */
            try {
                const response = await client.post('/api/standard-info/set-user-company-data', {
                        params: {
                            ...userCom,
                            ymd_open: userCom.ymd_open.replaceAll('-', ''),
                            saup_no: userCom.saup_no.replaceAll('-', ''),
                            corp_no: userCom.corp_no.replaceAll('-', '')
                        }
                    })

                if(response.data.result === 'success') {
                    setSaveModalSuccess(true)
                } else {
                    setSaveModalFailed({ message: '정보 저장에 실패했습니다.', state: true })
                }
            } catch (e) {
                setSaveModalFailed({ message: '정보 저장에 실패했습니다.', state: true })
            }
        }
    }

    /* 날짜, 사업자 등록 번호, 주소, 이메일 이외에는 전부 setUserCom으로 바로 접근 */
    return (
        <>
            <div className="card-body ms-1 pt-0 px-2" style={{ width: '100%', maxWidth: '800px' }}>

                <form className="overflow-hidden" onSubmit={e => e.preventDefault()}>
                    {/* 회사 번호, 회사명 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">회사명</span>
                        <span className="input-group-text justify-content-center" style={{ width: '50px'}}>{userCom.corp}</span>
                        <input id="corp_nm" type="text" className="form-control" value={userCom.corp_nm} style = {{ maxWidth: '350px' }}
                               onChange={e => setUserCom({ ...userCom, corp_nm: e.target.value })} onKeyDown={handleEnter} disabled/>
                    </div>

                    {/* 설립일, 형식에 맞아야 함 */}
                    <div className="input-group input-group-sm mt-3 mb-1">
                        <span className="input-group-text saup_comp justify-content-center">설립일</span>
                        <input id="ymd_open" type="text" className={`form-control ${errorOnInput.ymd_open ? 'border-danger' : ''}`} style={{ maxWidth: '160px'}}
                               value={userCom.ymd_open} onChange={handleChangeOnYmdOpen} placeholder="숫자만 입력해주세요." onKeyDown={handleEnter}/>
                    </div>
                    <p className="text-danger" style={{ fontSize: '10pt' }} hidden={!errorOnInput.ymd_open}>올바른 날짜를 입력해주세요.</p>

                    {/* 사업자 등록 번호, 형식에 맞아야 함 */}
                    <div className="input-group input-group-sm mt-3 mb-1">
                        <span className="input-group-text saup_comp justify-content-center justify-content-center">사업자등록번호</span>
                        <input id="saup_no" type="text" className={`form-control ${errorOnInput.saup_no ? 'border-danger' : ''}`} style={{ maxWidth: '240px' }}
                               value={userCom.saup_no} onChange={handleChangeOnSaupNo} placeholder="숫자만 입력해주세요." onKeyDown={handleEnter}/>
                        {/* 개인 법인 구분 */}


                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Toggle variant="outline-secondary">{userCom.comp_gbn === '1' ? '1. 법인' : '2. 개인'}</Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1">1. 법인</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item eventKey="2">2. 개인</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <p className="text-danger" style={{ fontSize: '10pt' }} hidden={!errorOnInput.saup_no}>올바른 사업자등록번호를 입력해주세요.</p>

                    {/* 법인등록번호 입력 */}
                    <div className="input-group input-group-sm mt-3 mb-1" hidden={userCom.comp_gbn === '2'}>
                        <span className="input-group-text saup_comp justify-content-center justify-content-center">법인등록번호</span>
                        <input id="corp_no" type="text" className={`form-control ${errorOnInput.corp_no ? 'border-danger' : ''}`} style={{ maxWidth: '240px' }}
                               value={userCom.corp_no} onChange={handleChangeOnCorpNo} placeholder="숫자만 입력해주세요." onKeyDown={handleEnter}/>
                    </div>

                    <p className="text-danger" style={{ fontSize: '10pt' }} hidden={!errorOnInput.corp_no}>올바른 법인등록번호를 입력해주세요.</p>

                    {/* 대표자명 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">대표자명</span>
                        <input id="owner" type="text" className="form-control" style={{ maxWidth: '240px'}} value={userCom.owner}
                               onChange={(e) => setUserCom({ ...userCom, owner: e.target.value })} onKeyDown={handleEnter}/>
                    </div>

                    {/* 업태와 종목 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">업태</span>
                        <input id="up" className="form-control" value={userCom.up} style={{ maxWidth: '240px'}}
                               onChange={e => setUserCom({ ...userCom, up: e.target.value })} onKeyDown={handleEnter}/>
                    </div>
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">종목</span>
                        <input id="jong" className="form-control" value={userCom.jong} style={{ maxWidth: '240px'}}
                               onChange={e => setUserCom({ ...userCom, jong: e.target.value })} onKeyDown={handleEnter}/>
                    </div>

                    {/* 이메일, 형식에 맞아야 함 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">이메일</span>
                        <input id="email" className={`form-control ${errorOnInput.email ? 'border-danger' : ''}`} value={userCom.email}
                               onChange={handleChangeOnEmail} onBlur={handleCheckEmail} style={{ maxWidth: '350px' }} onKeyDown={handleEnter}/>
                    </div>
                    <p className="text-danger" style={{ fontSize: '10pt' }} hidden={!errorOnInput.email}>올바른 이메일을 입력해주세요.</p>

                    {/* 사업장 주소, 상세주소 외 readOnly */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">주소</span>
                        <input id="addr" className="form-control" value={userCom.addr}
                               onChange={handleChangeOnAddr} onKeyDown={handleEnter}/>
                        <button id="zip" className="btn btn-sm btn-outline-secondary"
                                onClick={() => setDaumPost(true)}>
                            {userCom.zip === '' ? '우편번호 찾기' : userCom.zip}
                        </button>
                    </div>

                    {/* 주소 입력 모달 */}
                    <Modal centered show={daumPost} onHide={() => setDaumPost(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                주소 입력
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <DaumPostcode onComplete={handleComplete}/>
                        </Modal.Body>
                    </Modal>

                    {/* 전화번호 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">전화</span>
                        <input id="tel" type="tel" className="form-control" value={userCom.tel} style={{ maxWidth: '240px' }}
                               onChange={handleChangeTelFax} onKeyDown={handleEnter}/>
                    </div>

                    {/* 팩스 번호 */}
                    <div className="input-group input-group-sm my-3">
                        <span className="input-group-text saup_comp justify-content-center">팩스</span>
                        <input id="fax" type="tel" className="form-control" value={userCom.fax} style={{ maxWidth: '240px' }}
                               onChange={handleChangeTelFax} onKeyDown={handleEnter}/>
                    </div>

                    {/*

                    <label>도장</label> <br/>
                    <img src={signSource.length === 0 ? `img/blank.png` : `data:image/png;base64,${signSource}`}
                         className="img-thumbnail" style={{ width: '100px' }} alt="..."/>
                    <div className="input-group input-group-sm my-3">
                        <button className="btn btn-sm btn-outline-secondary sign-button" onClick={handleClickUpload}>
                            도장등록
                        </button>
                        <button className="btn btn-sm btn-outline-secondary sign-button">도장삭제</button>
                    </div>

                    <input ref={hiddenFileUploader} type="file" accept="image/*" onChange={handleImageUpload} hidden/>
                    */}

                    {/* 정보 저장 요청 모달 */}
                    <Modal centered show={saveModal} onHide={handleCancelSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                정보 저장
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>바뀐 정보를 저장할까요?</Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCancelSave}>닫기</button>
                            <button className="btn btn-primary" onClick={handleInfoSave}>저장</button>
                        </Modal.Footer>
                    </Modal>

                    {/* 정보 저장 성공 모달 */}
                    <Modal centered show={saveModalSuccess} onHide={() => {
                        setSaveModalSuccess(false)
                        navigate(0)
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                저장 성공
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>정보를 저장했습니다.</Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => setSaveModalSuccess(false)}>닫기</button>
                        </Modal.Footer>
                    </Modal>

                    {/* 정보 저장 실패 모달 */}
                    <Modal centered show={saveModalFailed.state} onHide={() => {
                        setSaveModalFailed({ message: '', state: false})
                        navigate(0)
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                저장 실패
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>{saveModalFailed.message}</Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={() => setSaveModalFailed({ message: '', state: false})}>닫기</button>
                        </Modal.Footer>
                    </Modal>

                    <div style={{ height: '900px'}}>

                    </div>
                </form>
            </div>
        </>
    )
}

export default UserCompanyInfo