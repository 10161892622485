import React, {useEffect, useRef, useState} from 'react'
import client from "../../../axios";
import InfoModal from '../../common-modals/info-modal'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {RootReducer} from "../../../redux/reducers";
import * as decActions from '../../../redux/actions/dec'

const DecChange = () => {
	const btnRef = useRef<HTMLButtonElement>(null)
	const selectRef = useRef<HTMLSelectElement>(null)

	/* 동작 실패 시 띄울 모달의 정보 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')

	/* selector와 dispatch 사용 */
	const { dec } = useSelector((state: RootReducer) => state.dec, shallowEqual)
	const dispatch = useDispatch()


	const handleChange = async () => {
		try {
			const response = await client.post('/api/settings/set-dec', { payload: { newDec: selectRef.current?.value }})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if (response.data.result === 'failed') {
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setShowFailedModal(true)
			} else {

				if(selectRef.current !== null)
					dispatch(decActions.setDec(Number(selectRef.current.value)))
			}
		} catch (e: any) {
			setShowFailedModal(true)
			setFailedTitle('데이터 가져오기 실패')
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
		}
	}

	useEffect(() => {
		if(selectRef.current !== null) {
			selectRef.current.value = dec.toString()
			selectRef.current.focus()
		}
	}, [])

	return (
		<div className="mt-4 d-flex justify-content-sm-start justify-content-center" aria-label="dec-change-box">
			<div className="d-inline border px-3" aria-label="dec-field">
				<div className="input-group input-group-sm my-4">
					<span className="input-group-text password-addon">소수점이하 자릿수</span>
					<select ref={selectRef} className="form-select" name="dec-select" id="dec-select">
						{[0, 1, 2, 3, 4, 5].map(element =>
							<option key={element} value={element}>{element}</option>
						)}
					</select>
				</div>

				<div className="d-flex justify-content-end my-4">
					<button ref={btnRef} className="btn btn-primary btn-sm px-3" onClick={handleChange}>저장</button>
				</div>
			</div>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />
		</div>
	)
}

export default DecChange