import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Modal} from 'react-bootstrap'
import client from '../../../../axios'
import * as buttonActions from '../../../../redux/actions/button'
import * as changeMenuActions from '../../../../redux/actions/change-menu'
import CodeList from './standard-code-list'
import './style.css'
import {RootReducer} from "../../../../redux/reducers";
import InfoModal from '../../../common-modals/info-modal'

const categoryHeaders = [ 'No', '구분' ]
const categoryNames = [ '단위', '재고조정유형-재고증가', '재고조정유형-재고감소', '창고', '작업팀' ]
/* 02: 단위, 91: 재고조정유형-재고증가, 92: 재고감소, 81: 창고, 71: 작업팀 */
const categoryCodes = [ '02', '91', '92', '81', '71' ]

export interface IStandardCode {
    big: string;
    sml: string;
    sml_nm: string;
    use_gbn: string;
    upd_gbn: string;
    bigo: string;
}

let categoryData: any[] = []
for(let i = 0; i < 5; i++) {
    categoryData = [ ...categoryData, { name: categoryNames[i], code: categoryCodes[i] } ]
}

const StandardCode = () => {

    /* isFetched */
    const [ isFetched, setIsFetched ] = useState(false)
    /* 카테고리 선택(big) */
    const [ codeBig, setCodeBig ] = useState('02')
    /* 전체 코드 가져오기 */
    const [ standardCodes, setStandardCodes ] = useState<IStandardCode[]>([])
    /* 수정된 행 관리 */
    const [ modifiedRows, setModifiedRows ] = useState<string[]>([])
    /* 추가된 행 관리 */
    const [ addedRows, setAddedRows ] = useState<string[]>([])
    /* 저장 모달 띄울 지 여부 */
    const [ saveModal, setSaveModal ] = useState(false)
    /* 성공, 실패 모달 */
    const [ saveModalSuccess, setSaveModalSuccess ] = useState(false)
    const [ saveModalFailed, setSaveModalFailed ] = useState({
        message: '',
        state: false
    })

    /* redux에서 버튼 액션 불러오기 */
    const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
    const dispatch = useDispatch()
    
    /* 데이터 가져오기 */
    useEffect(() => {
        if(!isFetched) {
            setIsFetched(true)
            client.get('/api/standard-info/get-standard-code').then(res => {
                const codes = res.data.payload

                setStandardCodes(codes.map((code: IStandardCode) => ({
                    ...code,
                    sml_nm: code.sml_nm || '',
                    bigo: code.bigo || '',
                })))
            })
        }
    }, [isFetched])
    
    /* 버튼 액션 처리 */
    useEffect(() => {
        if(btnActions.active && btnActions.action === 'save') {
            setSaveModal(true)
        } else if (btnActions.active && btnActions.action === 'input') {
            dispatch(changeMenuActions.change())
            const new_sml = getCurrentBiggestNum(codeBig) + 1
            const newRow = { big: codeBig, sml: new_sml.toString(), use_gbn: '1', upd_gbn: '1', bigo: '', sml_nm: '' }
            setStandardCodes([ ...standardCodes, newRow ])

            let noDup = new Set([ ...addedRows, `${newRow.big}-${newRow.sml}` ])
            setAddedRows(Array.from(noDup))

            /* 화면 맨 밑으로 이동 */
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 50)
        }
        dispatch(buttonActions.clear())
    }, [btnActions.action, btnActions.active, dispatch])

    /* big 값에 따라 sml의 최대값 반환 */
    const getCurrentBiggestNum = (code: string) => {
        return standardCodes.filter(value => value.big === code).length
    }

    /* 카테고리 테이블 행 클릭 시 실행되는 함수 */
    const handleCategoryClick = (data: any) => {
        setCodeBig(data.code)
    }

    /* 사용여부 수정 함수 */
    const toggleUseGbn = (big: string, sml: string) => {
        /* state 설정 함수에 콜백 함수를 쓰지 않는다 */
        setStandardCodes(standardCodes.map(code =>
            (code.big === big && code.sml === sml)                           /* 만약 타깃이라면 */
                ?
                { ...code, use_gbn: (parseInt(code.use_gbn) ^ 1).toString()} /* use_gbn을 toggle한다. */
                :
                code
        ))
        let noDup = new Set([ ...modifiedRows, `${big}-${sml}`])
        setModifiedRows(Array.from(noDup))
    }

    /* sml_nm을 수정하는 함수 */
    const handleChangeSmlNm = (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => {
        setStandardCodes(standardCodes.map(code =>
            (code.big === big && code.sml === sml)                           /* 만약 타깃이라면 */
                ?
                { ...code, sml_nm: e.target.value.slice(0, 199)} /* use_gbn을 toggle한다. */
                :
                code
        ))
        let noDup = new Set([ ...modifiedRows, `${big}-${sml}`])
        setModifiedRows(Array.from(noDup))
    }

    /* 비고란 수정 함수 */
    const handleChangeBigo = (e: React.ChangeEvent<HTMLInputElement>, big: string, sml: string) => {
        setStandardCodes(standardCodes.map(code =>
            (code.big === big && code.sml === sml)                           /* 만약 타깃이라면 */
                ?
                { ...code, bigo: e.target.value.slice(0, 49)} /* use_gbn을 toggle한다. */
                :
                code
        ))
        let noDup = new Set([ ...modifiedRows, `${big}-${sml}`])
        setModifiedRows(Array.from(noDup))
    }

    /* 저장 취소할 때 실행 */
    const handleCancelSave = () => {
        dispatch(buttonActions.clear())
        setSaveModal(false)
    }

    /* 기준코드 저장 키 */
    const handleCodeSave = async () => {
        dispatch(buttonActions.clear())
        setSaveModal(false)

        const modifiedCodeArr = standardCodes.filter(value => (modifiedRows.findIndex(element => `${value.big}-${value.sml}` === element) !== -1))
        const addedCodeArr = standardCodes.filter(value => (addedRows.findIndex(element => `${value.big}-${value.sml}` === element) !== -1))

        try {
            const response = await client.post('/api/standard-info/set-standard-code', { modified: modifiedCodeArr, added: addedCodeArr })

            if(response.data.result === 'success') {
                setSaveModalSuccess(true)
            } else {
                setSaveModalFailed({ message: '정보 저장에 실패했습니다.', state: true })
            }
        } catch (e: any) {
            setSaveModalFailed({ message: '정보 저장에 실패했습니다.', state: true })
        }
    }

    /* 저장 성공 모달 숨기기 */
    const handleHideSuccessModal = () => {
        setSaveModalSuccess(false)
    }

    /* 저장 실패 모달 숨기기 */
    const handleHideFailedModal = () => {
        setSaveModalFailed({ message: '', state: false })
    }

    return (
        <>
            <div className="d-flex my-3 mx-3" style={{ overflow: 'auto' }}>
                <div className="me-3 table-wrapper" style={{ width: 270 }}>
                    <table id="categoryTable" className="table table-sm table-bordered table-condensed">
                        <thead>
                        <tr>
                            {categoryHeaders.map((value, index) => <th key={`1st_cthead_${index + 1}`} scope="col">{value}</th>)}
                        </tr>
                        </thead>

                        <tbody>
                        {
                            categoryData.map((data, index) => {
                                return (
                                    <tr id={data.code} key={`ctbody_${index + 1}`} onClick={() => handleCategoryClick(data)}
                                        className={data.code === codeBig ? "category-row-selected" : ""}>
                                        <th scope="row" className="text-center">{index + 1}</th>
                                        <td className="category-row-selected">{data.name}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive scrollbar flex-grow-1 table-wrapper">
                    <table className="table table-sm table-bordered table-condensed">
                        <thead className="sticky-head">
                        <tr>
                            <th scope="col" style={{ width: '45px', minWidth: '45px', textAlign: "center" }}>사용</th>
                            <th scope="col" style={{ width: '45px', minWidth: '45px', textAlign: "center" }}>코드</th>
                            <th scope="col" style={{ width: '250px', minWidth: '250px', textAlign: "center" }}>코드명</th>
                            <th scope="col" style={{ width: '400px', minWidth: '400px', textAlign: "center" }}>비고</th>
                            <th />
                        </tr>
                        </thead>

                        <tbody>
                        <CodeList data={standardCodes} toggleUseGbn={toggleUseGbn} codeBig={codeBig}
                                  onChangeBigo={handleChangeBigo} onChangeSmlNm={handleChangeSmlNm} />
                        </tbody>
                    </table>
                </div>
            </div>

            {/* 정보 저장 요청 모달 */}
            <Modal centered show={saveModal} onHide={handleCancelSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        정보 저장
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>바뀐 정보를 저장할까요?</Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCancelSave}>닫기</button>
                    <button className="btn btn-primary" onClick={handleCodeSave}>저장</button>
                </Modal.Footer>
            </Modal>

            {/* 정보 저장 성공 모달 */}
            <InfoModal show={saveModalSuccess} onHide={handleHideSuccessModal} title={"저장 성공"}
                       message={"정보를 저장했습니다."} onButtonClick={handleHideSuccessModal} />

            {/* 정보 저장 실패 모달 */}
            <InfoModal show={saveModalFailed.state} onHide={handleHideFailedModal} title={"저장 실패"}
                       message={saveModalFailed.message} onButtonClick={handleHideFailedModal} />
        </>
    )
}

export default StandardCode