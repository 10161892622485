import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {INameValue} from '../../../../interfaces/common'
import {IPrdStandardCode, IProduct} from '../../../../interfaces/standard-info/i-product'
import client from '../../../../../axios'
import CodeModal from './code-modal'

/* 행 추가 모달의 props 인터페이스 */
interface AddModalProps {
    show: boolean;
    onHide: () => any;
    accCategory: INameValue[];
    useCategory: INameValue[];
    codeData: IPrdStandardCode[];
    onSubmit: (row: IProduct) => Promise<void>
}

interface IRow extends IProduct {
    unit_nm: string;
}

const defaultRow: IRow = {
    item: '',
    item_usr: '',
    item_nm: '',
    spec: '',
    acc_gbn: '14600',
    unit: '',
    unit_nm: '',
    use_gbn: '1'
}
const defaultError = { item_nm: true, unit: true }

const AddModal = ({ show, onHide, accCategory, useCategory, codeData, onSubmit }: AddModalProps) => {

    /*
    * addRow: 품목추가 정보를 담는 객체
    * errorOnInput: 문제가 있는 입력을 저장
    * errorMsg: 문제가 있을 시 그 문제를 표현하는 문자열
    * showErrorMsg: 문제 메시지를 표현할 지 여부
    * */
    const [ addRow, setAddRow ] = useState(defaultRow)
    const [ errorOnInput, setErrorOnInput ] = useState(defaultError)
    const [ errorMsg, setErrorMsg ] = useState('')
    const [ showErrorMsg, setShowErrorMsg ] = useState(false)
    /* 변경 사항 저장 */
    const [ showSaveModal, setShowSaveModal ] = useState(false)
    /* 변경되었는지 확인 */
    const [ isChanged, setIsChanged ] = useState(false)


    /* 기준코드(단위) 찾을 때 사용 데이터 */
    const [ showCodeSearch, setShowCodeSearch ] = useState(false)

    const initialize = () => {
        setAddRow(defaultRow)
        setErrorOnInput(defaultError)
        setErrorMsg('')
        setShowErrorMsg(false)
    }

    useEffect(() => {
        setTimeout(() => document.getElementById('item_nm')?.focus(), 200)
    }, [show])

    /* 엔터 입력 시 항목 이동 */
    const handleEnter = (e: any) => {
        if(e.key === 'Enter') {
            /* form 레퍼런스 획득 */
            const form = e.target.form
            /* 현재 선택한 input/select의 index 얻기 */
            const index = Array.prototype.indexOf.call(form, e.target)

            if(index !== form.elements.length - 1) {
                if(form.elements[index].id === 'acc_gbn') {
                    form.elements[index + 2].focus()
                } else {
                    if(form.elements[index].id === 'unit_nm' && addRow.unit_nm === '')
                        setShowCodeSearch(true)

                    form.elements[index + 1].focus()
                }
            }
            e.preventDefault()
        }
    }

    /* 입력란에 쓰여있는 것을 토대로 단위 찾기 */
    const findUnit = (e: any) => {
        const val = e.target.value.toLowerCase() as string
        /* 기준코드 테이블에서 불러온 값을 토대로 찾는다. */
        let index = codeData.findIndex(element => element.sml_nm.toLowerCase() === val)
        if (index === -1) index = codeData.findIndex(element => element.sml_nm.toLowerCase().includes(val))

        setAddRow({
            ...addRow,
            unit: (index === -1 || val === '') ? '' : codeData[index].sml,
            unit_nm: (index === -1 || val === '') ? '' : codeData[index].sml_nm
        })

        setErrorOnInput({ ...errorOnInput, unit: (index === -1 || val === '')})
    }

    /* 아이템 이름 변경 */
    const handleChangeOnItemNm = (e: any) => {
        setIsChanged(true)
        setErrorOnInput({ ...errorOnInput, item_nm: e.target.value === '' })
        setAddRow({ ...addRow, item_nm: e.target.value })
    }

    /* 규격 수정 수행 */
    const handleSpec = (e: any) => {
        setIsChanged(true)
        setAddRow({ ...addRow, spec: e.target.value })
    }

    /* 단위 입력란 값 변경 시 수행 */
    const handleChangeOnUnitNm = (e: any) => {
        setIsChanged(true)
        setAddRow({ ...addRow, unit: '', unit_nm: e.target.value })
        setErrorOnInput({ ...errorOnInput, unit: true })
    }

    /* 저장 버튼 클릭 시 액션 */
    const handleClickOnButton = async () => {
        let response, itemCode

         try {
            /* 입력란에 문제가 있으면 문제 보이기, 없으면 제출 */
            if(errorOnInput.item_nm) {
                setErrorMsg('품목을 입력해주세요.')
                setShowErrorMsg(true)
            } else if(errorOnInput.unit) {
                setErrorMsg('정확한 단위를 입력해주세요.')
                setShowErrorMsg(true)
            } else {
                response = await client.get('/api/standard-info/get-new-item-code')
                itemCode = response.data.newItemCode['IFNULL(SEQ, 0) + 1']
                await onSubmit({
                    ...addRow,
                    item: '0000000'.slice(0, '0000000'.length - itemCode.length) + itemCode,
                    item_usr: '00000'.slice(0, '00000'.length - itemCode.length) + itemCode
                })
                initialize()
                onHide()
            }
        } catch (e) {
            setErrorMsg('통신에 에러가 발생하였습니다.')
            setShowErrorMsg(true)
        }
    }

    /* 품목 추가 모달 가리기 */
    const handleHideAddModal = () => {
        onHide()
        initialize()
    }

    /* 단위 찾기 모달 가리기 */
    const handleHideCodeModal = () => {
        setShowCodeSearch(false)
    }

    /* 단위 찾기 모달의 '리턴값'을 토대로 단위 값 수정 */
    const handleSubmit = (sml: string, sml_nm: string) => {
        setAddRow({ ...addRow, unit: sml, unit_nm: sml_nm })
        setErrorOnInput({ ...errorOnInput, unit: false })
    }

    /* 변경 사항 저장 */
    const handleSaveData = () => {
        handleClickOnButton().then()
        setShowSaveModal(false)
    }

    return (
        <>
            <Modal show={show} onHide={() => {
                {
                    if(isChanged)
                        setShowSaveModal(true)
                    else {
                        handleHideAddModal()
                    }
                }
            }} centered>
                {/* 모달의 헤더 */}
                <Modal.Header closeButton>
                    <Modal.Title>
                        품목 추가
                    </Modal.Title>
                </Modal.Header>

                {/* 모달의 내용물 */}
                <Modal.Body>
                    <div className="card-body">
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">코드</span>
                                <input id="item_nm" type="text" className="form-control text-center" readOnly disabled value={addRow.item_usr} style={{ maxWidth: "80px" }}/>
                            </div>

                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">품명</span>
                                <input id="item_nm" type="text" className="form-control" value={addRow.item_nm} onKeyDown={handleEnter}
                                       onChange={handleChangeOnItemNm} autoFocus />
                            </div>

                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">규격</span>
                                <input id="spec" type="text" className="form-control" value={addRow.spec} onKeyDown={handleEnter}
                                       onChange={handleSpec} autoFocus />
                            </div>

                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">계정구분</span>
                                <select id="acc_gbn" defaultValue={addRow.acc_gbn} className="form-select form-select-sm"
                                        style={{ maxWidth: '150px' }} onKeyDown={handleEnter} onChange={e =>
                                        {
                                            setAddRow({...addRow, acc_gbn: e.target.value})
                                            setIsChanged(true)}
                                        }
                                >
                                    {accCategory.slice(1).map((category, index) =>
                                        <option key={index} value={category.value}>{`${index + 1}. ${category.name}`}</option>
                                    )}
                                </select>
                            </div>

                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">단위</span>
                                <input id="unit" type="text" className="form-control text-center" readOnly disabled
                                       value={addRow.unit} style={{ maxWidth: "40px" }} />
                                <input id="unit_nm" type="text" className="form-control" onKeyDown={handleEnter} onBlur={findUnit}
                                       value={addRow.unit_nm} onChange={handleChangeOnUnitNm} style={{ maxWidth: '110px' }}/>
                            </div>

                            <div className="input-group input-group-sm my-3">
                                <span className="input-group-text addonSize">사용여부</span>
                                <select id="use_gbn" defaultValue={addRow.use_gbn} className="form-select form-select-sm"
                                        style={{ maxWidth: '150px' }} onKeyDown={() => {}} onChange={e => {
                                    setAddRow({...addRow, use_gbn: e.target.value})
                                    setIsChanged(true)
                                }}>
                                    {useCategory.slice(1).map((category, index) =>
                                        <option key={index} value={category.value}>{`${index + 1}. ${category.name}`}</option>
                                    )}
                                </select>
                            </div>
                            <div className="d-flex justify-content-end">
                                <label className="me-3 text-danger" hidden={!showErrorMsg}>{errorMsg}</label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

                {/* 모달 버튼 모음 */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHideAddModal}>
                        닫기
                    </Button>
                    <Button variant="primary" onClick={handleClickOnButton}>
                        추가
                    </Button>
                </Modal.Footer>
            </Modal>

            <CodeModal show={showCodeSearch} codeData={codeData} onHide={handleHideCodeModal} onSubmit={handleSubmit}/>

            {/* 변경 내용 저장 모달 */}
            <Modal show={showSaveModal} onHide={() => {
                setShowSaveModal(false)
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        입력 사항 저장
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    입력된 정보를 저장하시겠습니까?
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-secondary my-2" onClick={() => {
                        setShowSaveModal(false)
                        setIsChanged(false)
                        initialize()
                        onHide()
                    }}>취소</button>
                    <button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddModal