import React from 'react'
import {IBeforeRecipe, IRecipe, ISelectedRecipe} from "../../../interfaces/production-manage/i-add-recipe";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";

/* 새 입력 칸 컨텍스트 */
export interface INewRowContext {
	newRow: IRecipe
	setNewRow: (newRow: IRecipe) => void
}

export const NewRowContext = React.createContext<INewRowContext>({
	newRow: {} as IRecipe,
	setNewRow: () => {}
})

/* 레시피를 담는 컨텍스트(AddRecipe 컴포넌트의 RecipeContext보다 하위에 있음) */
export interface IRecipeContext {
	recipe: IRecipe[]
	setRecipe: (recipe: IRecipe[]) => void
	beforeRecipe: IBeforeRecipe[]
	setBeforeRecipe: (beforeRecipe: IBeforeRecipe[]) => void
	productData: IProductData[]
	isChanged: boolean
	setIsChanged: (isChanged: boolean) => void
}

export const RecipeContext = React.createContext<IRecipeContext>({
	recipe: [] as IRecipe[],
	setRecipe: () => {},
	beforeRecipe: [] as IBeforeRecipe[],
	setBeforeRecipe: () => {},
	productData: [] as IProductData[],
	isChanged: false,
	setIsChanged: () => {}
})

/* 실패 모달 등을 조절하는 컨텍스트 */
export interface IInfoModalContext {
	setShowFailedModal: (flag: boolean) => void;
	setFailedMessage: (message: string) => void;
	setFailedTitle: (title: string) => void;
}

export const InfoModalContext = React.createContext<IInfoModalContext>({
	setShowFailedModal: () => {},
	setFailedMessage: () => {},
	setFailedTitle: () => {}
})

/* 선택된 상세 레시피 컨텍스트 */
export interface ISelectedRecipeContext {
	selectedRecipe: ISelectedRecipe[]
	setSelectedRecipe: (selectedRecipe: ISelectedRecipe[]) => void
}

export const SelectedRecipeContext = React.createContext<ISelectedRecipeContext>({
	selectedRecipe: [] as ISelectedRecipe[],
	setSelectedRecipe: () => {}
})


/* 선택된 제품 컨텍스트 */
export interface ISelectedProductContext {
	selectedProduct: IProductData[]
	setSelectedProduct: (selectedProduct: IProductData[]) => void
}

export const SelectedProductContext = React.createContext<ISelectedProductContext>({
	selectedProduct: [] as IProductData[],
	setSelectedProduct: () => {}
})
